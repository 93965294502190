import * as actionTypes from "./actionTypes";

/**
 * @function manageOptionsStart
 * @returns `MANAGE_OPTIONS_START` action
 */
export const manageOptionsStart = () => {
  return {
    type: actionTypes.MANAGE_OPTIONS_START,
  };
};

/**
 * This function returns error Object
 * @param {Object} error
 */
export const manageOptionsFail = (error) => {
  return {
    type: actionTypes.MANAGE_OPTIONS_FAIL,
    error,
  };
};

export const companyIndustrySuccess = (response) => {
  return {
    type: actionTypes.GET_COMPANY_INDUSTRY_SUCCESS,
    response,
  };
};

export const getCompanyIndustry = (language) => {
  return {
    type: actionTypes.GET_COMPANY_INDUSTRY,
    language,
  };
};

export const createCompanyIndustry = (companyData) => {
  return {
    type: actionTypes.CREATE_COMPANY_INDUSTRY,
    companyData,
  };
};

export const createCompanyIndustrySuccess = (response) => {
  return {
    type: actionTypes.CREATE_COMPANY_INDUSTRY_SUCCESS,
    response,
  };
};

export const deleteCompanyIndustry = (id, language) => {
  return {
    type: actionTypes.DELETE_COMPANY,
    id,
    language
  };
};

export const getCompanyIndustryById = (id) => {
  return {
    type: actionTypes.GET_COMPANY_INDUSTRY_BY_ID,
    id,
  };
};

export const getCompanyIndustryByIdSuccess = (response) => {
  return {
    type: actionTypes.GET_COMPANY_INDUSTRY_BY_SUCCESS,
    response,
  };
};

export const getAccountTypeById = (id) => {
  return {
    type: actionTypes.GET_ACCOUNT_TYPE,
    id,
  };
};

export const getAccountTypeByIdSuccess = (response) => {
  return {
    type: actionTypes.GET_ACCOUNT_TYPE_SUCCESS,
    response,
  };
};

export const updateCompanyIndustry = (companyData) => {
  return {
    type: actionTypes.UPDATE_COMPANY_INDUSTRY,
    companyData,
  };
};

export const getAllInvestingSourceList = (language) => {
  return {
    type: actionTypes.GET_ALL_INVESTING_SOURCE_LIST,
    language,
  };
};

export const getAllInvestingSourceListSuccess = (response) => {
  return {
    type: actionTypes.GET_ALL_INVESTING_SOURCE_LIST_SUCCESS,
    response,
  };
};

export const deleteInvestingSourceList = (id) => {
  return {
    type: actionTypes.DELETE_INVESTING_SOURCE,
    id,
  };
};

export const createInvestingSource = (investingData) => {
  return {
    type: actionTypes.CREATE_INVESTING_SOURCE,
    investingData,
  };
};

export const getInvestingSourceById = (id) => {
  return {
    type: actionTypes.GET_INVESTING_SOURCE,
    id,
  };
};

export const getInvestingSourceByIdSuccess = (response) => {
  return {
    type: actionTypes.GET_INVESTING_SOURCE_SUCCESS,
    response,
  };
};

export const updateInvestingSource = (investingData) => {
  return {
    type: actionTypes.UPDATE_INVESTING_SOURCE,
    investingData,
  };
};

export const getAllInvestingType = (language) => {
  return {
    type: actionTypes.GET_ALL_INVESTING_TYPE,
    language,
  };
};

export const getAllInvestingTypeSuccess = (response) => {
  return {
    type: actionTypes.GET_ALL_INVESTING_TYPE_SUCCESS,
    response,
  };
};

export const deleteInvestingType = (id) => {
  return {
    type: actionTypes.DELETE_INVESTING_TYPE,
    id,
  };
};

export const createInvestingType = (investingTypeData) => {
  return {
    type: actionTypes.CREATE_INVESTING_TYPE,
    investingTypeData,
  };
};

export const getInvestingType = (id) => {
  return {
    type: actionTypes.GET_INVESTING_TYPE,
    id,
  };
};

export const getInvestingTypeSuccess = (response) => {
  return {
    type: actionTypes.GET_INVESTING_TYPE_SUCCESS,
    response,
  };
};

export const updateInvestingType = (investingData) => {
  return {
    type: actionTypes.UPDATE_INVESTING_TYPE,
    investingData,
  };
};

export const getAllDocumentType = (language) => {
  return {
    type: actionTypes.GET_ALL_DOCUMENT_TYPE,
    language,
  };
};

export const getAllDocumentTypeSuccess = (response) => {
  return {
    type: actionTypes.GET_ALL_DOCUMENT_TYPE_SUCCESS,
    response,
  };
};

export const deleteDocumentType = (id) => {
  return {
    type: actionTypes.DELETE_DOCUMENT,
    id,
  };
};

export const createDocumentType = (documentData) => {
  return {
    type: actionTypes.CREATE_DOCUMENT,
    documentData,
  };
};

export const getDocumentType = (id) => {
  return {
    type: actionTypes.GET_DOCUMENT_TYPE,
    id,
  };
};

export const getDocumentTypeSuccess = (response) => {
  return {
    type: actionTypes.GET_DOCUMENT_TYPE_SUCCESS,
    response,
  };
};

export const updateDocumentType = (documentData) => {
  return {
    type: actionTypes.UPDATE_DOCUMENT,
    documentData,
  };
};

export const getAllAccountType = (language) => {
  return {
    type: actionTypes.GET_ALL_ACCOUNT_TYPE,
    language,
  };
};

export const getAllAccountTypeSuccess = (response) => {
  return {
    type: actionTypes.GET_ALL_ACCOUNT_TYPE_SUCCESS,
    response,
  };
};

export const deleteAccountType = (id) => {
  return {
    type: actionTypes.DELETE_ACCOUNT_TYPE,
    id,
  };
};

export const createAccountType = (accountData) => {
  return {
    type: actionTypes.CREATE_ACCOUNT_TYPE,
    accountData,
  };
};

export const updateAccountType = (accountData) => {
  return {
    type: actionTypes.UPDATE_ACCOUNT_TYPE,
    accountData,
  };
};

export const companyLanguageCurrentId = (companyLanguage) => {
  return {
    type: actionTypes.GET_COMPANY_INDUSTRY_LANGUAGE,
    companyLanguage,
  };
};

export const getManageOptionsLanguages = (response) => {
  return {
    type: actionTypes.GET_MANAGE_DROPDOWN_LANGUAGES,
    response,
  };
};

export const getManageLanguageSuccess = (response) => {
  return {
    type: actionTypes.GET_MANAGE_LANGUAGE_SUCCESS,
    response,
  };
};

export const getManageOptionsCurrentId = (language) => {
  return {
    type: actionTypes.GET_MANAGE_DROPDOWN_LANG_ID,
    language,
  };
};

export const setManageOptionsCurrentId = (languageId) => {
  return {
    type: actionTypes.SET_MANAGE_DROPDOWN_LANG_ID,
    languageId,
  };
};

export const getAllTeamMemberType = (language) => {
  return {
    type: actionTypes.GET_ALL_TEAM_MEMBER_TYPE,
    language,
  };
};

export const getAllTeamMemberTypeSuccess = (response) => {
  return {
    type: actionTypes.GET_ALL_TEAM_MEMBER_TYPE_SUCCESS,
    response,
  };
};

export const createTeamMemberType = (teamMember) => {
  return {
    type: actionTypes.CREATE_TEAM_MEMBER_TYPE,
    teamMember,
  };
};

export const deleteTeamMemberType = (id, language) => {
  return {
    type: actionTypes.DELETE_TEAM_MEMBER_TYPE,
    id,
    language
  };
};

export const getTeamMemberType = (id) => {
  return {
    type: actionTypes.GET_TEAM_MEMBER_TYPE,
    id,
  };
};

export const getTeamMemberTypeSuccess = (response) => {
  return {
    type: actionTypes.GET_TEAM_MEMBER_TYPE_SUCCESS,
    response,
  };
};

export const updateTeamMemberType = (teamMember) => {
  return {
    type: actionTypes.UPDATE_TEAM_MEMBER_TYPE,
    teamMember,
  };
};

export const getAllInvestorType = (language) => {
  return {
    type: actionTypes.GET_INVESTORT_TYPE_LIST,
    language,
  };
};

export const getAllInvestorTypeSuccess = (response) => {
  return {
    type: actionTypes.GET_INVESTORT_TYPE_LIST_SUCCESS,
    response,
  };
};

export const createInvestorType = (investor) => {
  return {
    type: actionTypes.CREATE_INVESTOR_TYPE,
    investor,
  };
};

export const deleteInvestorType = (id, language) => {
  return {
    type: actionTypes.DELETE_INVESTOR_TYPE,
    id,
    language
  };
};

export const getInvestorType = (id) => {
  return {
    type: actionTypes.GET_INVESTOR_TYPE,
    id,
  };
};

export const getInvestorTypeSuccess = (response) => {
  return {
    type: actionTypes.GET_INVESTOR_TYPE_SUCCESS,
    response,
  };
};

export const updateInvestorType = (investor) => {
  return {
    type: actionTypes.UPDATE_INVESTOR_TYPE,
    investor,
  };
};

export const getCampaignStageList = (language) => {
  return {
    type: actionTypes.GET_CAMPAIGN_STAGE_LIST,
    language,
  };
};

export const getCampaignStageListSuccess = (response) => {
  return {
    type: actionTypes.GET_CAMPAIGN_STAGE_LIST_SUCCESS,
    response,
  };
};

export const createCampaignStage = (campaign) => {
  return {
    type: actionTypes.CREATE_CAMPAIGN_STAGE,
    campaign,
  };
};

export const getCampaignStage = (id) => {
  return {
    type: actionTypes.GET_CAMPAIGN_STAGE,
    id,
  };
};

export const getCampaignStageSuccess = (response) => {
  return {
    type: actionTypes.GET_CAMPAIGN_STAGE_SUCCEESS,
    response,
  };
};

export const deleteCampaignStage = (id, language) => {
  return {
    type: actionTypes.DELETE_CAMPAIGN_STAGE,
    id,
    language
  };
};

export const updateCampaignStage = (campaign) => {
  return {
    type: actionTypes.UPDATE_CAMPAIGN_STAGE,
    campaign,
  };
};

export const getInvestingRoundList = (language) => {
  return {
    type: actionTypes.GET_INVESTING_ROUND_LIST,
    language,
  };
};

export const getInvestingRoundListSuccess = (response) => {
  return {
    type: actionTypes.GET_INVESTING_ROUND_LIST_SUCCESS,
    response,
  };
};

export const createInvestingRound = (investing) => {
  return {
    type: actionTypes.CREATE_INVESTING_ROUND,
    investing,
  };
};

export const getInvestingRound = (id) => {
  return {
    type: actionTypes.GET_INVESTING_ROUND,
    id,
  };
};

export const getInvestingRoundSuccess = (response) => {
  return {
    type: actionTypes.GET_INVESTING_ROUND_SUCCESS,
    response,
  };
};

export const deleteInvestingRound = (id, language) => {
  return {
    type: actionTypes.DELETE_INVESTING_ROUND,
    id,
    language
  };
};

export const updateInvestingRound = (investing) => {
  return {
    type: actionTypes.UPDATE_INVESTING_ROUND,
    investing,
  };
};

export const getDealTypes = (language) => {
  return {
    type: actionTypes.GET_DEAL_TYPES,
    language,
  };
};

export const getDealTypesSuccess = (response) => {
  return {
    type: actionTypes.GET_DEAL_TYPES_SUCCESS,
    response,
  };
};

export const createDealType = (deal) => {
  return {
    type: actionTypes.CREATE_DEAL_TYPE,
    deal,
  };
};

export const createDealTypeSuccess = (response) => {
  return {
    type: actionTypes.CREATE_DEAL_TYPE_SUCCESS,
    response,
  };
};

export const deleteDealType = (id) => {
  return {
    type: actionTypes.DELETE_DEAL_TYPE,
    id,
  };
};

export const getDealType = (id) => {
  return {
    type: actionTypes.GET_DEAL_TYPE,
    id,
  };
};

export const getDealTypeSuccess = (response) => {
  return {
    type: actionTypes.GET_DEAL_TYPE_SUCCESS,
    response,
  };
};

export const updateDealType = (deal) => {
  return {
    type: actionTypes.UPDATE_DEAL_TYPE,
    deal,
  };
};

export const updateDealTypeSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_DEAL_TYPE_SUCCESS,
    response,
  };
};

export const getInvestorTaxList = () => {
  return {
    type: actionTypes.GET_INVESTOR_TAX_LIST,
  };
};

export const getInvestorTaxListSuccess = (response) => {
  return {
    type: actionTypes.GET_INVESTOR_TAX_LIST_SUCCESS,
    response,
  };
};

export const deleteInvestorTax = (id) => {
  return {
    type: actionTypes.DELETE_INVESTOR_TAX,
    id,
  };
};

export const getInvestorTax = (id) => {
  return {
    type: actionTypes.GET_INVESTOR_TAX,
    id,
  };
};

export const getInvestorTaxSuccess = (response) => {
  return {
    type: actionTypes.GET_INVESTOR_TAX_SUCCESS,
    response,
  };
};

export const updateInvestorTax = (tax) => {
  return {
    type: actionTypes.UPDATE_INVESTOR_TAX,
    tax,
  };
};

export const updateInvestorTaxSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_INVESTOR_TAX_SUCCESS,
    response,
  };
};

export const createInvestorTax = (tax) => {
  return {
    type: actionTypes.CREATE_INVESTOR_TAX,
    tax,
  };
};

export const createInvestorTaxSuccess = (response) => {
  return {
    type: actionTypes.CREATE_INVESTOR_TAX_SUCCESS,
    response,
  };
};