import axios from "../../../config/AxiosConfig";
import { put, call } from "redux-saga/effects";
import {
    WALLET_STATUS_UPDATE_API,
    WALLET_TRANSACTION_LIST_API
} from "../../actions/apiCollections";
import * as actions from "../../actions/wallet/walletActions";

export function* getWalletTransactionsSaga(action) {
    yield put(actions.walletStart(action.pageLimit));
    try {
      const response = yield axios.get(`${WALLET_TRANSACTION_LIST_API}`, {
        params: { 
          ...(action.searchData.status !== "" && {
            status: action.searchData.status,
          }),
          ...(action.searchData.transactionNumber !== "" && {
            transactionNumber: action.searchData.transactionNumber,
          }),
          ...(action.searchData.transactionType !== "" && {
            transactionType: action.searchData.transactionType,
          }),
          ...(action.searchData.walletType !== "" && {
            walletType: action.searchData.walletType,
          }),
          limit: 10 * action.pageLimit },
      });
      yield put(actions.getWalletTransactionsSuccess(response?.data?.data?.data));
    } catch (error) {
      yield put(actions.walletFail(error.response));
    }
  }

  export function* updateWalletTransactionSaga(action) {
    try {
      const response = yield axios.patch(`${WALLET_STATUS_UPDATE_API}/${action.id}/admin`, action.data);
      yield put(actions.updateWalletTransactionSuccess(response?.data?.data));
      const obj = {
        pageLimit: action.pageLimit,
        searchData: action.searchData
      };
      
      yield call(getWalletTransactionsSaga, obj);
    } catch (error) {
      yield put(actions.walletFail(error.response));
    }
  }