import * as actionTypes from "./actionTypes";
export const revenueStart = () => {
  return {
    type: actionTypes.REVENUE_START,
  };
};

export const revenueFail = (error) => {
  return {
    type: actionTypes.REVENUE_FAIL,
    error,
  };
};

export const successCommon = (response) => {
  return {
    type: actionTypes.SUCCESS_COMMON,
    response,
  };
};

export const getRevenue = () => {
  return {
    type: actionTypes.GET_REVENUE,
  };
};

export const getRevenueSuccess = (response) => {
  return {
    type: actionTypes.GET_REVENUE_SUCCESS,
    response,
  };
};

export const createRevenue = (revenue) => {
  return {
    type: actionTypes.CREATE_REVENUE,
    revenue,
  };
};

export const updateRevenue = (revenue) => {
  return {
    type: actionTypes.UPDATE_REVENUE,
    revenue,
  };
};
