import * as actionTypes from "./actionTypes";

export const spamStart = () => {
  return {
    type: actionTypes.SPAM_START,
  };
};

export const spamFail = (error) => {
  return {
    type: actionTypes.SPAM_FAIL,
    error,
  };
};

export const spamCreateSuccess = (response) => {
  return {
    type: actionTypes.SPAM_SUCCESS,
    response,
  };
};

export const spamUpdateSuccess = (response) => {
  return {
    type: actionTypes.SPAM_UPDATE_SUCCESS,
    response,
  };
};

export const getSpamSetting = () => {
  return {
    type: actionTypes.GET_SPAM_SETTING,
  };
};

export const getSpamSettingSuccess = (response) => {
  return {
    type: actionTypes.GET_SPAM_SETTING_SUCCESS,
    response,
  };
};

export const createSpamSetting = (spam) => {
  return {
    type: actionTypes.CREATE_SPAM_SETTING,
    spam,
  };
};

export const updateSpamSetting = (spam) => {
  return {
    type: actionTypes.UPDATE_SPAM_SETTING,
    spam,
  };
};

export const getSpammer = (id) => {
  return {
    type: actionTypes.GET_SPAMMER,
    id,
  };
};

export const getSpammerSuccess = (response) => {
  return {
    type: actionTypes.GET_SPAMMER_SUCCESS,
    response,
  };
};

export const getSpammers = () => {
  return {
    type: actionTypes.GET_SPAMMERS,
  };
};

export const getSpammersSuccess = (response) => {
  return {
    type: actionTypes.GET_SPAMMERS_SUCCESS,
    response,
  };
};

export const createSpammer = (spammer) => {
  return {
    type: actionTypes.CREATE_SPAMMER,
    spammer,
  };
};

export const createSpammerSuccess = (response) => {
  return {
    type: actionTypes.CREATE_SPAMMER_SUCCESS,
    response,
  };
};

export const updateSpammer = (spammer) => {
  return {
    type: actionTypes.UPDATE_SPAMMER,
    spammer,
  };
};

export const updateSpammerSuccess = (spammer) => {
  return {
    type: actionTypes.UPDATE_SPAMMER_SUCCESS,
    spammer,
  };
};
