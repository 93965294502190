import { put } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import {
  exportCSVStart,
  exportCSVFail,
  getFrontUserCSVListSuccess,
  getRePaymentDistributionListSuccess,
  getDistributionViewCSVListSuccess,
  getRePaymentReceivedCSVListSuccess
} from "../../actions";
import {
  FRONT_END_USER,
  DISTRIBUTION_API,
  REPAYMENT_API
} from "../../actions/apiCollections";

let PageLimit = 5000;

export function* getFrontUserCSVList(action) {
  yield put(exportCSVStart());
  try {
    const response = yield axios.get(FRONT_END_USER, {
      params: { limit: 10 * PageLimit },
    });
    yield put(getFrontUserCSVListSuccess(response.data.data.data.docs));
  } catch (error) {
    yield put(exportCSVFail(error.response));
  }
}

export function* getRePaymentDistributionCSVListSaga(action) {
  yield put(exportCSVStart());
  try {
    const response = yield axios.get(`${DISTRIBUTION_API}${action.id}`);
    yield put(getRePaymentDistributionListSuccess(response.data.data.data?.distributionData));
  } catch (error) {
    yield put(exportCSVFail(error.response));
  }
}

export function* getDistributionViewCSVListSaga(action) {
  yield put(exportCSVStart());
  try {
    const response = yield axios.get(`${DISTRIBUTION_API}${action.id}`);
    yield put(getDistributionViewCSVListSuccess(response.data.data.data?.distributionData));
  } catch (error) {
    yield put(exportCSVFail(error.response));
  }
}

export function* getRePaymentReceivedCSVListSaga(action) {
  yield put(exportCSVStart());
  try {
    const response = yield axios.get(`${REPAYMENT_API}${action.id}`);
    yield put(getRePaymentReceivedCSVListSuccess(response.data.data.data?.repaymentData));
  } catch (error) {
    yield put(exportCSVFail(error.response));
  }
}