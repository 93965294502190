import * as actionTypes from "../../actions/exportCsv/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  exportError: [],
  exportLoading: false,
  exportFrontUserList: [],
  exportRePaymentDistributionList: [],
  exportDistributionViewList: [],
  exportRePaymentReceivedList: [],
};

const exportingStart = (state, action) => {
  return updateObject(state, {
    exportLoading: true,
    exportError: [],
    exportFrontUserList: [],
    exportRePaymentDistributionList: [],
    exportDistributionViewList: [],
    exportRePaymentReceivedList: [],
  });
};

const exportCSVFail = (state, action) => {
  return updateObject(state, {
    exportError: action.error,
    exportLoading: false,
  });
};

const getFrontUserCSVListSuccess = (state, action) => {
  return updateObject(state, {
    exportLoading: false,
    exportError: [],
    exportFrontUserList: action.response,
  });
};

const getRePaymentDistributionListSuccess = (state, action) => {
  return updateObject(state, {
    exportLoading: false,
    exportError: [],
    exportRePaymentDistributionList: action.response,
  });
};

const getDistributionViewCSVListSuccess = (state, action) => {
  return updateObject(state, {
    exportLoading: false,
    exportError: [],
    exportDistributionViewList: action.response
  });
};

const getRePaymentReceivedCSVListSuccess = (state, action) => {
  return updateObject(state, {
    exportLoading: false,
    exportError: [],
    exportRePaymentReceivedList: action.response
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EXPORT_CSV_START:
      return exportingStart(state, action);
    case actionTypes.EXPORT_CSV_COMMON_ERROR:
      return exportCSVFail(state, action);
    case actionTypes.EXPORT_CSV_FRONT_USERS_SUCCESS:
      return getFrontUserCSVListSuccess(state, action);
    case actionTypes.GET_REPAYMENT_DISTRIBUTION_CSV_LIST_SUCCESS:
      return getRePaymentDistributionListSuccess(state, action);
    case actionTypes.GET_DISTRIBUTION_VIEW_CSV_LIST_SUCCESS:
      return getDistributionViewCSVListSuccess(state, action);
    case actionTypes.GET_REPAYMENT_RECEIVED_CSV_LIST_SUCCESS:
      return getRePaymentReceivedCSVListSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
