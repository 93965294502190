import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";
import Select from "react-select";
import { Form, Row, Col, Alert } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { isEmpty } from "lodash";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../_metronic/_partials/controls";
import { getDemoMode } from "../../../helpers";
import {
  addTransaction,
  getActiveCamapignList,
  getInvestorList
} from "../../../store/actions/transactions/transactionsActions";
import { formatCurrency } from "../../../helpers/numberFormat";

const AddTransaction = ({history}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  
  const demoMode = getDemoMode();

  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignSelectOptions, setCampaignSelectOptions] = useState([]);
  const [investorsSelectOptions, setInvestorsSelectOptions] = useState([]);
  const [numberOfShares, setNumberOfShares] = useState(0);

  useEffect(() => {
    dispatch(getActiveCamapignList());
    dispatch(getInvestorList());
  }, [dispatch]);

  const { activeCamapigns, investors, successAlert, isSpinning, error } = useSelector((state) => state.transaction);
  const { generalSettings } = useSelector((state) => state.settings);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue
  } = useForm({ mode: "onChange" });

  const backToList = () => {
    history.push(`/transaction/`);
  };

  useEffect(() => {
		const camapignList = activeCamapigns?.map(({ companyId, _id: value }) => ({
			label: companyId?.companyName,
			value
		}));
		setCampaignSelectOptions(camapignList);

	}, [activeCamapigns]);

  useEffect(() => {
		const investorList = investors?.map(({ fullName: label, _id: value }) => ({
			label,
			value
		}));
		setInvestorsSelectOptions(investorList);
	}, [ investors]);

  const oncampaignIdSelect = (selectedCampaignId) => {
    setSelectedCampaign(activeCamapigns?.filter(campaign => campaign._id === selectedCampaignId)?.[0])
  };
  const MySwal = withReactContent(Swal);
  const alertBox = (title, text, icon) => {
    MySwal.fire({
      title: title,
      text: text,
      icon: icon,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result["isConfirmed"]) {
        history.push(`/transaction/`);
      }
    });
  };

  useEffect(() => {
		if (successAlert) {
      alertBox(
        intl.formatMessage({ id: "transaction.addTransaction.created" }),
        intl.formatMessage({ id: "transaction.addTransaction.created.message" }),
        "success"
      );
    }
	}, [ successAlert]);

  const onSubmitAddTransaction = (data) => {
    data.createdAt= new Date();
    dispatch(addTransaction(data));
    reset();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Card>
        <CardHeader title={intl.formatMessage({ id: "transaction.addTransaction.title" })}>
          <CardHeaderToolbar>
            <button type="button" onClick={backToList} className="btn btn-light">
              <i className="fa fa-arrow-left"></i>
              {intl.formatMessage({ id: "AUTH.GENERAL.BACK_BUTTON" })}
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Row className="justify-content-md-center">
            <Col md={8}>
              {!isEmpty(error) ? <Alert variant= "danger">{error}</Alert> : null}
              <Form
                id="AddTransactionForm"
                className="form form-label-right"
                onSubmit={handleSubmit(onSubmitAddTransaction)}
              >
                <Form.Group>
                  <Form.Label>{intl.formatMessage({ id: "transaction.addTransaction.activeCampaigns" })}</Form.Label>
                  {" "}<span className="text-important">*</span>
                  <Controller
                    name={"campaignId"}
                    control={control}
                    rules={{ required: intl.formatMessage({ id: "COMMON.FIELD.REQ" }) }}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Select
                          placeholder={intl.formatMessage({ id: "transaction.addTransaction.selectCampaign" })}
                          classNamePrefix="react-select"
                          className={classNames("react-select-container", {
                            "is-invalid": errors?.campaignId
                          })}
                          inputId="addTransactionCampaignId"
                          options={campaignSelectOptions}
                          value={campaignSelectOptions?.find((c) => c.value === value)}
                          onChange={(val) => {
                            reset({campaignId: val.value});
                            oncampaignIdSelect(val.value);
                            onChange(val.value);
                          }}
                        />
                      );
                    }}
                  />
                  <ErrorMessage
                      errors={errors}
                      name="campaignId"
                      render={({ message }) => (
                        <div className="invalid-feedback" id="addTransactionCampaignIdErr">{message}</div>
                      )}
                    />
                </Form.Group>
                <Form.Group >
                  <Form.Label>{intl.formatMessage({ id: "transaction.addTransaction.investorDetail" })}</Form.Label>
                  {" "}<span className="text-important">*</span>
                  <Controller
                    name={"user"}
                    control={control}
                    rules={{ required: intl.formatMessage({ id: "COMMON.FIELD.REQ" }) }}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Select
                          placeholder={intl.formatMessage({ id: "transaction.addTransaction.selectUser" })}
                          className={classNames("react-select-container", {
                            "is-invalid": errors?.user
                          })}
                          inputId="addTransactionCampaignId"
                          options={investorsSelectOptions}
                          value={investorsSelectOptions?.find((c) => c.value === value) || ""}
                          onChange={(val) => {
                            onChange(val.value)
                          }}
                        />
                      );
                    }}
                  />
                  <ErrorMessage
                      errors={errors}
                      name="user"
                      render={({ message }) => (
                        <div className="invalid-feedback" id="addTransactionCampaignIdErr">{message}</div>
                      )}
                    />
                </Form.Group>
                { selectedCampaign ? 
                  <>
                      <hr/>
                      <Row>
                        <Col md={3}>
                          <Card className="border border-secondary text-center">
                          <div className="m-3">
                            <h5 className="text-muted">{intl.formatMessage({ id: "transaction.addTransaction.goal" })}</h5>
                            <strong>
                              {formatCurrency(
                                selectedCampaign?.goal ?? 0,
                                generalSettings.currencySymbolSide,
                                selectedCampaign.equityCurrencySymbol ??
                                  "",
                                  selectedCampaign?.equityCurrencyCode ?? "",
                                generalSettings?.decimalPoints
                              )}
                            </strong>
                          </div>
                          </Card>
                        </Col>
                        <Col md={4}>
                          <Card className="border border-secondary text-center">
                          <div className="m-3">
                            <h5 className="text-muted">{intl.formatMessage({ id: "transaction.addTransaction.minimumRaise" })}</h5>
                            <strong>
                            {formatCurrency(
                                selectedCampaign?.minimumRaise ?? 0,
                                generalSettings.currencySymbolSide,
                                selectedCampaign.equityCurrencySymbol ??
                                  "",
                                  selectedCampaign?.equityCurrencyCode ?? "",
                                generalSettings?.decimalPoints
                              )}
                            </strong>
                          </div>
                          </Card>
                        </Col>
                        <Col md={4}>
                          <Card className="border border-secondary text-center">
                            <div className="m-3">
                              <h5 className="text-muted">{intl.formatMessage({ id: "transaction.addTransaction.maximumRaise" })}</h5>
                              <strong>
                                {formatCurrency(
                                  selectedCampaign?.maximumRaise ?? 0,
                                  generalSettings.currencySymbolSide,
                                  selectedCampaign.equityCurrencySymbol ??
                                    "",
                                    selectedCampaign?.equityCurrencyCode ?? "",
                                  generalSettings?.decimalPoints
                                )}
                              </strong>
                            </div>
                          </Card>
                        </Col>
                      </Row>
                   
                    { 
                      selectedCampaign?.termsSlug === "equity" ? 
                      (
                        <>
                          <hr/>
                          <Row>
                          <Col>
                              <Card className="border border-secondary">
                                <span className="m-3">
                                  {intl.formatMessage({ id: "transaction.addTransaction.availableShare" })}: {" "}
                                  <b>{selectedCampaign?.availableShares - numberOfShares}</b>
                                </span>
                                
                              </Card>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={3}>
                              <Card className="border border-secondary text-center">
                              <div className="m-3">
                                <h5 className="text-muted">{intl.formatMessage({ id: "transaction.addTransaction.shareUnits" })}</h5>
                                <strong>
                                  {numberOfShares}
                                </strong>
                              </div>
                              </Card>
                            </Col>
                            <Card>
                                <strong className="m-2">
                                  <h5 className="text-center mt-5">x</h5>
                                </strong>
                              </Card>
                            <Col md={4}>
                              <Card className="border border-secondary text-center">
                                <div className="m-3">
                                  <h5 className="text-muted">{intl.formatMessage({ id: "transaction.addTransaction.shareUnitPrice" })}</h5>
                                  <strong>
                                  {formatCurrency(
                                      selectedCampaign?.pricePerShare ?? 0,
                                      generalSettings.currencySymbolSide,
                                      selectedCampaign.equityCurrencySymbol ??
                                        "",
                                        selectedCampaign?.equityCurrencyCode ?? "",
                                      generalSettings?.decimalPoints
                                    )}
                                  </strong>
                                </div>
                              </Card>
                            </Col>
                              <Card>
                                <strong className="m-2">
                                  <h5 className="text-center mt-5">=</h5>
                                </strong>
                              </Card>
                            <Col md={4}>
                              <Card className="border border-secondary text-center">
                              <div className="m-3">
                                <h5 className="text-muted">{intl.formatMessage({ id: "transaction.addTransaction.totalInvestment" })}</h5>
                                <strong>
                                {formatCurrency(
                                    selectedCampaign?.pricePerShare * numberOfShares,
                                    generalSettings.currencySymbolSide,
                                    selectedCampaign.equityCurrencySymbol ??
                                      "",
                                      selectedCampaign?.equityCurrencyCode ?? "",
                                    generalSettings?.decimalPoints
                                  )}
                                </strong>
                              </div>
                              </Card>
                            </Col>
                          </Row>
                        </>
                        
                      )
                      : null
                    }
                    {selectedCampaign?.termsSlug === "equity" ? 
                      (<Form.Group>
                        <Form.Label>{intl.formatMessage({ id: "transaction.addTransaction.numberOfShares" })}</Form.Label>
                        {" "}<span className="text-important">*</span>
                        <Form.Control
                          name="purchasedShares"
                          id="addTransactionPurchasedShares"
                          className={classNames("form-control", {
                            "is-invalid": errors.purchasedShares,
                          })}
                          {...register("purchasedShares", {
                            required: intl.formatMessage({
                              id: "COMMON.FIELD.REQ",
                            }),
                            pattern: {
                              value: /^[0-9]*$/,
                              message: intl.formatMessage({
                                id: "ONLY.NUMBER",
                              })
                            },
                            min: {
                              value: 1,
                              message: `${intl.formatMessage({
                                id: "transaction.addTransaction.minShare",
                              })} ${1}`,
                            },
                            max: {
                              value: selectedCampaign?.availableShares,
                              message: `${intl.formatMessage({
                                id: "transaction.addTransaction.maxShare",
                              })} ${selectedCampaign?.availableShares}`,
                            },
                            validate: {
                              maxInvestment: v => parseInt(v) * selectedCampaign?.pricePerShare < selectedCampaign?.maximumRaise || `${intl.formatMessage({
                                id: "transaction.addTransaction.maxAmount",
                              })} ${selectedCampaign?.maximumRaise}`,
                            },
                            onChange: e => {
                              setValue("amount", parseInt(e.target.value) * selectedCampaign?.pricePerShare);
                              setNumberOfShares(parseInt(e.target.value) || 0);
                            }
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="purchasedShares"
                          render={({ message }) => (
                            <div className="invalid-feedback" id="addTransactionPurchasedSharesErr">{message}</div>
                          )}
                        />
                      </Form.Group>)
                      :
                      (<Form.Group>
                        <Form.Label>{intl.formatMessage({ id: "transaction.addTransaction.amount" })}</Form.Label>
                        {" "}<span className="text-important">*</span>
                        <Form.Control
                          name="amount"
                          id="addTransactionAmount"
                          className={classNames("form-control", {
                            "is-invalid": errors.amount,
                          })}
                          {...register("amount", {
                            required: intl.formatMessage({
                              id: "COMMON.FIELD.REQ",
                            }),
                            pattern: {
                              value: /^[0-9]*$/,
                              message: intl.formatMessage({
                                id: "ONLY.NUMBER",
                              })
                            },
                            min: {
                              value: 1,
                              message: `${intl.formatMessage({
                                id: "transaction.addTransaction.minAmount",
                              })} 1`,
                            },
                            max: {
                              value: selectedCampaign?.maximumRaise,
                              message: `${intl.formatMessage({
                                id: "transaction.addTransaction.maxAmount",
                              })} ${selectedCampaign?.maximumRaise}`,
                            }
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="amount"
                          render={({ message }) => (
                            <div className="invalid-feedback" id="addTransactionAmountErr">{message}</div>
                          )}
                        />
                      </Form.Group>)
                    }
                    <Form.Group>
                      <Form.Label>{intl.formatMessage({ id: "transaction.addTransaction.comments" })}</Form.Label>
                      <Form.Control
                        name="note"
                        as="textarea"
                        rows="3"
                        id="addTransactionNote"
                        className={classNames("form-control", {
                          "is-invalid": errors.note,
                        })}
                        {...register("note",

                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="note"
                        render={({ message }) => (
                          <div className="invalid-feedback" id="addTransactionNoteErr">{message}</div>
                        )}
                      />
                    </Form.Group>
                    {demoMode === "false" ? (
                      <button
                        type="submit"
                        id="addTransactionSubmit"
                        className="btn btn-primary mr-2 pull-right"
                        disabled={isSpinning}
                      >
                        {isSpinning ? 
													<div className="spinner-border spinner-border-sm" role="status">
														<span className="sr-only" />
													</div>
													:
													<span>{intl.formatMessage({ id: "SAVE" })}</span>
												}
                      </button>
                    ) : null}
                  </>
                : null}
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default AddTransaction;
