import * as actionTypes from "./actionTypes";

export const emailStart = () => {
  return {
    type: actionTypes.EMAIL_SETTINGS_START,
  };
};

export const emailFail = (error) => {
  return {
    type: actionTypes.EMAIL_SETTINGS_FAIL,
    error,
  };
};

export const getEmailTemplates = () => {
  return {
    type: actionTypes.GET_EMAIL_TEMPLATES,
  };
};

export const getEmailTemplatesSuccess = (response) => {
  return {
    type: actionTypes.GET_EMAIL_TEMPLATES_SUCCESS,
    response,
  };
};

export const getEmailTemplate = (id) => {
  return {
    type: actionTypes.GET_EMAIL_TEMPLATE,
    id,
  };
};

export const getEmailTemplateSuccess = (response) => {
  return {
    type: actionTypes.GET_EMAIL_TEMPLATE_SUCCESS,
    response,
  };
};

export const createEmailTemplate = (emailTemplate) => {
  return {
    type: actionTypes.CREATE_EMAIL_TEMPlATE,
    emailTemplate,
  };
};

export const createEmailTemplateSuccess = (response) => {
  return {
    type: actionTypes.CREATE_EMAIL_TEMPlATE_SUCCESS,
    response,
  };
};

export const deleteEmailTemplate = (id) => {
  return {
    type: actionTypes.DELETE_EMAIL_TEMPLATE,
    id,
  };
};

export const sendTestEmail = (emailData) => {
  return {
    type: actionTypes.SEND_TEST_EMAIL,
    emailData,
  };
};

export const sendTestEmailSuccess = (response) => {
  return {
    type: actionTypes.SEND_TEST_EMAIL_SUCCESS,
    response,
  };
};

export const updateEmailTemplate = (emailTemplate) => {
  return {
    type: actionTypes.UPDATE_EMAIL_TEMPlATE,
    emailTemplate,
  };
};

export const updateEmailTemplateSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_EMAIL_TEMPlATE_SUCCESS,
    response,
  };
};
export const updateMasterEmail = (emailData) => {
  return {
    type: actionTypes.UPDATE_MASTER_EMAIL,
    emailData,
  };
};

export const updateMasterEmailSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_MASTER_EMAIL_SUCCESS,
    response,
  };
};

export const bulkUpdateEmail = (emailData) => {
  return {
    type: actionTypes.BULK_UPDATE_EMAIL_TEMPlATE,
    emailData,
  };
};

export const bulkUpdateEmailSuccess = (response) => {
  return {
    type: actionTypes.BULK_UPDATE_EMAIL_TEMPlATE_SUCCESS,
    response,
  };
};
