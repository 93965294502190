import * as actionTypes from "./actionTypes";

export const miscStart = () => {
  return {
    type: actionTypes.MISC_START,
  };
};

export const getCurrencies = () => {
  return {
    type: actionTypes.GET_CURRENCIES,
  };
};

export const getCurrenciesSuccess = (response) => {
  return {
    type: actionTypes.GET_CURRENCIES_SUCCESS,
    response,
  };
};

export const createCurrency = (currency) => {
  return {
    type: actionTypes.CREATE_CURRENCY,
    currency,
  };
};

export const createCurrencySuccess = (response) => {
  return {
    type: actionTypes.CREATE_CURRENCY_SUCCESS,
    response,
  };
};

export const getCurrency = (id) => {
  return {
    type: actionTypes.GET_CURRENCY,
    id,
  };
};

export const getCurrencySuccess = (response) => {
  return {
    type: actionTypes.GET_CURRENCY_SUCCESS,
    response,
  };
};
export const deleteCurrency = (id) => {
  return {
    type: actionTypes.DELETE_CURRENCY,
    id,
  };
};
export const updateCurrency = (currencyData) => {
  return {
    type: actionTypes.UPDATE_CURRENCY,
    currencyData,
  };
};

export const updateCurrencySuccess = (response) => {
  return {
    type: actionTypes.UPDATE_CURRENCY_SUCCESS,
    response,
  };
};

export const bulkUpdateCurrency = (currency) => {
  return {
    type: actionTypes.BULK_UPDATE_CURRENCY,
    currency,
  };
};

export const bulkUpdateCurrencySuccess = (response) => {
  return {
    type: actionTypes.BULK_UPDATE_CURRENCY_SUCCESS,
    response,
  };
};

export const bulkDeleteCurrency = (currency) => {
  return {
    type: actionTypes.BULK_DELETE_CURRENCY,
    currency,
  };
};

export const currenciesFail = (error) => {
  return {
    type: actionTypes.CURRENCIES_FAIL,
    error,
  };
};

export const getMiscLanguages = () => {
  return {
    type: actionTypes.GET_MISC_LANGUAGES,
  };
};

export const getMiscLanguagesSuccess = (response) => {
  return {
    type: actionTypes.GET_MISC_LANGUAGES_SUCCESS,
    response,
  };
};

export const createMiscLanguage = (languageData) => {
  return {
    type: actionTypes.CREATE_MISC_LANGUAGE,
    languageData,
  };
};

export const createMiscLanguageSuccess = (response) => {
  return {
    type: actionTypes.CREATE_MISC_LANGUAGE_SUCCESS,
    response,
  };
};

export const deleteLanguage = (id) => {
  return {
    type: actionTypes.DELETE_MISC_LANGUAGE,
    id,
  };
};

export const setDefaultLanguage = (id) => {
  return {
    type: actionTypes.SET_DEFAULT_LANGUAGE,
    id,
  };
};

export const miscFail = (error) => {
  return {
    type: actionTypes.MISC_FAIL,
    error,
  };
};

export const getMiscLanguage = (id) => {
  return {
    type: actionTypes.GET_MISC_LANGUAGE,
    id,
  };
};

export const getMiscLanguageSuccess = (response) => {
  return {
    type: actionTypes.GET_MISC_LANGUAGE_SUCCESS,
    response,
  };
};

export const miscLanguageUpdate = (languageData) => {
  return {
    type: actionTypes.UPDATE_MISC_LANGUAGE,
    languageData,
  };
};

export const miscLanguageUpdateSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_MISC_LANGUAGE_SUCCESS,
    response,
  };
};

export const getMiscSliders = (language) => {
  return {
    type: actionTypes.GET_MISC_SLIDERS,
    language,
  };
};

export const getMiscSliderSuccess = (response) => {
  return {
    type: actionTypes.GET_MISC_SLIDERS_SUCCESS,
    response,
  };
};

export const createSlider = (slider, language) => {
  return {
    type: actionTypes.CREATE_MISC_SLIDER,
    slider,
    language
  };
};

export const deleteSlider = (id, language) => {
  return {
    type: actionTypes.DELETE_MISC_SLIDER,
    id,
    language
  };
};

export const getSlider = (id) => {
  return {
    type: actionTypes.GET_MISC_SLIDER,
    id,
  };
};

export const getSliderSuccess = (response) => {
  return {
    type: actionTypes.GET_MISC_SLIDER_SUCCESS,
    response,
  };
};

export const createMiscSliderSuccess = (response) => {
  return {
    type: actionTypes.CREATE_MISC_SLIDER_SUCCESS,
    response,
  };
};

export const updateMiscSlider = (slider, language) => {
  return {
    type: actionTypes.MISC_SLIDER_UPDATE,
    slider,
    language
  };
};

export const updateMiscSliderSuccess = (response) => {
  return {
    type: actionTypes.MISC_SLIDER_UPDATE_SUCCESS,
    response,
  };
};

export const bulkSliderUpdate = (slider, language) => {
  return {
    type: actionTypes.BULK_SLIDER_UPDATE,
    slider,
    language
  };
};

export const bulkSliderUpdateSuccess = (response) => {
  return {
    type: actionTypes.BULK_SLIDER_UPDATE_SUCCESS,
    response,
  };
};

export const bulkSliderDelete = (slider, language) => {
  return {
    type: actionTypes.BULK_SLIDER_DELETE,
    slider,
    language
  };
};

/**
 *
 * Global functions for Misc
 */
export const languages = (lang) => {
  return {
    type: actionTypes.GET_MANAGE_DROPDOWN_LANGUAGES,
    lang,
  };
};

export const languagesSuccess = (response) => {
  return {
    type: actionTypes.GET_MANAGE_LANGUAGE_SUCCESS,
    response,
  };
};

export const languageId = (language) => {
  return {
    type: actionTypes.GET_MANAGE_DROPDOWN_LANG_ID,
    language,
  };
};
/**
 *
 * @param {String} language `String`
 * @param {String} menuType `String`
 */
export const getMenu = (language, menuType) => {
  return {
    type: actionTypes.GET_MENU,
    language,
    menuType,
  };
};
/**
 * @returns {Object} `Object`
 * @param {Object} response `Object`
 */
export const getMenuSuccess = (response) => {
  return {
    type: actionTypes.GET_MENU_SUCCESS,
    response,
  };
};

export const getContentPage = (language) => {
  return {
    type: actionTypes.GET_CONTENT_PAGE,
    language,
  };
};

export const getContentPageSuccess = (response) => {
  return {
    type: actionTypes.GET_CONTENT_PAGE_SUCCESS,
    response,
  };
};

export const createMenuItem = (menuItem) => {
  return {
    type: actionTypes.CREATE_MENU_ITEM,
    menuItem,
  };
};

export const deleteMenuItem = (menuType, id, language) => {
  return {
    type: actionTypes.DELETE_MENU_ITEM,
    menuType,
    id,
    language
  };
};

/**
 * @description We need these two to make the API call via saga
 * i.e /menus/:menuType/:id
 * @param {String} menuType `String`
 * @param {String} id `String`
 */
export const getMenuItem = (menuType, id) => {
  return {
    type: actionTypes.GET_MENU_ITEM,
    menuType,
    id,
  };
};

export const getMenuItemSuccess = (response) => {
  return {
    type: actionTypes.GET_MENU_ITEM_SUCCESS,
    response,
  };
};

/**
 * We send the entire object to be updated and then access the Object's keys when we need
 * i.e. `action.menu.menuType` or `action.menu.id`
 * @param {JSON} menu `Object`
 */
export const updateMenuItem = (menu) => {
  return {
    type: actionTypes.UPDATE_MENU_ITEM,
    menu,
  };
};

export const updateMenuItemSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_MENU_ITEM_SUCCESS,
    response,
  };
};

export const getSuccessStories = () => {
  return {
    type: actionTypes.GET_SUCCESS_STORIES,
  };
};

export const getSuccessStoriesSuccess = (response) => {
  return {
    type: actionTypes.GET_SUCCESS_STORIES_SUCCESS,
    response,
  };
};

export const getSuccessStory = (id) => {
  return {
    type: actionTypes.GET_SUCCESS_STORY,
    id,
  };
};

export const getSuccessStorySuccess = (response) => {
  return {
    type: actionTypes.GET_SUCCESS_STORY_SUCCESS,
    response,
  };
};

export const createSuccessStories = (story) => {
  return {
    type: actionTypes.CREATE_SUCCESS_STORIES,
    story,
  };
};

export const createSuccessStoriesSucess = (response) => {
  return {
    type: actionTypes.CREATE_SUCCESS_STORIES_SUCCESS,
    response,
  };
};

export const updateSuccessStories = (story) => {
  return {
    type: actionTypes.UPDATE_SUCCESS_STORIES,
    story,
  };
};

export const updateSuccessStoriesSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_SUCCESS_STORIES_SUCCESS,
    response,
  };
};

export const deleteSuccessStories = (id) => {
  return {
    type: actionTypes.DELETE_SUCCESS_STORIES,
    id,
  };
};
