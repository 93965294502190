import * as actionTypes from "./actionTypes";

export const transactionsStart = () => {
  return {
    type: actionTypes.TRANSACTION_START,
  };
};

export const getTransactions = (response) => {
  return {
    type: actionTypes.GET_TRANSACTIONS,
    response,
  };
};

export const getTransactionsSuccess = (response) => {
  return {
    type: actionTypes.GET_TRANSACTIONS_SUCCESS,
    response,
  };
};

export const getTransaction = (campaignId) => {
  return {
    type: actionTypes.GET_TRANSACTION,
    campaignId,
  };
};

export const getTransactionSuccess = (response) => {
  return {
    type: actionTypes.GET_TRANSACTION_SUCCESS,
    response,
  };
};

export const transactionFail = (error) => {
  return {
    type: actionTypes.TRANSACTION_FAIL,
    error,
  };
};
/**
 * An action to approve the transaction
 */
export const updateTransaction = (response) => {
  return {
    type: actionTypes.UPDATE_TRANSACTION,
    response,
  };
};

export const updateTransactionSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_TRANSACTION_SUCCESS,
    response,
  };
};

export const getSearchTransactions = (response,limitNo) => {
  return {
    type: actionTypes.GET_SEARCH_TRANSACTIONS,
    response,limitNo
  };
};

export const getActiveCamapignList = () => {
  return {
    type: actionTypes.GET_ACTIVE_CAMPAIGN_LIST
  };
};

export const getActiveCamapignListSuccess = (response) => {
  return {
    type: actionTypes.GET_ACTIVE_CAMPAIGN_LIST_SUCCESS,
    response
  };
};

export const getInvestorList = () => {
  return {
    type: actionTypes.GET_INVESTOR_LIST
  };
};

export const getInvestorListSuccess = (response) => {
  return {
    type: actionTypes.GET_INVESTOR_LIST_SUCCESS,
    response
  };
};

export const addTransaction = (transactionData) => {
  return {
    type: actionTypes.ADD_TRANSACTION,
    transactionData
  };
};

export const addTransactionSuccess = (response) => {
  return {
    type: actionTypes.ADD_TRANSACTION_SUCCESS,
    response
  };
};
