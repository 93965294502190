import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";

import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import { getDemoMode } from "../../../../helpers";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { useHistory } from "react-router-dom";
// import { AdminChangePassword } from "../extras/dropdowns/AdminChangePassword";

export function Topbar() {
  let demoMode = getDemoMode();
  const uiService = useHtmlClassService();
  const history = useHistory();
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(uiService.config, "extras.search.display"),
      viewNotificationsDisplay: objectPath.get(uiService.config, "extras.notifications.display"),
      viewQuickActionsDisplay: objectPath.get(uiService.config, "extras.quick-actions.display"),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(uiService.config, "extras.quick-panel.display"),
      viewLanguagesDisplay: objectPath.get(uiService.config, "extras.languages.display"),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
      viewAdminDisplay: objectPath.get(uiService.config, "extras.admin.display"),
    };
  }, [uiService]);

  const inbox = () => {
    history.push(`/inbox`);
  };

  return (
    <>
    {(demoMode === "true" ?
      <div className="pull-left pt-7 pr-2 pl-2" style={{ fontWeight: "bold", fontSize: "12px", color:"white",  backgroundColor:"#6048fe" }} >
        This demo is in sandbox mode and you will have limited access to the admin panel options. Have a question? <a className="text-warning font-weight-bold" href="http://www.fundraisingscript.com/contact_us.php" target="_blank">Contact Us</a>
      </div> : "")
    }
    <div className="topbar">
      {/* <OverlayTrigger placement="bottom" overlay={<Tooltip id="quick-panel-tooltip">Inbox</Tooltip>}>
        <div className="topbar-item" data-toggle="tooltip" title="Inbox" data-placement="right" onClick={inbox}>
          <div className="btn btn-icon btn-clean btn-lg mr-1">
            <span className="svg-icon svg-icon-xl svg-icon-primary symbol">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Mail-opened.svg")} />
              <i className="symbol-badge bg-danger" />
            </span>
          </div>
        </div>
      </OverlayTrigger> */}

      {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />}

      {/* {layoutProps.viewQuickActionsDisplay && (<QuickActionsDropdown/>)}

            {layoutProps.viewCartDisplay && (<MyCartDropdown/>)}

            {layoutProps.viewQuickPanelDisplay && (
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="quick-panel-tooltip">Quick panel</Tooltip>}
                >
                    <div
                        className="topbar-item"
                        data-toggle="tooltip"
                        title="Quick panel"
                        data-placement="right"
                    >
                        <div className="btn btn-icon btn-clean btn-lg mr-1" id="kt_quick_panel_toggle">
                            <span className="svg-icon svg-icon-xl svg-icon-primary">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")}/>
                            </span>
                        </div>
                    </div>
                </OverlayTrigger>
            )}
            */}
      {/* {layoutProps.viewAdminDisplay && <AdminChangePassword />} */}

      {/* {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />} */}

      {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
    </>
  );
}
