import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import classNames from "classnames";

const SearchWalletTransactions = (props) => {
  const { register, reset } = useFormContext({reValidateMode: 'onChange'});
  const intl = useIntl();
  
  const statusFilterList = [
    {
      value: 0,
      label: intl.formatMessage({ id: "GLOBAL.STATUS.PENDING" })
    },
    {
      value: 2,
      label: intl.formatMessage({ id: "GLOBAL.STATUS.SUCCESS" })
    },
    {
      value: 3,
      label: intl.formatMessage({ id: "GLOBAL.STATUS.REJECTED" })
    }
  ];

  const transactionTypeList = [
    {
      value: 0,
      label: intl.formatMessage({ id: "wallet.transactionType.investment" }),
    },
    {
      value: 1,
      label: intl.formatMessage({ id: "wallet.transactionType.topup" }),
    },
    {
      value: 2,
      label: intl.formatMessage({ id: "wallet.transactionType.withdraw" }),
    },
    {
      value: 3,
      label: intl.formatMessage({ id: "wallet.transactionType.refunded" }),
    },
    {
      value: 4,
      label: intl.formatMessage({ id: "wallet.transactionType.campaignUnsuccessfull" }),
    },
    {
      value: 5,
      label: intl.formatMessage({ id: "wallet.transactionType.distributionAdded" }),
    },
  ];

  const walletTypeList = [
    {
      value: "CREDIT",
      label: intl.formatMessage({ id: "wallet.walletType.credit" }),
      
    },
    {
      value: "DEBIT",
      label: intl.formatMessage({ id: "wallet.walletType.debit" }),
    },
  ];

  const onClearSearchData = () => {
    props.clearSearchData();
    reset();
  }

  return (
    <>
      <Card>
        <CardBody>
          <Row className="justify-content-md-center">
            <Col md={12}>
              <Row className="form-group">
                <Col md={4}>
                  <Form.Control
                    name="transactionNumber"
                    placeholder={intl.formatMessage({ id: "wallet.search.transactionId" })}
                    className={classNames("form-control")}
                    {...register("transactionNumber")}
                  />
                </Col>
                {props?.showTransactionType && <Col md={4}>
                  <select 
                    placeholder={intl.formatMessage({ id: "wallet.search.transactionType" })}
                    className="form-control"
                    {...register("transactionType")}
                  >
                    <option defaultValue value="">
                      {intl.formatMessage({ id: "wallet.search.transactionType" })}
                    </option>
                    {transactionTypeList.map((option, key) => (
                      <option key={key} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </Col>}
              </Row>
              <Row className="form-group">
              {props?.showTransactionType &&<Col md={4}>
                  <select 
                    placeholder={intl.formatMessage({ id: "wallet.search.walletType" })}
                    className="form-control"
                    {...register("walletType")}
                  >
                    <option defaultValue  value="">
                      {intl.formatMessage({ id: "wallet.search.walletType" })}
                    </option>
                    {walletTypeList.map((option, key) => (
                      <option key={key} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </Col>}
                <Col md={4}>
                  <select 
                    placeholder={intl.formatMessage({ id: "GLOBAL.SEARCH.STATUS" })}
                    className="form-control"
                    {...register("status")}
                  >
                    <option defaultValue value="">
                      {intl.formatMessage({ id: "GLOBAL.SEARCH.STATUS" })}
                    </option>
                    {statusFilterList.map((option, key) => (
                      <option key={key} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </Col> 
                <Col md={1}>
                  <button className="btn btn-primary">
                    {intl.formatMessage({ id: "GLOBAL.SEARCH" })}
                  </button>
                </Col>
                <Col md={2}>
                  <button 
                    className="btn btn-secondary ml-5"
                    type="button"
                    onClick={() => onClearSearchData()}
                  >
                    {intl.formatMessage({ id: "GLOBAL.SEARCH.CLEAR" })}
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default SearchWalletTransactions;
