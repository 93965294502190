import * as actionTypes from "../../actions/newsletters/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  error: [],
  newsletters: [],
  newsletter: [],
};

export const newsletterStart = (state, action) => {
  return updateObject(state, {
    error: [],
  });
};

export const getNewsletters = (state, action) => {
  return updateObject(state, {
    newsletters: action.response,
  });
};

export const getNewsletter = (state, action) => {
  return updateObject(state, {
    transaction: action.response,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NEWSLETTER_START:
      return newsletterStart(state, action);
    case actionTypes.GET_NEWSLETTERS_SUCCESS:
      return getNewsletters(state, action);
    case actionTypes.GET_NEWSLETTER_SUCCESS:
      return getNewsletter(state, action);
    default:
      return state;
  }
};

export default reducer;
