import * as actionTypes from "./actionTypes";
export const countriesStart = () => {
  return {
    type: actionTypes.COUNTRIES_START,
  };
};

export const getCountries = () => {
  return {
    type: actionTypes.GET_COUNTRIES,
  };
};

export const getCountriesSuccess = (response) => {
  return {
    type: actionTypes.GET_COUNTRIES_SUCCESS,
    response,
  };
};

export const countriesFail = (error) => {
  return {
    type: actionTypes.COUNTRIES_FAIL,
    error,
  };
};

export const getLanguage = () => {
  return {
    type: actionTypes.GET_ENG_LANG,
  };
};

export const getLanguageSuccess = (response) => {
  return {
    type: actionTypes.GET_ENG_LANG_SUCCESS,
    response,
  };
};

export const createCountry = (countryData) => {
  return {
    type: actionTypes.CREATE_COUNTRY,
    countryData,
  };
};

export const createCountrySucces = (response) => {
  return {
    type: actionTypes.CREATE_COUNTRY_SUCCESS,
    response,
  };
};

export const getCountry = (id) => {
  return {
    type: actionTypes.GET_COUNTRY,
    id,
  };
};

export const getCountrySuccess = (response) => {
  return {
    type: actionTypes.GET_COUNTRY_SUCCESS,
    response,
  };
};

export const updateCountry = (countryData) => {
  return {
    type: actionTypes.UPDATE_COUNTRY,
    countryData,
  };
};

export const updateCountrySuccess = (response) => {
  return {
    type: actionTypes.UPDATE_COUNTRY_SUCCESS,
    response,
  };
};

export const deleteCountry = (id) => {
  return {
    type: actionTypes.DELETE_COUNTRY,
    id,
  };
};
