import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";
const initialState = {
  loading: false,
  error: false,
  errors: [],
  faqs: [],
  isCreated: false,
  isUpdated: false,
  faq: [],
  footerFaqs: [],
  footerFaq:[],
  investorContracts: [],
  investorContractsLoader: false,
  investorContract: null,
  investorContractLoader: false,
  updateInvestorContractSuccess: false,
  pages: [],
  page: [],
  helpCategories: [],
  helpCategory: [],
  helpCenters: [],
  helpCenter: [],
};

const contentPageStart = (state, action) => {
  return updateObject(state, {
    error: false,
    errors: null,
    loading: true,
    isCreated: false,
    isUpdated: false,
    faq: [],
    footerFaqs: [],
    footerFaq:[],
    page: [],
    helpCategory: [],
    helpCenter: [],
    investorContracts: [],
    investorContractsLoader: true,
    investorContract: null,
    investorContractLoader: true,
    updateInvestorContractSuccess: false,
  });
};

const contentPageFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errors: action.error,
    investorContractLoader: false,
    investorContractsLoader: false,
    updateInvestorContractSuccess: false,
  });
};

const getFaqs = (state, action) => {
  return updateObject(state, {
    faqs: action.response,
  });
};
const isCreated = (state, action) => {
  return updateObject(state, {
    isCreated: true,
  });
};
const getFaq = (state, action) => {
  return updateObject(state, {
    faq: action.response,
  });
};
const isUpdated = (state, action) => {
  return updateObject(state, {
    isUpdated: true,
  });
};
const getPages = (state, action) => {
  return updateObject(state, {
    pages: action.response,
    loading: false,
    page: [],
  });
};
const getPage = (state, action) => {
  return updateObject(state, {
    page: action.response,
  });
};

const getHelpCategories = (state, action) => {
  return updateObject(state, {
    helpCategories: action.response,
  });
};

const getHelpCategory = (state, action) => {
  return updateObject(state, {
    helpCategory: action.response,
  });
};

const getHelpCenters = (state, action) => {
  return updateObject(state, {
    helpCenters: action.response,
  });
};

const getHelpCenter = (state, action) => {
  return updateObject(state, {
    helpCenter: action.response,
  });
};

const getFooterFaqs = (state, action) => {
  return updateObject(state, {
    footerFaqs: action.response,
  });
};

const getFooterFaq = (state, action) => {
  return updateObject(state, {
    footerFaq: action.response,
  });
};

const getInvestorContractsSuccess = (state, action) => {
  return updateObject(state, {
    investorContracts: action.response,
    investorContractsLoader: false,
    updateInvestorContractSuccess: false,
  });
};

const getInvestorContractByIdSuccess = (state, action) => {
  return updateObject(state, {
    investorContract: action.response,
    investorContractLoader: false,
  });
};

const updateInvestorContractSuccess = (state, action) => {
  return updateObject(state, {
    updateInvestorContractSuccess: action.response,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CONTENT_PAGES_START:
      return contentPageStart(state, action);
    case actionTypes.CONTENT_PAGES_FAIL:
      return contentPageFail(state, action);
    case actionTypes.GET_FAQS_SUCCESS:
      return getFaqs(state, action);
    case actionTypes.GET_FOOTER_FAQS_SUCCESS:
      return getFooterFaqs(state, action);
    case actionTypes.CREATE_FAQ_SUCCESS:
    case actionTypes.CREATE_PAGE_SUCCESS:
    case actionTypes.CREATE_HELP_CATEGORY_SUCCESS:
    case actionTypes.CREATE_HELP_CENTER_SUCCESS:
      return isCreated(state, action);
    case actionTypes.GET_FAQ_SUCCESS:
      return getFaq(state, action);
    case actionTypes.GET_FOOTER_FAQ_SUCCESS:
      return getFooterFaq(state, action);
    case actionTypes.GET_INVESTOR_CONTRACTS_SUCCESS:
      return getInvestorContractsSuccess(state, action);
    case actionTypes.GET_INVESTOR_CONTRACT_BY_ID_SUCCESS:
      return getInvestorContractByIdSuccess(state, action);
    case actionTypes.UPDATE_INVESTOR_CONTRACT_SUCCESS:
      return updateInvestorContractSuccess(state, action);
    case actionTypes.UPDATE_FAQ_SUCCESS:
    case actionTypes.UPDATE_PAGE_SUCCESS:
    case actionTypes.UPDATE_HELP_CATEGORY_SUCCESS:
    case actionTypes.UPDATE_HELP_CENTER_SUCCESS:
      return isUpdated(state, action);
    case actionTypes.GET_PAGES_SUCCESS:
      return getPages(state, action);
    case actionTypes.GET_PAGE_SUCCESS:
      return getPage(state, action);
    case actionTypes.GET_HELP_CATEGORIES_SUCCESS:
      return getHelpCategories(state, action);
    case actionTypes.GET_HELP_CATEGORY_SUCCESS:
      return getHelpCategory(state, action);
    case actionTypes.GET_HELP_CENTERS_SUCCESS:
      return getHelpCenters(state, action);
    case actionTypes.GET_HELP_CENTER_SUCCCESS:
      return getHelpCenter(state, action);
    default:
      return state;
  }
};

export default reducer;
