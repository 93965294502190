import * as actionTypes from "./actionTypes";

export const activityStart = () => {
  return {
    type: actionTypes.ACTIVITY_START,
  };
};

export const activityFail = (error) => {
  return {
    type: actionTypes.ACTIVITY_FAIL,
  };
};

export const getActivities = (limit) => {
  return {
    type: actionTypes.GET_ACTIVITIES,
    limit
  };
};

export const getActivitiesSuccess = (response) => {
  return {
    type: actionTypes.GET_ACTIVITIES_SUCCESS,
    response,
  };
};
