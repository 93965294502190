export const EMAIL_SETTINGS_START = "[Email Settings] Email Start";
export const EMAIL_SETTINGS_FAIL = "[Email Settings] Email Fail";
export const GET_EMAIL_TEMPLATES = "[Email Settings] Get Email Templates";
export const GET_EMAIL_TEMPLATES_SUCCESS = "[Email Settings] Get Email Templates Success";
export const GET_EMAIL_TEMPLATE = "[Email Settings] Get Email Template";
export const DELETE_EMAIL_TEMPLATE = "[Email Settings] Delete Email Template";
export const GET_EMAIL_TEMPLATE_SUCCESS = "[Email Settings] Get Email Template Success";
export const CREATE_EMAIL_TEMPlATE = "[Email Settings] Create Email Template";
export const CREATE_EMAIL_TEMPlATE_SUCCESS = "[Email Settings] Create Email Template Success";
export const SEND_TEST_EMAIL = "SEND_TEST_EMAIL";
export const SEND_TEST_EMAIL_SUCCESS = "SEND_TEST_EMAIL_SUCCESS";
export const UPDATE_EMAIL_TEMPlATE = "[Email Settings] Update Email Template";
export const UPDATE_EMAIL_TEMPlATE_SUCCESS = "[Email Settings] Update Email Template Success";
export const UPDATE_MASTER_EMAIL = "UPDATE_MASTER_EMAIL";
export const UPDATE_MASTER_EMAIL_SUCCESS = "UPDATE_MASTER_EMAIL_SUCCESS";
export const BULK_UPDATE_EMAIL_TEMPlATE = "[Email Settings] Bulk Update Email Template";
export const BULK_UPDATE_EMAIL_TEMPlATE_SUCCESS = "[Email Settings] Bulk Update Email Template Success";
