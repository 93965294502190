/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect } from "react";
import { Tab, Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import {  toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getActivities } from "../../../../../store/actions";
import moment from "moment";
import LanguageReplaceText from "../../../../../_metronic/_helpers/activityHelper"

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function UserNotificationsDropdown() {
  const [key, setKey] = useState("Alerts");
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getActivities(1));
  }, [dispatch]);
  const { activities } = useSelector((state) => state.activity);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.notifications.layout") === "offcanvas",
    };
  }, [uiService]);

  const viewAll = () => {
    history.push(`/activities`);
  };

  const activity =
    activities !== []
      ? activities?.map((act, key) => (
          <div className="d-flex py-4 border-bottom" key={key}>
            <div className="symbol symbol-40 symbol-light-primary mr-5">
            </div>
            <div className="font-weight-bold">
              <span className="text-dark text-hover-primary mb-1 font-size-lg">
              <LanguageReplaceText
                  languageText={act?.languageText}
                  textToReplace={act?.textToReplace}
                  defaultText={act?.text}
                />
              </span>
              <span className="text-muted d-block">{moment(act.createdAt).fromNow()}</span>
            </div>
          </div>
        ))
      : null;

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary" id="kt_quick_notifications_toggle">
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Notifications1.svg")} />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle as={DropdownTopbarItemToggler} id="kt_quick_notifications_toggle">
            <div className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary" id="kt_quick_notifications_toggle">
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Notifications1.svg")} />
              </span>
              <span className="pulse-ring"></span>
              <span className="pulse-ring" />
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              {/** Head */}
              <div className="d-flex flex-column pt-8 bgi-size-cover bgi-no-repeat rounded-top" style={{ backgroundImage: `url(${bgImage})` }}>
                <h4 className="d-flex pb-5 pl-10 rounded-top align-items-center">
                  <span className="text-white">Recent Activities</span>
                  <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">{activities?.length} new</span>
                </h4>

                <Tab.Container defaultActiveKey={key}>
                  <Tab.Content className="tab-content">
                    <Tab.Pane eventKey="Alerts" className="p-8">
                      <PerfectScrollbar options={perfectScrollbarOptions} className="scroll pr-7 mr-n7" style={{ maxHeight: "300px", position: "relative" }}>
                        {activity}
                      </PerfectScrollbar>
                      <div className="mt-8 text-center">
                        <button type="button" onClick={viewAll} className="btn btn-outline-primary font-weight-bolder">
                          View All Activity
                        </button>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
