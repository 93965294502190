import * as actionTypes from "../../actions/apiManagement/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  recaptcha: [],
  error: null,
  isCreated: false,
  publicRecaptcha: [],
};

const getRecaptcha = (state, action) => {
  return updateObject(state, {
    recaptcha: action.response,
    isCreated: false,
  });
};

const recaptchaCreated = (state, action) => {
  return updateObject(state, {
    isCreated: true,
  });
};

export const getPublicRecaptcha = (state, action) => {
  return updateObject(state, {
    publicRecaptcha: action.response,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_RECAPTCHA_SUCCESS:
      return getRecaptcha(state, action);
    case actionTypes.RECAPTCHA_SUCCESS:
      return recaptchaCreated(state, action);
    case actionTypes.GET_PUBLIC_RECAPTCHA_SUCCESS:
      return getPublicRecaptcha(state, action);
    default:
      return state;
  }
};

export default reducer;
