import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../../helpers/numberFormat";

const TotalTransaction = (props) => {
  const { generalSettings } = useSelector((state) => state.settings);
  const totalTransViewdata =
    props.totalAmountWiseDetail != undefined &&
    props.totalAmountWiseDetail.length > 0
      ? props.totalAmountWiseDetail?.map((transaction, key) => (
          <Col key={key}>
            <Row>
              <Col className="m-2">
                <b>{transaction.code}</b>
              </Col>
            </Row>
            <Row>
              <Col className="m-2">
                {formatCurrency(
                  transaction.totalAmount ?? 0,
                  generalSettings.currencySymbolSide,
                  transaction.symbol,
                  transaction.code,
                  generalSettings.decimalPoints
                )}
              </Col>
            </Row>
            <Row>
              <Col className="m-2">
                {formatCurrency(
                  transaction.totalearnedFee ?? 0,
                  generalSettings.currencySymbolSide,
                  transaction.symbol,
                  transaction.code,
                  generalSettings.decimalPoints
                )}
              </Col>
            </Row>
          </Col>
        ))
      : null;
  const totalTransView =
    props.totalAmountWiseDetail != undefined &&
    props.totalAmountWiseDetail.length > 0 ? (
      <Col>
        <Row>
          <Col className="m-2">&nbsp;</Col>
        </Row>
        <Row>
          <Col className="m-2">
            <b>Total Amount</b>
          </Col>
        </Row>
        <Row>
          <Col className="m-2">
            <b>Total Earned Amount</b>
          </Col>
        </Row>
      </Col>
    ) : null;
  // const { register } = useFormContext();
  return (
    <>
      <Card>
        <CardBody>
          <Row>
            {totalTransView}
            {totalTransViewdata}
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default TotalTransaction;
