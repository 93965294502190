import { put, delay } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import {
  LOGIN_API,
  LOGIN_USER,
  LOGIN_ADMIN_FRONTEND,
} from "../actions/apiCollections";
import { authFrontEndSuccess } from "../actions/auth";
import {
  logoutSucceed,
  logout,
  authStart,
  authSuccess,
  authFail,
  checkAuthTimeout,
} from "../actions/index";

export function* logoutSaga(action) {
  yield localStorage.removeItem("adminToken");
  yield localStorage.removeItem("expirationDate");
  yield localStorage.removeItem("email");
  yield localStorage.removeItem("id");
  yield localStorage.removeItem("role");
  yield localStorage.setItem("persist:root", {});
  yield put(logoutSucceed());
}

export function* checkAuthTimeoutSaga(action) {
  yield delay(action.expirationTime * 1000);
  yield put(logout());
}

export function* authLoginSaga(action) {
  yield put(authStart());
  const authData = {
    email: action.email,
    password: action.password,
    createdAt: Date.now()
  };
  try {
    const response = yield axios.post(LOGIN_API, authData);
    const expirationTokenTime = 3600;
    const expirationDate = yield new Date(
      new Date().getTime() + expirationTokenTime * 1000
    );
    yield localStorage.setItem("adminToken", response.data.token);
    yield localStorage.setItem("expirationDate", expirationDate);
    yield localStorage.setItem("email", authData.email);
    yield localStorage.setItem("id", response.data.data.admin.id);
    yield localStorage.setItem("role", response.data.data.admin.role);
    yield put(authSuccess(response.data.token));
    yield put(checkAuthTimeout(expirationTokenTime));
  } catch (error) {
    yield put(authFail(error.response.data.message));
  }
}

export function* authCheckStateSaga(action) {
  const token = yield localStorage.getItem("adminToken");
  if (!token) {
    yield put(logout());
  } else {
    const expirationDate = yield new Date(
      localStorage.getItem("expirationDate")
    );
    if (expirationDate <= new Date()) {
      yield put(logout());
    } else {
      yield put(authSuccess(token));
      yield put(
        checkAuthTimeout(
          (expirationDate.getTime() - new Date().getTime()) / 1000
        )
      );
    }
  }
}

export function* loginAsUserSaga(action) {
  yield put(authStart());
  const { email } = action;
  try {
    const response = yield axios.get(LOGIN_USER + email);
    const expirationTokenTime = 3600;
    const expirationDate = yield new Date(
      new Date().getTime() + expirationTokenTime * 1000
    );
    yield put(
      authFrontEndSuccess({
        token: response.data.token,
        data: response.data.data,
        expirationDate: new Date(expirationDate),
      })
    );
    yield delay(1000);
    yield put(authStart());
  } catch (error) {
    yield put(authFail(error.response.data.message));
  }
}

export function* loginAsAdminUserSaga() {
  yield put(authStart());
  try {
    const response = yield axios.get(LOGIN_ADMIN_FRONTEND);
    const expirationTokenTime = 3600;
    const expirationDate = yield new Date(
      new Date().getTime() + expirationTokenTime * 1000
    );
    yield put(
      authFrontEndSuccess({
        token: response.data.token,
        data: response.data.data,
        expirationDate: new Date(expirationDate),
      })
    );
    yield delay(1000);
    yield put(authStart());
  } catch (error) {
    yield put(authFail(error.response.data.message));
  }
}
