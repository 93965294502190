import { put } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import { languageSelectorFail, getLanguageSuccess } from "../../actions/languageSelector/languageSelector";
import { LANGUAGE_SELECTOR_API } from "../../actions/apiCollections";

export function* getLanguage() {
  try {
    const response = yield axios.get(LANGUAGE_SELECTOR_API);
    yield put(getLanguageSuccess(response.data.data.data));
  } catch (error) {
    yield put(languageSelectorFail(error.response.data.data));
  }
}
