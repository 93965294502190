export const showCampaignRepayment = (termsSlug, status, investingType) => {
  if (termsSlug === "debt" && status === 4 && investingType === "flexible") {
    return 1;
  } else if (termsSlug === "debt" && status === 5 && investingType === "flexible") {
    return 1;
  } else if (termsSlug === "debt" && status === 4 && investingType === "fixed") {
    return 1;
  } else if (termsSlug === "debt" && status === 5 && investingType === "fixed") {
    return 0;
  } else if (termsSlug === "equity" && status === 4 && investingType === "flexible") {
    return 0;
  } else if (termsSlug === "equity" && status === 5 && investingType === "flexible") {
    return 0;
  } else if (termsSlug === "equity" && status === 4 && investingType === "fixed") {
    return 0;
  } else if (termsSlug === "equity" && status === 5 && investingType === "fixed") {
    return 0;
  } else {
    return 0;
  }
};
