/* eslint-disable no-unused-vars */
import { call, put } from "redux-saga/effects";
import {
  MANAGE_OPTIONS_COMPANY_INDUSTRY_GET_API,
  MANAGE_OPTIONS_COMPANY_INDUSTRY_CREATE_API,
  MANAGE_OPTIONS_COMPANY_INDUSTRY_DELETE_API,
  MANAGE_OPTIONS_COMPANY_INDUSTRY_UPDATE_API,
  MANAGE_OPTIONS_INVESTING_SOURCE_LIST_API,
  MANAGE_OPTIONS_INVESTING_TYPE_API,
  MANAGE_OPTIONS_DOCUMENT_TYPE_API,
  MANAGE_OPTIONS_ACCOUNT_TYPE_API,
  GET_LANGUAGES,
  MANAGE_OPTIONS_TEAM_MEMBER_TYPE_API,
  MANAGE_OPTIONS_INVESTOR_TYPE_API,
  MANAGE_OPTIONS_CAMPAIGN_STAGE_API,
  MANAGE_OPTIONS_INVESTING_ROUND_API,
  MANAGE_OPTIONS_DEAL_TYPE,
  INVESTOR_TAX_RELIEF
} from "../actions/apiCollections";
import axios from "../../config/AxiosConfig";
import {
  manageOptionsFail,
  companyIndustrySuccess,
  getCompanyIndustryByIdSuccess,
  getAllInvestingSourceListSuccess,
  getInvestingSourceByIdSuccess,
  getAllInvestingTypeSuccess,
  getInvestingTypeSuccess,
  getAllDocumentTypeSuccess,
  getDocumentTypeSuccess,
  getAllAccountTypeSuccess,
  getManageOptionsCurrentId,
  getManageLanguageSuccess,
  getAccountTypeByIdSuccess,
  getAllTeamMemberTypeSuccess,
  getTeamMemberTypeSuccess,
  getAllInvestorTypeSuccess,
  getInvestorTypeSuccess,
  getCampaignStageListSuccess,
  getCampaignStageSuccess,
  getInvestingRoundListSuccess,
  getInvestingRoundSuccess,
  getDealTypesSuccess,
  manageOptionsStart,
  createDealTypeSuccess,
  getDealTypeSuccess,
  updateDealTypeSuccess,
  getInvestorTaxListSuccess,
  getInvestorTaxSuccess,
  updateInvestorTaxSuccess,
  createInvestorTaxSuccess,
} from "../actions/index";

export function* getAllCompanyIndustrySaga(action) {
  yield put(manageOptionsStart());
  try {
    const lang = action.language ? `&language=${action.language}` : "";
    const response = yield axios.get(`${MANAGE_OPTIONS_COMPANY_INDUSTRY_GET_API}?sort=name${lang}`);
    yield put(companyIndustrySuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}
export function* getDealTypes(action) {
  yield put(manageOptionsStart());
  try {
    const lang = action.language ? `&language=${action.language}` : "";
    const response = yield axios.get(`${MANAGE_OPTIONS_DEAL_TYPE}?sort=name`);
    yield put(getDealTypesSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* getAllTeamMemberTypeSaga(action) {
  yield put(manageOptionsStart());
  try {
    const lang = action.language ? `&language=${action.language}` : "";
    const response = yield axios.get(`${MANAGE_OPTIONS_TEAM_MEMBER_TYPE_API}?sort=name${lang}`);
    yield put(getAllTeamMemberTypeSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* getInvestingRoundListSaga(action) {
  try {
    const lang = action.language ? `&language=${action.language}` : "";
    const response = yield axios.get(`${MANAGE_OPTIONS_INVESTING_ROUND_API}?sort=name${lang}`);
    yield put(getInvestingRoundListSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* getInvestorTaxList() {
  yield put(manageOptionsStart());
  try {
    const response = yield axios.get(INVESTOR_TAX_RELIEF);
    yield put(getInvestorTaxListSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* createInvestingRoundSaga(action) {
  try {
    const response = yield axios.post(`${MANAGE_OPTIONS_INVESTING_ROUND_API}?lng=en`, action.investing);
    yield call(getInvestingRoundListSaga, action.investing);
  } catch (error) {
    yield put(manageOptionsFail(error.response.error.data));
  }
}

export function* createDealType(action) {
  try {
    const response = yield axios.post(`${MANAGE_OPTIONS_DEAL_TYPE}?lng=en`, action.deal);
    yield put(createDealTypeSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.error.data));
  }
}
export function* deleteDealType(action) {
  try {
    yield axios.delete(`${MANAGE_OPTIONS_DEAL_TYPE}/${action.id}`);
    yield call(getDealTypes, action);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}
export function* deleteInvestorTax(action) {
  try {
    yield axios.delete(`${INVESTOR_TAX_RELIEF}/${action.id}`);
    yield call(getInvestorTaxList);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* getInvestorTax(action) {
  try {
    const response = yield axios.get(`${INVESTOR_TAX_RELIEF}/${action.id}`);
    yield put(getInvestorTaxSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}
export function* createInvestorTax(action) {
  try {
    const response = yield axios.post(INVESTOR_TAX_RELIEF, action.tax);
    yield put(createInvestorTaxSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}
export function* deleteInvestingRoundSaga(action) {
  try {
    const response = yield axios.delete(`${MANAGE_OPTIONS_INVESTING_ROUND_API}${action.id}`);
    yield call(getInvestingRoundListSaga, action);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}
export function* getInvestingRoundSaga(action) {
  try {
    const response = yield axios.get(`${MANAGE_OPTIONS_INVESTING_ROUND_API}${action.id}`);
    yield put(getInvestingRoundSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* updateInvestingRoundSaga(action) {
  try {
    const response = yield axios.patch(`${MANAGE_OPTIONS_INVESTING_ROUND_API}${action.investing.id}`, action.investing);
    yield call(getInvestingRoundListSaga, action.investing);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* updateInvestorTaxRelief(action) {
  try {
    const response = yield axios.patch(`${INVESTOR_TAX_RELIEF}/${action.tax.id}`, action.tax);
    yield put(updateInvestorTaxSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}
export function* getAllInvestorTypeSaga(action) {
  yield put(manageOptionsStart());
  try {
    const lang = action.language ? `&language=${action.language}` : "";
    const response = yield axios.get(`${MANAGE_OPTIONS_INVESTOR_TYPE_API}?sort=name${lang}`);
    yield put(getAllInvestorTypeSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* getAllCampaignStageSaga(action) {
  yield put(manageOptionsStart());

  try {
    const lang = action.language ? `&language=${action.language}` : "";
    const response = yield axios.get(`${MANAGE_OPTIONS_CAMPAIGN_STAGE_API}?sort=name${lang}`);
    yield put(getCampaignStageListSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* createCampaignStageSaga(action) {
  try {
    const response = yield axios.post(`${MANAGE_OPTIONS_CAMPAIGN_STAGE_API}`, action.campaign);
    yield call(getAllCampaignStageSaga, action.campaign);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* updateInvestorTypeSaga(action) {
  try {
    const response = yield axios.patch(`${MANAGE_OPTIONS_INVESTOR_TYPE_API}${action.investor.id}`, action.investor);
    yield call(getAllInvestorTypeSaga, action.investor);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* createInvestorTypeSaga(action) {
  try {
    const response = yield axios.post(`${MANAGE_OPTIONS_INVESTOR_TYPE_API}`, action.investor);
    yield call(getAllInvestorTypeSaga, action.investor);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* deleteInvestorTypeSaga(action) {
  try {
    const response = yield axios.delete(`${MANAGE_OPTIONS_INVESTOR_TYPE_API}${action.id}`);
    yield call(getAllInvestorTypeSaga, action);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* deleteCampaignStageSaga(action) {
  try {
    const response = yield axios.delete(`${MANAGE_OPTIONS_CAMPAIGN_STAGE_API}${action.id}`);
    yield call(getAllCampaignStageSaga, action);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* getInvestorTypeSaga(action) {
  try {
    const response = yield axios.get(`${MANAGE_OPTIONS_INVESTOR_TYPE_API}${action.id}`);
    yield put(getInvestorTypeSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* getDocumentListSaga(action) {
  yield put(manageOptionsStart());

  try {
    const lang = action.language ? `&language=${action.language}` : "";
    const response = yield axios.get(`${MANAGE_OPTIONS_DOCUMENT_TYPE_API}?sort=name${lang}`);
    yield put(getAllDocumentTypeSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* getInvestingTypeSaga(action) {
  yield put(manageOptionsStart());

  try {
    const lang = action.language ? `&language=${action.language}` : "";
    const response = yield axios.get(`${MANAGE_OPTIONS_INVESTING_TYPE_API}${action.id}`);
    yield put(getInvestingTypeSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* getAllInvestingTypeSaga(action) {
  yield put(manageOptionsStart());

  try {
    const lang = action.language ? `?language=${action.language}` : "";
    const response = yield axios.get(`${MANAGE_OPTIONS_INVESTING_TYPE_API}${lang}`);
    yield put(getAllInvestingTypeSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* getAllInvestingSourceListSaga(action) {
  yield put(manageOptionsStart());

  try {
    const lang = action.language ? `&language=${action.language}` : "";
    const response = yield axios.get(`${MANAGE_OPTIONS_INVESTING_SOURCE_LIST_API}?sort=name${lang}`);
    yield put(getAllInvestingSourceListSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* getAllAccountTypeSaga(action) {
  yield put(manageOptionsStart());
  try {
    const lang = action.language ? `&language=${action.language}` : "";
    const response = yield axios.get(`${MANAGE_OPTIONS_ACCOUNT_TYPE_API}?sort=name${lang}`);
    yield put(getAllAccountTypeSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* getAccountTypeByIdSaga(action) {
  try {
    const response = yield axios.get(`${MANAGE_OPTIONS_ACCOUNT_TYPE_API}${action.id}`);
    yield put(getAccountTypeByIdSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* getDealType(action) {
  try {
    const response = yield axios.get(`${MANAGE_OPTIONS_DEAL_TYPE}/${action.id}`);
    yield put(getDealTypeSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* getCampaignSaga(action) {
  try {
    const response = yield axios.get(`${MANAGE_OPTIONS_CAMPAIGN_STAGE_API}${action.id}`);
    yield put(getCampaignStageSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* getTeamMemberTypeSaga(action) {
  try {
    const response = yield axios.get(`${MANAGE_OPTIONS_TEAM_MEMBER_TYPE_API}${action.id}`);
    yield put(getTeamMemberTypeSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* deleteCompanyIndustrySaga(action) {
  try {
    const response = yield axios.delete(`${MANAGE_OPTIONS_COMPANY_INDUSTRY_DELETE_API}${action.id}`);
    yield call(getAllCompanyIndustrySaga, action);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* updateCompanyIndustrySaga(action) {
  try {
    const response = yield axios.patch(`${MANAGE_OPTIONS_COMPANY_INDUSTRY_UPDATE_API}${action.companyData.id}`, action.companyData);
    yield put(getCompanyIndustryByIdSuccess(response.data.data.data));
    yield call(getAllCompanyIndustrySaga, action.companyData);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* updateDealType(action) {
  let id = new FormData();
  id = action.deal;
  id = id.get("id");
  try {
    const response = yield axios.patch(`${MANAGE_OPTIONS_DEAL_TYPE}/${id}`, action.deal);
    yield put(updateDealTypeSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* updateCampaignStageSaga(action) {
  try {
    const response = yield axios.patch(`${MANAGE_OPTIONS_CAMPAIGN_STAGE_API}${action.campaign.id}`, action.campaign);
    yield put(getCampaignStageSuccess(response.data.data.data));
    yield call(getAllCampaignStageSaga, action.campaign);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* updateTeamMemberType(action) {
  try {
    const response = yield axios.patch(`${MANAGE_OPTIONS_COMPANY_INDUSTRY_UPDATE_API}${action.teamMember.id}`, action.teamMember);
    yield put(getTeamMemberTypeSuccess(response.data.data.data));
    yield call(getAllTeamMemberTypeSaga, action.teamMember);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* updateAccountTypeSaga(action) {
  try {
    const response = yield axios.patch(`${MANAGE_OPTIONS_ACCOUNT_TYPE_API}${action.accountData.id}`, action.accountData);
    yield put(getCompanyIndustryByIdSuccess(response.data.data.data));
    yield call(getAllAccountTypeSaga, action.accountData);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* createCompanyIndustrySaga(action) {
  try {
    const response = yield axios.post(`${MANAGE_OPTIONS_COMPANY_INDUSTRY_CREATE_API}?lng=en`, action.companyData);
    yield call(getAllCompanyIndustrySaga, action.companyData);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}
export function* createTeamMemberTypeSaga(action) {
  try {
    const response = yield axios.post(`${MANAGE_OPTIONS_TEAM_MEMBER_TYPE_API}`, action.teamMember);
    yield call(getAllTeamMemberTypeSaga, action.teamMember);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* getCompanyIndustryByIdSaga(action) {
  try {
    const response = yield axios.get(`${MANAGE_OPTIONS_COMPANY_INDUSTRY_GET_API}${action.id}`);
    yield put(getCompanyIndustryByIdSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* deleteInvestingSourceSaga(action) {
  try {
    const response = yield axios.delete(`${MANAGE_OPTIONS_INVESTING_SOURCE_LIST_API}${action.id}`);
    yield call(getAllInvestingSourceListSaga, action);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* deleteTeamMemberSaga(action) {
  try {
    const response = yield axios.delete(`${MANAGE_OPTIONS_TEAM_MEMBER_TYPE_API}${action.id}`);
    yield call(getAllTeamMemberTypeSaga, action);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}
export function* createInvestingSourceSaga(action) {
  try {
    const response = yield axios.post(`${MANAGE_OPTIONS_INVESTING_SOURCE_LIST_API}?lng=en&sort=name`, action.investingData);
    yield call(getAllInvestingSourceListSaga, action.investingData);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* getInvestingSourceByIdSaga(action) {
  try {
    const response = yield axios.get(`${MANAGE_OPTIONS_INVESTING_SOURCE_LIST_API}${action.id}`);
    yield put(getInvestingSourceByIdSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* updateInvestingSourceSaga(action) {
  try {
    const response = yield axios.patch(`${MANAGE_OPTIONS_INVESTING_SOURCE_LIST_API}${action.investingData.id}?lng=id`, action.investingData);
    yield put(getInvestingSourceByIdSuccess(response.data.data.data));
    yield call(getAllInvestingSourceListSaga, action.investingData);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* deleteInvestingTypeSaga(action) {
  try {
    const response = yield axios.delete(`${MANAGE_OPTIONS_INVESTING_TYPE_API}${action.id}`);
    yield call(getAllInvestingTypeSaga, action);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* createInvestingTypeSaga(action) {
  try {
    const response = yield axios.post(`${MANAGE_OPTIONS_INVESTING_TYPE_API}`, action.investingTypeData);
    yield call(getAllInvestingTypeSaga, action.investingTypeData);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* updateInvestingTypeSaga(action) {
  try {
    const response = yield axios.patch(`${MANAGE_OPTIONS_INVESTING_TYPE_API}${action.investingData.id}?lng=en`, action.investingData);
    yield call(getAllInvestingTypeSaga, action.investingData);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* deleteDocumentSaga(action) {
  try {
    const response = yield axios.delete(`${MANAGE_OPTIONS_DOCUMENT_TYPE_API}${action.id}`);
    yield call(getDocumentListSaga, action);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* createDocumentTypeSaga(action) {
  try {
    const response = yield axios.post(MANAGE_OPTIONS_DOCUMENT_TYPE_API, action.documentData);
    yield call(getDocumentListSaga, action.documentData);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* getDocumentTypeSaga(action) {
  try {
    const response = yield axios.get(`${MANAGE_OPTIONS_DOCUMENT_TYPE_API}${action.id}`);
    yield put(getDocumentTypeSuccess(response.data.data.data));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* updateDocumentTypeSaga(action) {
  try {
    const response = yield axios.patch(`${MANAGE_OPTIONS_DOCUMENT_TYPE_API}${action.documentData.id}`, action.documentData);
    yield call(getDocumentListSaga, action.documentData);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* deleteAccountTypeSaga(action) {
  try {
    const response = yield axios.delete(`${MANAGE_OPTIONS_ACCOUNT_TYPE_API}${action.id}`);
    yield call(getAllAccountTypeSaga, action);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* createAccountTypeSaga(action) {
  try {
    const response = yield axios.post(MANAGE_OPTIONS_ACCOUNT_TYPE_API, action.accountData);
    yield call(getAllAccountTypeSaga, action.accountData);
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* getManageOptionsLanguagesSaga() {
  try {
    const response = yield axios.get(`${GET_LANGUAGES}?fields=id,isDefault,name,status&sort=name`);
    yield put(getManageLanguageSuccess(response.data.data.data));
    yield put(getManageOptionsCurrentId(response.data.data.data[0].id));
  } catch (error) {
    yield put(manageOptionsFail(error.response.data.errors));
  }
}

export function* setManageOptionsCurrentIdSaga(action) {
  try {
    yield put(getManageOptionsCurrentId(action.languageId));
  } catch (error) {
    console.log("default language set Error");
  }
}