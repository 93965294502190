import * as actionTypes from "./actionTypes";

export const frontEndUsersStart = () => {
  return {
    type: actionTypes.FRONT_END_USERS_START,
  };
};

export const getFrontEndUsers = (search, pageLimit) => {
  return {
    type: actionTypes.GET_ALL_FRONT_END_USERS,
    search,
    pageLimit,
  };
};

export const getFrontEndUsersSuccess = (response) => {
  return {
    type: actionTypes.GET_ALL_FRONT_END_USERS_SUCCESS,
    response,
  };
};

/**
 *
 * @param {String} id `String`
 */
export const deleteFrontEndUser = (id) => {
  return {
    type: actionTypes.DELETE_FRONT_END_USER,
    id,
  };
};

export const createFrontEndUser = (frontEndUser) => {
  return {
    type: actionTypes.CREATE_FRONT_END_USER,
    frontEndUser,
  };
};

export const createFrontEndUserSuccess = (response) => {
  return {
    type: actionTypes.CREATE_FRONT_END_USER_SUCCESS,
    response,
  };
};

export const getFrontEndUser = (id) => {
  return {
    type: actionTypes.GET_FRONT_END_USER,
    id,
  };
};

export const getFrontEndUserSuccess = (response) => {
  return {
    type: actionTypes.GET_FRONT_END_USER_SUCCESS,
    response,
  };
};

export const updateFrontEndUser = (frontEndUser) => {
  return {
    type: actionTypes.UPDATE_FRONT_END_USER,
    frontEndUser,
  };
};

export const updateFrontEndUserSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_FRONT_END_USER_SUCCESS,
    response,
  };
};

export const frontEndUsersFail = (error) => {
  return {
    type: actionTypes.FRONT_END_USERS_FAIL,
    error,
  };
};

export const userTypes = filterData => {
	return {
		type: actionTypes.USER_TYPE,
		filterData: filterData
	};
};

export const userTypesSuccess = typesData => {
	return {
		type: actionTypes.USER_TYPE_SUCCESS,
		typesData: typesData
	};
};

// BATCH START

export const batchFrontEndUpdate = (frontEndData) => {
  return {
    type: actionTypes.BULK_UPDATE_FRONT_END_USER,
    frontEndData,
  };
};

export const batchFrontEndUpdateSuccess = (response) => {
  return {
    type: actionTypes.BULK_UPDATE_FRONT_END_USER_SUCCESS,
    response,
  };
};

export const batchDelteFrontEndUser = (frontEndData) => {
  return {
    type: actionTypes.BULK_DELETE_FRONT_END_USER,
    frontEndData,
  };
};
