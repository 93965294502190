import * as actionTypes from "./actionTypes";

export const cronJobStart = () => {
  return {
    type: actionTypes.CRON_START,
  };
};

export const cronJobFail = (error) => {
  return {
    type: actionTypes.CRON_FAIL,
    error,
  };
};

export const getCronJobs = () => {
  return {
    type: actionTypes.GET_CRON_JOBS,
  };
};

export const getCronJobsSuccess = (response) => {
  return {
    type: actionTypes.GET_CRON_JOBS_SUCCESS,
    response,
  };
};

export const createCronJob = () => {
  return {
    type: actionTypes.CREATE_CRON_JOB,
    createdAt: new Date(Date.now()).toISOString(),
  };
};

export const createCronJobSuccess = (response) => {
  return {
    type: actionTypes.CREATE_CRON_JOB_SUCCESS,
    response,
  };
};
