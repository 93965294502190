import * as actionTypes from "./actionTypes";

/**
 * A Common action to tell redux-store that we are starting
 */
export const categoriesCommonStart = () => {
  return {
    type: actionTypes.CATEGORIES_COMMON_START,
  };
};

/**
 * A Common action to tell redux-store that have recieved an error
 * @param {Object} error : `Object`
 */
export const commonCategoriesFail = (error) => {
  return {
    type: actionTypes.CATEGORIES_COMMON_FAIL,
    error,
  };
};

/**
 * We get a `200` response from the Saga API call for all the categories,
 * and then store it in the redux-store.
 * We do use `key : value` format in the return `Object` since it is not needed
 * Use `key : value` format when you want change the `key`
 * @param {response} response : `JSON`
 * @returns {Object} `Object`
 */
export const getAllCategorySuccess = (response) => {
  return {
    type: actionTypes.GET_CATEGORIES_SUCCESS,
    response,
  };
};

/**
 * This `action` is dispatched from the `Component` using
 * `useDispatch` hook, it's response is stored in either
 * `getAllCategorySuccess` or `commonCategoriesFail`
 * @param {String} language `String`
 */
export const getAllCategories = (language, pageLimit, search) => {
  return {
    type: actionTypes.GET_CATEGORIES,
    language,
    pageLimit,
    search
  };
};

/**
 * We get a `200` response from the Saga API call for a single Category is created,
 * and then store it in the redux-store.
 * We do use `key : value` format in the return `Object` since it is not needed
 * Use `key : value` format when you want change the `key`
 * @param {response} response : `JSON`
 * @returns {Object} `Object`
 */
export const createCategorySuccess = (response) => {
  return {
    type: actionTypes.CREATE_CATEGORY_SUCCESS,
    response,
  };
};

/**
 * This is the actual `action` which is being dispatched/called from the
 * `Component`. Since we are sending documents (images) we need to send the data
 * in `FormData`. Handling `FormData` is tricky as compared to `JSON` (in the `Component`)
 * @param {FormData} categoryData : `FormData`
 * @returns {Object} `Object`
 */
export const createCategory = (categoryData) => {
  return {
    type: actionTypes.CREATE_CATEGORY,
    categoryData,
  };
};

/**
 * We get a `200` response from the Saga API call when we get a single Category by it's ID,
 * and then store it in the redux-store.
 * We do use `key : value` format in the return `Object` since it is not needed
 * Use `key : value` format when you want change the `key`
 * @param {JSON} response
 * @returns {Object} `Object`
 */
export const getCategorySuccess = (response) => {
  return {
    type: actionTypes.GET_CATEGORY_SUCCESS,
    response,
  };
};

/**
 * This is the actual `action` which is being dispatched/called from the
 * `Component`. We need this to render the `Component` when it is in the edit mode.
 * It sends an `Object` which consists the actionType and the `id` itself.
 * @param {String} id : `String`
 * @returns {Object} `Object`
 */
export const getCategory = (id) => {
  return {
    type: actionTypes.GET_CATEGORY,
    id,
  };
};

/**
 * This is the actual `action` which is being dispatched/called from the
 * `Component`. Since we are sending documents (images) we need to send the data
 * in `FormData`. Handling `FormData` is tricky as compared to `JSON` (in the `Component`).
 *
 * Only fields or files which are modified are sent as `FormData`
 * @param {FormData} categoryData : `FormData`
 * @returns {Object} `Object`
 */
export const updateCategory = (categoryData) => {
  return {
    type: actionTypes.UPDATE_CATEGORY,
    categoryData,
  };
};

/**
 * This is the actual `action` which is being dispatched/called from the
 * `Component`.
 *
 * If the `id` exists for the category, then that category will be deleted.
 * @param {String} id : `String`
 * @returns {Object} `Object`
 */
export const deleteCategory = (id, language, search) => {
  return {
    type: actionTypes.DELETE_CATEGORY,
    id,
    language,
    search

  };
};

export const getLanguages = () => {
  return {
    type: actionTypes.GET_LANGUAGES,
  };
};

export const getLanguagesSuccess = (response) => {
  return {
    type: actionTypes.GET_LANGUAGES_SUCCESS,
    response,
  };
};

export const getCategoryLanguageCurrentId = (response) => {
  return {
    type: actionTypes.GET_LANGUAGE,
    response,
  };
};

/**
 *
 * @param {object}} obj
 * @returns {object}
 */
export const bulkUpdateCategories = (obj) => {
  return {
    type: actionTypes.BULK_UPDATE_CATEGORIES,
    obj,
  };
};

export const bulkUpdateCategoriesSuccess = (response) => {
  return {
    type: actionTypes.BULK_UPDATE_CATEGORIES_SUCCESS,
    response,
  };
};

export const bulkDeleteCategories = (obj, language) => {
  return {
    type: actionTypes.BULK_DELETE_CATEGORIES,
    obj,
  };
};
