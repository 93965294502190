import * as actionTypes from "../../actions/transactions/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  error: [],
  transactions: [],
  transaction: [],
  activeCamapigns: [],
  investors: [],
  successAlert: true,
  isSpinning: false
};

export const transactionStart = (state, action) => {
  return updateObject(state, {
    error: [],
    isSpinning: true
  });
};

export const getTransactions = (state, action) => {
  return updateObject(state, {
    transactions: action.response,
    successAlert: false,
    activeCamapigns: [],
    investors: [],
  });
};

export const getTransaction = (state, action) => {
  return updateObject(state, {
    transaction: action.response,
  });
};

export const getActiveCamapignList = (state, action) => {
  return updateObject(state, {
    activeCamapigns: action.response,
    isSpinning: false
  });
};

export const getInvestorList = (state, action) => {
  return updateObject(state, {
    investors: action.response,
    isSpinning: false
  });
};

export const addTransaction = (state, action) => {
  return updateObject(state, {
    successAlert: true,
    isSpinning: false
  });
};

export const transactionFail = (state, action) => {
  console.log(action);
  return updateObject(state, {
    error: action.error,
    successAlert: false,
    isSpinning: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TRANSACTION_START:
      return transactionStart(state, action);
    case actionTypes.GET_TRANSACTIONS_SUCCESS:
      return getTransactions(state, action);
    case actionTypes.GET_TRANSACTION_SUCCESS:
      return getTransaction(state, action);
    case actionTypes.GET_ACTIVE_CAMPAIGN_LIST_SUCCESS:
      return getActiveCamapignList(state, action);
    case actionTypes.GET_INVESTOR_LIST_SUCCESS:
      return getInvestorList(state, action);
    case actionTypes.ADD_TRANSACTION_SUCCESS:
      return addTransaction(state, action);
    case actionTypes.TRANSACTION_FAIL:
      return transactionFail(state, action);
    default:
      return state;
  }
};

export default reducer;
