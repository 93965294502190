import { call, put } from "redux-saga/effects";
import { getSpamSettingSuccess, spamStart, spamFail, spamCreateSuccess, spamUpdateSuccess, getSpammersSuccess, createSpammerSuccess, getSpammerSuccess } from "../../actions/spamSettings/spamSettingsActions";
import { SPAM_SETTING_API, SPAMMERS_API } from "../../actions/apiCollections";
import axios from "../../../config/AxiosConfig";

export function* getSpamSettingSaga() {
  yield put(spamStart());
  try {
    const response = yield axios.get(SPAM_SETTING_API);
    yield put(getSpamSettingSuccess(response.data.data.data));
  } catch (error) {
    yield put(spamFail(error));
  }
}

export function* createSpamSettingSaga(action) {
  yield put(spamStart());
  try {
    const response = yield axios.post(SPAM_SETTING_API, action.spam);
    yield put(spamCreateSuccess, response.data.data.data);
    yield call(getSpamSettingSaga);
  } catch (error) {
    yield put(spamFail(error));
  }
}

export function* updateSpamSettingSaga(action) {
  yield put(spamStart());
  try {
    const response = yield axios.patch(`${SPAM_SETTING_API}${action.spam.id}`, action.spam);
    yield put(spamUpdateSuccess(response.data.data.data));
    yield call(getSpamSettingSaga);
  } catch (error) {
    yield put(spamFail(error));
  }
}

export function* getSpammersSaga(action) {
  yield put(spamStart());
  try {
    const response = yield axios.get(SPAMMERS_API);
    yield put(getSpammersSuccess(response.data.data.data));
  } catch (error) {
    yield put(spamFail(error.response));
  }
}

export function* createSpammerSaga(action) {
  yield put(spamStart());
  try {
    const response = yield axios.post(SPAMMERS_API, action.spammer);
    yield put(createSpammerSuccess(response.data.data.data));
  } catch (error) {
    yield put(spamFail(error));
  }
}

export function* getSpammerSaga(action) {
  yield put(spamStart());
  try {
    const response = yield axios.get(SPAMMERS_API + action.id);
    yield put(getSpammerSuccess(response.data.data.data));
  } catch (error) {
    yield put(spamFail(error));
  }
}
