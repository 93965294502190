import * as actionTypes from "./actionTypes";

/**
 * @description We use `start` to reset the store, in order to store new values
 */
export const socialStart = () => {
  return {
    type: actionTypes.SOCIAL_START,
  };
};

/**
 * @description We use a `fail` method in order to get any error messages. It is a common `store` for the Social Signup module
 * @param {Error} error `Error`
 */
export const socialFail = (error) => {
  return {
    type: actionTypes.SOCIAL_FAIL,
    error,
  };
};

/**
 * @description An action which will dispatch when we pass a `service`.
 * @example facebook | twitter | linkedin | google
 * @param {String} service `String` : `facebook` | `twitter` | `linkedin` | `google`
 */
export const getSocial = (service) => {
  return {
    type: actionTypes.GET_SOCIAL,
    service,
  };
};

/**
 * @description An action to store response from the server
 * @param {JSON} response
 */
export const getSocialSuccess = (response) => {
  return {
    type: actionTypes.GET_SOCIAL_SUCCESS,
    response,
  };
};

/**
 * @description Sends a JSON Schema to the server in order to store.
 * @param {JSON} service
 */
export const createSocial = (service) => {
  return {
    type: actionTypes.CREATE_SOCIAL,
    service,
  };
};

export const createSocialSuccess = (response) => {
  return {
    type: actionTypes.CREATE_SOCIAL_SUCCESS,
    response,
  };
};

export const updateSocial = (service) => {
  return {
    type: actionTypes.UPDATE_SOCIAL,
    service,
  };
};

export const updateSocialSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_SOCIAL_SUCCESS,
    response,
  };
};
