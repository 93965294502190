import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { alert } from "./alertReducer";
import authReducer from "./authReducer";
import adminAuth from "./adminReducer";
import categoryReducer from "./categoryReducer";
import manageOptions from "./manageOptionsReducer";
import frontEndUsers from "./frontEndUserReducer";
import misc from "./miscReducer";
import countries from "./countriesReducer";
import settings from "./settingsReducer";
import contentPage from "./contentPagesReducer";
import paymentGateway from "./apiManagement/paymentGatewayReducer";
import recaptcha from "./apiManagement/recaptchaReducer";
import social from "./apiManagement/socialSignupReducer";
import headerLang from "./languageSelector/languageSelector";
import revenue from "./revenue/revenueReducer";
import role from "./roleManagement/roleManagementReducer";
import meta from "./meta/metaReducer";
import taxonomy from "./taxonomy/taxonomyReducer";
import spamSetting from "./spamSetting/spamSettingReducer";
import dashboard from "./dashboard/dashboardReducer";
import campaign from "./campaigns/campaignsReducer";
import accreditation from "./accreditation/accreditationReducer";
import activity from "./activity/activityReducer";
import email from "./emailSettings/emailSettingsReducer";
import cron from "./cron/cronReducer";
import transaction from "./transactions/transactionReducer";
import newsletter from "./newsletters/newsletterReducer";
import exportAllCSV from "./exportCsv/exportCsvReducer";
import wallet from "./wallet/walletReducer";

const rootPersistConfig = {
  key: "root",
  storage: storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  alert,
  adminAuth,
  categoryReducer,
  manageOptions,
  frontEndUsers,
  misc,
  countries,
  settings,
  contentPage,
  paymentGateway,
  recaptcha,
  social,
  headerLang,
  revenue,
  role,
  meta,
  taxonomy,
  spamSetting,
  dashboard,
  campaign,
  accreditation,
  activity,
  email,
  cron,
  transaction,
  newsletter,
  exportAllCSV,
  wallet
});

export default persistReducer(rootPersistConfig, rootReducer);
