/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Tooltip,
  OverlayTrigger,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import { useIntl } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  LoadingDialog,
} from "../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { getSiteBaseURL, getDemoMode } from "../../../helpers";
import {
  getTransactions,
  updateTransaction,
  getSearchTransactions,
} from "../../../store/actions";
import moment from "moment";
import { formatCurrency } from "../../../helpers/numberFormat";
import SearchTransaction from "./SearchTransaction";
import TotalTransaction from "./TotalTransaction";

function Transaction({history}) {
  const demoMode = getDemoMode();
  const siteBaseUrl = useRef(getSiteBaseURL());
  const methods = useForm({ mode: "onTouched" });
  const dispatch = useDispatch();
  const intl = useIntl();
  const [refund, setRefund] = useState(false);
  const [show, setShow] = useState(false);
  const [transactionInfo, setTransactionInfo] = useState();
  const [showMore, setShowMore] = useState(2);
  const [TransactionFilterData, setTransactionFilterData] = useState();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    dispatch(getTransactions(1));
  }, [dispatch]);

  const { transactions } = useSelector((state) => state.transaction);
  const { generalSettings } = useSelector((state) => state.settings);

  const loadMore = () => {
    setShowMore(showMore + 1);
    if (
      TransactionFilterData != undefined &&
      Object.keys(TransactionFilterData).length > 0
    ) {
      dispatch(getSearchTransactions(TransactionFilterData, showMore));
    } else {
      dispatch(getTransactions(showMore));
    }
  };

  const showTransactionDetail = (data) => {
    setShow(true);
    setTransactionInfo(data);
  };

  const handleClose = () => {
    setShow(false);
    setTransactionInfo();
  };

  const refundShow = (data) => {
    setRefund(true);
    setTransactionInfo(data);
    setValue("tranId", data?._id);
    setValue("preapprovalStatus", "REFUNDED");
    setValue("paymentStatus", 3);
  };

  const refundClose = () => {
    setRefund(false);
    reset();
  };

  const onSubmitRefund = (data) => {
    dispatch(updateTransaction(data));
    setRefund(false);
    reset();
  };
  const onSubmit = (data) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setTransactionFilterData(data);
    setShowMore(2);
    dispatch(getSearchTransactions(data, 1));
  };

  const transactionList =
    transactions?.docs?.length > 0 ? (
      transactions?.docs?.map((transaction, key) => (
        <tr key={key}>
          <td>
            <a
              href={`${siteBaseUrl.current}campaign-detail-page/${transaction?.companyId?.companySlug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {generalSettings?.projectScriptType === 1 
              ? transaction?.campaignId?.propertyName 
              : transaction?.companyId?.companyName }
            </a>
          </td>
          <td>
            <a
              href={`${siteBaseUrl.current}profile/${transaction.user?.slug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {transaction?.user?.firstName} {transaction?.user?.lastName}
            </a>
          </td>
          <td>
            {formatCurrency(
              transaction.amount ?? 0,
              generalSettings.currencySymbolSide,
              transaction?.campaignId?.equityCurrencySymbol,
              transaction.campaignId?.equityCurrencyCode,
              generalSettings.decimalPoints
            )}
          </td>
          <OverlayTrigger
            key={key}
            placement={"right"}
            overlay={
              <Tooltip>
                <span>
                  Transaction Fees:
                  <b> {transaction?.earnedFee ?? 0}</b>
                </span>
                <br />
                <span>
                  Earned Fees Percentage:
                  <b> {transaction?.earnedFeeDetail?.earnedFeesPercentage}</b>
                </span>
                <br />
                <span>
                  Earned Flat Fees:
                  <b> {transaction?.earnedFeeDetail?.earnedFlatFees}</b>
                </span>
              </Tooltip>
            }
          >
            <td>
              {formatCurrency(
                transaction.earnedFee ?? 0,
                generalSettings.currencySymbolSide,
                transaction?.campaignId?.equityCurrencySymbol,
                transaction.campaignId?.equityCurrencyCode,
                generalSettings.decimalPoints
              )}
            </td>
          </OverlayTrigger>
          <td>
            {moment(transaction.createdAt).format(generalSettings.dateFormat) ??
              "-"}
          </td>
          <td>
            <span
              className={`label label-lg ${
                transaction.preapprovalStatus === "SUCCESS"
                  ? "label-light-success"
                  : transaction.preapprovalStatus === "PENDING"
                  ? "label-light-default"
                  : "label-light-warning"
              } label-inline`}
            >
              {transaction.preapprovalStatus === "SUCCESS"
                ? "Success"
                : transaction.preapprovalStatus === "PENDING"
                ? "Pending"
                : "Refunded"}
            </span>
          </td>
          {demoMode === "false" ? (
            <td>
              <OverlayTrigger
                popperConfig={{
                  modifiers: {
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  },
                }}
                placement="bottom"
                overlay={<Tooltip>View</Tooltip>}
              >
                <button
                  onClick={() => showTransactionDetail(transaction)}
                  id={`kt_view_transaction_toggle_${key}`}
                  className="btn btn-icon btn-light btn-hover-success btn-sm ml-3"
                >
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG
                      title=" "
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Visible.svg"
                      )}
                    />
                  </span>
                </button>
              </OverlayTrigger>
              {transaction?.campaignId?.status === 2 &&
              transaction?.paymentStatus !== 2 ? (
                <OverlayTrigger
                  popperConfig={{
                    modifiers: {
                      preventOverflow: {
                        enabled: false,
                      },
                      hide: {
                        enabled: false,
                      },
                    },
                  }}
                  placement="bottom"
                  overlay={<Tooltip>Refund</Tooltip>}
                >
                  <button
                    disabled={
                      transaction.preapprovalStatus === "REFUNDED" ||
                      transaction.preapprovalStatus === "PENDING"
                    }
                    onClick={() => refundShow(transaction)}
                    id="kt_view_transaction_toggle"
                    className="btn btn-icon btn-light btn-hover-success btn-sm ml-3"
                  >
                    <span className="svg-icon svg-icon-md svg-icon-success">
                      <SVG
                        title=" "
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Shopping/Money.svg"
                        )}
                      />
                    </span>
                  </button>
                </OverlayTrigger>
              ) : null}
            </td>
          ) : null}
        </tr>
      ))
    ) : (
      <tr className="text-center">
        <td colSpan={7}>
          {transactions?.docs?.length === 0 ? "No Record Found" : "Loading..."}
        </td>
      </tr>
    );
  if (transactionList?.docs?.length < 1) {
    return (
      <>
        <LoadingDialog />
      </>
    );
  } else {
    return (
      <>
        <FormProvider {...methods}>
          <Form
            name="searchTransaction"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <SearchTransaction></SearchTransaction>
          </Form>
        </FormProvider>
        {transactions?.totalAmountWiseDetail?.length > 0 ? <TotalTransaction
          totalAmountWiseDetail={transactions?.totalAmountWiseDetail}
        ></TotalTransaction> :  null}
        <Card>
          <CardHeader title="Transactions"></CardHeader>
          <button
              type="button"
              className="offset-md-10 btn btn-primary mt-5 mr-2"
              onClick={() => history.push(`/add-transaction`)}
            >
              Add Transaction
            </button>
          <CardBody>
            <Table
              responsive
              className="table-head-custom table-vertical-center"
            >
              <thead>
                <tr>
                  <th>Campaign</th>
                  <th>Investor</th>
                  <th>Amount</th>
                  <th>Earned</th>
                  <th>Date</th>
                  <th>Status</th>
                  {demoMode === "false" ? (
                    <th className="width110">Action</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>{transactionList}</tbody>
            </Table>
            {transactions?.displayLoadMore ? (
              <div className="mt-5 text-center">
                <Button
                  type="button"
                  variant="primary"
                  onClick={() => loadMore()}
                >
                  Load More
                </Button>
              </div>
            ) : null}
          </CardBody>
        </Card>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Transaction Detail: {transactionInfo?.companyId?.companyName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="offcanvas-content pr-5 mr-n5">
              <ul className="p-0 m-0">
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Investor Name</span>
                  <strong className="ml-4">
                    {transactionInfo?.user?.firstName}{" "}
                    {transactionInfo?.user?.lastName}
                  </strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Transaction ID</span>
                  <strong className="ml-4">
                    {transactionInfo?.preapprovalKey ?? "-"}
                  </strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Gateway Name</span>
                  <strong className="ml-4">
                    {transactionInfo?.gatewayId?.title}
                  </strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Gateway Transaction ID</span>
                  <strong className="ml-4">
                    {transactionInfo?.paymentConfirmationId ?? "-"}
                  </strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Amount</span>
                  <strong className="ml-4">
                    {transactionInfo?.campaignId?.equityCurrencySymbol}{" "}
                    {transactionInfo?.amount}
                  </strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Status</span>
                  <strong className="ml-4">
                    <span
                      className={`label label-lg ${
                        transactionInfo?.preapprovalStatus === "SUCCESS"
                          ? "label-light-success"
                          : transactionInfo?.preapprovalStatus === "PENDING"
                          ? "label-light-default"
                          : "label-light-warning"
                      } label-inline`}
                    >
                      {transactionInfo?.preapprovalStatus === "SUCCESS"
                        ? "Success"
                        : transactionInfo?.preapprovalStatus === "PENDING"
                        ? "Pending"
                        : "Refunded"}
                    </span>
                  </strong>
                </li>
                <li className="d-flex justify-content-between py-3">
                  <span>Transaction Date</span>
                  <strong className="ml-4">
                    {moment(transactionInfo?.createdAt).format(
                      generalSettings?.dateFormat
                    ) ?? "-"}
                  </strong>
                </li>
              </ul>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={refund} onHide={refundClose}>
          <Form
            id="dealTypeSettings"
            className="form form-label-right"
            onSubmit={handleSubmit(onSubmitRefund)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Refund</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label>
                  <label>Write reason why you refund this payment?</label>
                  <span className="text-important">*</span>
                </label>
                <Form.Control
                  name="refundReason"
                  as="textarea"
                  rows="6"
                  className={classNames("form-control", {
                    "is-invalid": errors.refundReason,
                  })}
                  {...register("refundReason", {
                    required: intl.formatMessage({ id: "COMMON.FIELD.REQ" }),
                    pattern: {
                      value: /^[^\s]+(\s+[^\s]+)*$/,
                      message: "Please enter valid refund reason",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="refundReason"
                  render={({ message }) => (
                    <div className="invalid-feedback d-block">{message}</div>
                  )}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button type="button" variant="secondary" onClick={refundClose}>
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default Transaction;
