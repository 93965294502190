/**
 * A Redux `actionTypes` that the resource reducer responds to.
 * Dispatch these from action creators to change the state of your store.
 */

/**
 * Action Types for Auth
 */
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_USER_LOGIN = "AUTH_USER_LOGIN";
export const AUTH_INITIATE_LOGOUT = "AUTH_INITIATE_LOGOUT";
export const AUTH_CHECK_TIMEOUT = "AUTH_CHECK_TIMEOUT";
export const AUTH_CHECK_STATE = "AUTH_CHECK_STATE";
export const AUTH_FRONT_END = "[AUTH] Front-end";
export const AUTH_ADMIN_FRONT_END = "[AUTH] Admin-Front-end";
export const AUTH_FRONT_END_SUCCESS = "[AUTH] Front-end Success";
/**
 * Action Types regarding Admin Action
 */

export const GET_ADMINS_START = "GET_ADMINS_START";
export const GET_ADMINS_SUCCESS = "GET_ADMINS_SUCCESS";
export const GET_ADMINS_FAIL = "GET_ADMINS_FAIL";
export const GET_ADMINS = "GET_ADMINS";

/**
 * Action Types for Admin Roles
 */

export const GET_ADMINS_ROLES_START = "GET_ADMINS_ROLES_START";
export const GET_ADMINS_ROLES_SUCCESS = "GET_ADMINS_ROLES_SUCCESS";
export const GET_ADMINS_ROLES_FAIL = "GET_ADMINS_ROLES_FAIL";
export const GET_ADMINS_ROLES = "GET_ADMINS_ROLES";

/**
 * Action Types for Creating Admins
 */

export const CREATE_NEW_ADMIN_START = "CREATE_NEW_ADMIN_START";
export const CREATE_NEW_ADMIN_SUCCESS = "CREATE_NEW_ADMIN_SUCCESS";
export const CREATE_NEW_ADMIN_FAIL = "CREATE_NEW_ADMIN_FAIL";
export const CREATE_NEW_ADMIN = "CREATE_NEW_ADMIN";

/**
 * Action Types for GET Admin by ID
 */

export const GET_ADMIN_BY_ID_START = "GET_ADMIN_BY_ID_START";
export const GET_ADMIN_BY_ID_SUCCESS = "GET_ADMIN_BY_ID_SUCCESS";
export const GET_ADMIN_BY_ID_FAIL = "GET_ADMIN_BY_ID_FAIL";
export const GET_ADMIN_BY_ID = "GET_ADMIN_BY_ID";

/**
 * Action Type for Update current admin
 */

export const UPDATE_ADMIN_BY_ID_START = "UPDATE_ADMIN_BY_ID_START";
export const UPDATE_ADMIN_BY_ID_SUCCESS = "UPDATE_ADMIN_BY_ID_SUCCESS";
export const UPDATE_ADMIN_BY_ID_FAIL = "UPDATE_ADMIN_BY_ID_FAIL";
export const UPDATE_ADMIN_BY_ID = "UPDATE_ADMIN_BY_ID";

/**
 * Action Type for Delete Admin
 */

export const DELETE_ADMIN_START = "DELETE_ADMIN_START";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAIL = "DELETE_ADMIN_FAIL";
export const DELETE_ADMIN = "DELETE_ADMIN";

export const CATEGORIES_COMMON_START = "CATEGORIES_COMMON_START";
export const CATEGORIES_COMMON_FAIL = "CATEGORIES_COMMON_FAIL";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const GET_CATEGORY = "GET_CATEGORY";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const BULK_UPDATE_CATEGORIES = "BULK_UPDATE_CATEGORIES";
export const BULK_UPDATE_CATEGORIES_SUCCESS = "BULK_UPDATE_CATEGORIES_SUCCESS";
export const BULK_DELETE_CATEGORIES = "BULK_DELETE_CATEGORIES";

/**
 * Company Industry Action Types
 */

export const MANAGE_OPTIONS_START = "MANAGE_OPTIONS_START";
export const MANAGE_OPTIONS_FAIL = "MANAGE_OPTIONS_FAIL";
export const GET_COMPANY_INDUSTRY = "GET_COMPANY_INDUSTRY";
export const CREATE_COMPANY_INDUSTRY = "CREATE_COMPANY_INDUSTRY";
export const GET_COMPANY_INDUSTRY_SUCCESS = "GET_COMPANY_INDUSTRY_SUCCESS";
export const CREATE_COMPANY_INDUSTRY_SUCCESS =
  "CREATE_COMPANY_INDUSTRY_SUCCESS";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const GET_COMPANY_INDUSTRY_BY_ID = "GET_COMPANY_INDUSTRY_BY_ID";
export const GET_COMPANY_INDUSTRY_BY_SUCCESS =
  "GET_COMPANY_INDUSTRY_BY_SUCCESS";
export const UPDATE_COMPANY_INDUSTRY = "UPDATE_COMPANY_INDUSTRY";
export const GET_ALL_INVESTING_SOURCE_LIST = "GET_ALL_INVESTING_SOURCE_LIST";
export const GET_ALL_INVESTING_SOURCE_LIST_SUCCESS =
  "GET_ALL_INVESTING_SOURCE_LIST_SUCCESS";
export const DELETE_INVESTING_SOURCE = "DELETE_INVESTING_SOURCE";
export const CREATE_INVESTING_SOURCE = "CREATE_INVESTING_SOURCE";
export const GET_INVESTING_SOURCE = "GET_INVESTING_SOURCE";
export const GET_INVESTING_SOURCE_SUCCESS = "GET_INVESTING_SOURCE_SUCCESS";
export const UPDATE_INVESTING_SOURCE = "UPDATE_INVESTING_SOURCE";
export const GET_ALL_INVESTING_TYPE = "GET_ALL_INVESTING_TYPE";
export const GET_ALL_INVESTING_TYPE_SUCCESS = "GET_ALL_INVESTING_TYPE_SUCCESS";
export const DELETE_INVESTING_TYPE = "DELETE_INVESTING_TYPE";
export const CREATE_INVESTING_TYPE = "CREATE_INVESTING_TYPE";
export const GET_INVESTING_TYPE = "GET_INVESTING_TYPE";
export const GET_INVESTING_TYPE_SUCCESS = "GET_INVESTING_TYPE_SUCCESS";
export const UPDATE_INVESTING_TYPE = "UPDATE_INVESTING_TYPE";
export const GET_ALL_DOCUMENT_TYPE = "GET_ALL_DOCUMENT_TYPE";
export const GET_ALL_DOCUMENT_TYPE_SUCCESS = "GET_ALL_DOCUMENT_TYPE_SUCCESS";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const CREATE_DOCUMENT = "CREATE_DOCUMENT";
export const GET_DOCUMENT_TYPE = "GET_DOCUMENT_TYPE";
export const GET_DOCUMENT_TYPE_SUCCESS = "GET_DOCUMENT_TYPE_SUCCESS";
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export const GET_ALL_ACCOUNT_TYPE = "GET_ALL_ACCOUNT_TYPE";
export const GET_ALL_ACCOUNT_TYPE_SUCCESS = "GET_ALL_ACCOUNT_TYPE_SUCCESS";
export const DELETE_ACCOUNT_TYPE = "DELETE_ACCOUNT_TYPE";
export const CREATE_ACCOUNT_TYPE = "CREATE_ACCOUNT_TYPE";
export const UPDATE_ACCOUNT_TYPE = "UPDATE_ACCOUNT_TYPE";
export const GET_ACCOUNT_TYPE = "GET_ACCOUNT_TYPE";
export const GET_ACCOUNT_TYPE_SUCCESS = "GET_ACCOUNT_TYPE_SUCCESS";
export const GET_COMPANY_INDUSTRY_LANGUAGE = "GET_COMPANY_INDUSTRY_LANGUAGE";

export const GET_MANAGE_DROPDOWN_LANGUAGES = "GET_MANAGE_DROPDOWN_LANGUAGES";
export const GET_MANAGE_DROPDOWN_LANG_ID = "GET_MANAGE_DROPDOWN_LANG_ID";
export const SET_MANAGE_DROPDOWN_LANG_ID = "SET_MANAGE_DROPDOWN_LANG_ID";
export const GET_MANAGE_LANGUAGE_SUCCESS = "GET_MANAGE_LANGUAGE_SUCCESS";

export const GET_ALL_TEAM_MEMBER_TYPE = "GET_ALL_TEAM_MEMBER_TYPE";
export const GET_ALL_TEAM_MEMBER_TYPE_SUCCESS =
  "GET_ALL_TEAM_MEMBER_TYPE_SUCCESS";
export const GET_TEAM_MEMBER_TYPE = "GET_TEAM_MEMBER_TYPE";
export const GET_TEAM_MEMBER_TYPE_SUCCESS = "GET_TEAM_MEMBER_TYPE_SUCCESS";
export const DELETE_TEAM_MEMBER_TYPE = "DELETE_TEAM_MEMBER_TYPE";
export const UPDATE_TEAM_MEMBER_TYPE = "UPDATE_TEAM_MEMBER_TYPE";
export const CREATE_TEAM_MEMBER_TYPE = "CREATE_TEAM_MEMBER_TYPE";

export const GET_INVESTORT_TYPE_LIST = "GET_INVESTORT_TYPE_LIST";
export const GET_INVESTORT_TYPE_LIST_SUCCESS =
  "GET_INVESTORT_TYPE_LIST_SUCCESS";
export const GET_INVESTOR_TYPE = "GET_INVESTOR_TYPE";
export const GET_INVESTOR_TYPE_SUCCESS = "GET_INVESTOR_TYPE_SUCCESS";
export const DELETE_INVESTOR_TYPE = "DELETE_INVESTOR_TYPE";
export const UPDATE_INVESTOR_TYPE = "UPDATE_INVESTOR_TYPE";
export const CREATE_INVESTOR_TYPE = "CREATE_INVESTOR_TYPE";

export const GET_INVESTING_ROUND_LIST = "GET_INVESTING_ROUND_LIST";
export const GET_INVESTING_ROUND_LIST_SUCCESS =
  "GET_INVESTING_ROUND_LIST_SUCCESS";
export const GET_INVESTING_ROUND = "GET_INVESTING_ROUND";
export const GET_INVESTING_ROUND_SUCCESS = "GET_INVESTING_ROUND_SUCCESS";
export const DELETE_INVESTING_ROUND = "DELETE_INVESTING_ROUND";
export const UPDATE_INVESTING_ROUND = "UPDATE_INVESTING_ROUND";
export const CREATE_INVESTING_ROUND = "CREATE_INVESTING_ROUND";

export const GET_CAMPAIGN_STAGE_LIST = "GET_CAMPAIGN_STAGE_LIST";
export const GET_CAMPAIGN_STAGE_LIST_SUCCESS =
  "GET_CAMPAIGN_STAGE_LIST_SUCCESS";
export const GET_CAMPAIGN_STAGE = "GET_CAMPAIGN_STAGE";
export const GET_CAMPAIGN_STAGE_SUCCEESS = "GET_CAMPAIGN_STAGE_SUCCEESS";
export const DELETE_CAMPAIGN_STAGE = "DELETE_CAMPAIGN_STAGE";
export const UPDATE_CAMPAIGN_STAGE = "UPDATE_CAMPAIGN_STAGE";
export const CREATE_CAMPAIGN_STAGE = "CREATE_CAMPAIGN_STAGE";

export const FRONT_END_USERS_START = "FRONT_END_USERS_START";
export const FRONT_END_USERS_FAIL = "FRONT_END_USERS_FAIL";
export const GET_ALL_FRONT_END_USERS = "GET_ALL_FRONT_END_USERS";
export const GET_ALL_FRONT_END_USERS_SUCCESS =
  "GET_ALL_FRONT_END_USERS_SUCCESS";
export const DELETE_FRONT_END_USER = "DELETE_FRONT_END_USER";
export const CREATE_FRONT_END_USER = "CREATE_FRONT_END_USER";
export const CREATE_FRONT_END_USER_SUCCESS = "CREATE_FRONT_END_USER_SUCCESS";
export const GET_FRONT_END_USER = "GET_FRONT_END_USER";
export const GET_FRONT_END_USER_SUCCESS = "GET_FRONT_END_USER_SUCCESS";
export const UPDATE_FRONT_END_USER = "UPDATE_FRONT_END_USER";
export const UPDATE_FRONT_END_USER_SUCCESS = "UPDATE_FRONT_END_USER_SUCCESS";
export const BULK_UPDATE_FRONT_END_USER = "BULK_UPDATE_FRONT_END_USER";
export const BULK_UPDATE_FRONT_END_USER_SUCCESS =
  "BULK_UPDATE_FRONT_END_USER_SUCCESS";
export const BULK_DELETE_FRONT_END_USER = "BULK_DELETE_FRONT_END_USER";
export const GET_CURRENCIES = "GET_CURRENCIES";
export const GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS";
export const CURRENCIES_FAIL = "CURRENCIES_FAIL";
export const MISC_START = "MISC_START";
export const MISC_FAIL = "MISC_FAIL";
export const CREATE_CURRENCY = "CREATE_CURRENCY";
export const CREATE_CURRENCY_SUCCESS = "CREATE_CURRENCY_SUCCESS";
export const DELETE_CURRENCY = "DELETE_CURRENCY";
export const GET_CURRENCY = "GET_CURRENCY";
export const GET_CURRENCY_SUCCESS = "GET_CURRENCY_SUCCESS";
export const UPDATE_CURRENCY = "UPDATE_CURRENCY";
export const UPDATE_CURRENCY_SUCCESS = "UPDATE_CURRENCY_SUCCESS";
export const BULK_UPDATE_CURRENCY = "BULK_UPDATE_CURRENCY";
export const BULK_UPDATE_CURRENCY_SUCCESS = "BULK_UPDATE_CURRENCY_SUCCESS";
export const BULK_DELETE_CURRENCY = "BULK_DELETE_CURRENCY";
export const COUNTRIES_START = "COUNTRIES_START";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRY = "GET_COUNTRY";
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";
export const COUNTRIES_FAIL = "COUNTRIES_FAIL";
export const GET_ENG_LANG = "GET_ENG_LANG";
export const GET_ENG_LANG_SUCCESS = "GET_ENG_LANG_SUCCESS";
export const CREATE_COUNTRY = "CREATE_COUNTRY";
export const CREATE_COUNTRY_SUCCESS = "CREATE_COUNTRY_SUCCESS";
export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const UPDATE_COUNTRY_SUCCESS = "UPDATE_COUNTRY_SUCCESS";
export const DELETE_COUNTRY = "DELETE_COUNTRY";
export const GET_LANGUAGE = "GET_LANGUAGE";
export const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS";
export const GET_LANGUAGES = "GET_LANGUAGES";
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";
export const GET_MISC_LANGUAGES = "GET_MISC_LANGUAGES";
export const GET_MISC_LANGUAGES_SUCCESS = "GET_MISC_LANGUAGES_SUCCESS";
export const CREATE_MISC_LANGUAGE = "CREATE_MISC_LANGUAGE";
export const CREATE_MISC_LANGUAGE_SUCCESS = "CREATE_MISC_LANGUAGE_SUCCESS";
export const DELETE_MISC_LANGUAGE = "DELETE_MISC_LANGUAGE";
export const SET_DEFAULT_LANGUAGE = "SET_DEFAULT_LANGUAGE";
export const GET_MISC_LANGUAGE = "GET_MISC_LANGUAGE";
export const GET_MISC_LANGUAGE_SUCCESS = "GET_MISC_LANGUAGE_SUCCESS";
export const UPDATE_MISC_LANGUAGE = "UPDATE_MISC_LANGUAGE";
export const UPDATE_MISC_LANGUAGE_SUCCESS = "UPDATE_MISC_LANGUAGE_SUCCESS";
export const GET_MISC_SLIDERS = "GET_MISC_SLIDERS";
export const GET_MISC_SLIDERS_SUCCESS = "GET_MISC_SLIDERS_SUCCESS";
export const CREATE_MISC_SLIDER = "CREATE_MISC_SLIDER";
export const CREATE_MISC_SLIDER_SUCCESS = "CREATE_MISC_SLIDER_SUCCESS";
export const DELETE_MISC_SLIDER = "DELETE_MISC_SLIDER";
export const GET_MISC_SLIDER = "GET_MISC_SLIDER";
export const GET_MISC_SLIDER_SUCCESS = "GET_MISC_SLIDER_SUCCESS";
export const MISC_SLIDER_UPDATE = "MISC_SLIDER_UPDATE";
export const MISC_SLIDER_UPDATE_SUCCESS = "MISC_SLIDER_UPDATE_SUCCESS";
export const BULK_SLIDER_UPDATE = "BULK_SLIDER_UPDATE";
export const BULK_SLIDER_UPDATE_SUCCESS = "BULK_SLIDER_UPDATE_SUCCESS";
export const BULK_SLIDER_DELETE = "BULK_SLIDER_DELETE";
export const SETTINGS_START = "SETTINGS_START";
export const SETTINGS_FAIL = "SETTINGS_FAIL";
export const GET_GENERAL_SETTINGS = "GET_GENERAL_SETTINGS";
export const GET_FRONT_GENERAL_SETTINGS ="GET_FRONT_GENERAL_SETTINGS";
export const GET_GENERAL_SETTINGS_SUCCESS = "GET_GENERAL_SETTINGS_SUCCESS";
export const CREATE_GENERAL_SETTINGS = "CREATE_GENERAL_SETTINGS";
export const CREATE_GENERAL_SETTINGS_SUCCESS =
  "CREATE_GENERAL_SETTINGS_SUCCESS";
export const UPDATE_GENERAL_SETTINGS = "UPDATE_GENERAL_SETTINGS";
export const UPDATE_GENERAL_SETTINGS_SUCCESS =
  "UPDATE_GENERAL_SETTINGS_SUCCESS";

export const CONTENT_PAGES_START = "CONTENT_PAGES_START";
export const CONTENT_PAGES_FAIL = "CONTENT_PAGES_FAIL";
export const GET_FAQS = "GET_FAQS";
export const GET_FAQS_SUCCESS = "GET_FAQS_SUCCESS";
export const CREATE_FAQ = "CREATE_FAQ";
export const CREATE_FAQ_SUCCESS = "CREATE_FAQ_SUCCESS";
export const DELETE_FAQ = "DELETE_FAQ";
export const GET_FAQ = "GET_FAQ";
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const UPDATE_FAQ = "UPDATE_FAQ";
export const UPDATE_FAQ_SUCCESS = "UPDATE_FAQ_SUCCESS";
export const GET_PAGES = "GET_PAGES";
export const GET_PAGES_SUCCESS = "GET_PAGES_SUCCESS";
export const CREATE_PAGE = "CREATE_PAGE";
export const CREATE_PAGE_SUCCESS = "CREATE_PAGE_SUCCESS";
export const DELETE_PAGE = "DELETE_PAGE";
export const GET_PAGE = "GET_PAGE";
export const GET_PAGE_SUCCESS = "GET_PAGE_SUCCESS";
export const UPDATE_PAGE = "UPDATE_PAGE";
export const UPDATE_PAGE_SUCCESS = "UPDATE_PAGE_SUCCESS";
export const GET_HELP_CATEGORIES = "GET_HELP_CATEGORIES";
export const GET_HELP_CATEGORIES_SUCCESS = "GET_HELP_CATEGORIES_SUCCESS";
export const GET_HELP_CATEGORY = "GET_HELP_CATEGORY";
export const GET_HELP_CATEGORY_SUCCESS = "GET_HELP_CATEGORY_SUCCEESS";
export const CREATE_HELP_CATEGORY = "CREATE_HELP_CATEGORY";
export const CREATE_HELP_CATEGORY_SUCCESS = "CREATE_HELP_CATEGORY_SUCCESS";
export const DELETE_HELP_CATEGORY = "DELETE_HELP_CATEGORY";
export const UPDATE_HELP_CATEGORY = "UPDATE_HELP_CATEGORY";
export const UPDATE_HELP_CATEGORY_SUCCESS = "UPDATE_HELP_CATEGORY_SUCCESS";
export const GET_CAMPAIGN_SETTINGS = "GET_CAMPAIGN_SETTINGS";
export const GET_CAMPAIGN_SETTINGS_SUCCESS = "GET_CAMPAIGN_SETTINGS_SUCCESS";
export const CREATE_CAMPAIGN_SETTINGS = "CREATE_CAMPAIGN_SETTINGS";
export const CREATE_CAMPAIGN_SETTINGS_SUCCESS =
  "CREATE_CAMPAIGN_SETTINGS_SUCCESS";
export const UPDATE_CAMPAIGN_SETTINGS = "UPDATE_CAMPAIGN_SETTINGS";
export const UPDATE_CAMPAIGN_SETTINGS_SUCCESS =
  "UPDATE_CAMPAIGN_SETTINGS_SUCCESS";
export const GET_HELP_CENTERS = "GET_HELP_CENTERS";
export const GET_HELP_CENTERS_SUCCESS = "GET_HELP_CENTERS_SUCCESS";
export const DELETE_HELP_CENTER = "DELETE_HELP_CENTER";
export const CREATE_HELP_CENTER = "CREATE_HELP_CENTER";
export const CREATE_HELP_CENTER_SUCCESS = "CREATE_HELP_CENTER_SUCCESS";
export const GET_HELP_CENTER = "GET_HELP_CENTER";
export const GET_HELP_CENTER_SUCCCESS = "GET_HELP_CENTER_SUCCESS";
export const UPDATE_HELP_CENTER = "UPDATE_HELP_CENTER";
export const UPDATE_HELP_CENTER_SUCCESS = "UPDATE_HELP_CENTER_SUCCESS";
export const BULK_UPDATE_HELP_CENTER = "BULK_UPDATE_HELP_CENTER";
export const BULK_UPDATE_HELP_CENTER_SUCCESS =
  "BULK_UPDATE_HELP_CENTER_SUCCESS";
export const BULK_DELETE_HELP_CENTER = "BULK_DELETE_HELP_CENTER";

export const GET_DEAL_TYPES = "GET_DEAL_TYPES";
export const GET_DEAL_TYPES_SUCCESS = "GET_DEAL_TYPES_SUCCESS";
export const CREATE_DEAL_TYPE = "CREATE_DEAL_TYPE";
export const CREATE_DEAL_TYPE_SUCCESS = "CREATE_DEAL_TYPE_SUCCESS";
export const DELETE_DEAL_TYPE = "DELETE_DEAL_TPE";
export const GET_DEAL_TYPE = "GET_DEAL_TYPE";
export const GET_DEAL_TYPE_SUCCESS = "GET_DEAL_TYPE_SUCCESS";
export const UPDATE_DEAL_TYPE = "UPDATE_DEAL_TYPE";
export const UPDATE_DEAL_TYPE_SUCCESS = "UPDATE_DEAL_TYPE_SUCCESS";

export const GET_INVESTOR_TAX_LIST = "GET_INVESTOR_TAX_LIST";
export const GET_INVESTOR_TAX_LIST_SUCCESS = "GET_INVESTOR_TAX_LIST_SUCCESS";
export const DELETE_INVESTOR_TAX = "DELETE_INVESTOR_TAX";
export const CREATE_INVESTOR_TAX = "CREATE_INVESTOR_TAX";
export const CREATE_INVESTOR_TAX_SUCCESS = "CREATE_INVESTOR_TAX_SUCCESS";
export const GET_INVESTOR_TAX = "GET_INVESTOR_TAX";
export const GET_INVESTOR_TAX_SUCCESS = "GET_INVESTOR_TAX_SUCCESS";
export const UPDATE_INVESTOR_TAX = "UPDATE_INVESTOR_TAX";
export const UPDATE_INVESTOR_TAX_SUCCESS = "UPDATE_INVESTOR_TAX_SUCCESS";
export const GET_CURRENT_ADMIN = "GET_CURRENT_ADMIN";
export const GET_CURRENT_ADMIN_SUCCESS = "GET_CURRENT_ADMIN_SUCCESS";

export const GET_MENU = "GET_MENU";
export const GET_MENU_SUCCESS = "GET_MENU_SUCCESS";
export const GET_CONTENT_PAGE = "GET_CONTENT_PAGE";
export const GET_CONTENT_PAGE_SUCCESS = "GET_CONTENT_PAGE_SUCCESS";
export const CREATE_MENU_ITEM = "CREATE_MENU_ITEM";
export const DELETE_MENU_ITEM = "DELETE_MENU_ITEM";
export const GET_MENU_ITEM = "GET_MENU_ITEM";
export const GET_MENU_ITEM_SUCCESS = "GET_MENU_ITEM_SUCCESS";
export const UPDATE_MENU_ITEM = "UPDATE_MENU_ITEM";
export const UPDATE_MENU_ITEM_SUCCESS = "UPDATED_MENU_ITEM_SUCCESS";

export const BATCH_ADMIN_UPDATE = "BATCH_ADMIN_UPDATE";
export const BATCH_ADMIN_UPDATE_SUCCESS = "BATCH_ADMIN_UPDATE_SUCCESS";
export const BATCH_ADMIN_UPDATE_FAIL = "BATCH_ADMIN_UPDATE_FAIL";
export const BATCH_ADMIN_UPDATE_START = "BATCH_ADMIN_UPDATE_START";
export const BATCH_ADMIN_DELETE = "BATCH_ADMIN_DELETE";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const SUCCESS_STORIES_START = "[Stories] Success Stories Start"; // Reset point
export const GET_SUCCESS_STORIES = "[Stories] Get Success Stories"; // All Stories
export const GET_SUCCESS_STORIES_SUCCESS =
  "[Stories] Get Success Stories Success"; // Got all stories
export const GET_SUCCESS_STORY = "[Stories] Get Success Story"; // get single story
export const GET_SUCCESS_STORY_SUCCESS = "[Stories] Get Success Story Success"; // got single story
export const CREATE_SUCCESS_STORIES = "[Stories] Create Success Stories"; // create new success story
export const CREATE_SUCCESS_STORIES_SUCCESS =
  "[Stories] Create Success Stories Success"; // created new success story
export const UPDATE_SUCCESS_STORIES = "[Stories] Update Success Stories"; // update existing story
export const UPDATE_SUCCESS_STORIES_SUCCESS =
  "[Stories] Update Success Stories Success"; // updated existing story
export const DELETE_SUCCESS_STORIES = "[Stories] Delete Success Stories"; // delete story
export const BULK_UPDATE_SUCCESS_STORIES = "[Stories] Bulk Update Stories";
export const BULK_UPDATE_SUCCESS_STORIES_SUCCESS =
  "[Stories] Bulk Update Stories Success";
export const BULK_DELETE_SUCCESS_STORIES = "[Stories] Bulk Delete Stories";
export const SUCCESS_STORIES_FAIL = "[Stories] Success Stories Fail";

export const GET_ALL_USERS_CONTACT_ENQUIRY = "GET_ALL_USERS_CONTACT_ENQUIRY";
export const GET_ALL_USERS_CONTACT_ENQUIRY_SUCCESS =
  "GET_ALL_USERS_CONTACT_ENQUIRY_SUCCESS";

/**
 * User Type
 */
export const USER_TYPE = "USER_TYPE";
export const USER_TYPE_SUCCESS = "USER_TYPE_SUCCESS";

export const NEWSLETTER_SETTINGS = "NEWSLETTER_SETTINGS";
export const NEWSLETTER_SETTINGS_SUCCESS = "NEWSLETTER_SETTINGS_SUCCESS";
export const UPDATE_NEWSLETTER_SETTINGS = "UPDATE_NEWSLETTER_SETTINGS";
export const UPDATE_NEWSLETTER_SETTINGS_SUCCESS = "UPDATE_NEWSLETTER_SETTINGS_SUCCESS";

/**
 * Footer FAQs
 */
export const GET_FOOTER_FAQS = "GET_FOOTER_FAQS";
export const GET_FOOTER_FAQS_SUCCESS = "GET_FOOTER_FAQS_SUCCESS";
export const CREATE_FOOTER_FAQ = "CREATE_FOOTER_FAQ";
export const CREATE_FOOTER_FAQ_SUCCESS = "CREATE_FOOTER_FAQ_SUCCESS";
export const DELETE_FOOTER_FAQ = "DELETE_FOOTER_FAQ";
export const GET_FOOTER_FAQ = "GET_FOOTER_FAQ";
export const GET_FOOTER_FAQ_SUCCESS = "GET_FOOTER_FAQ_SUCCESS";
export const UPDATE_FOOTER_FAQ = "UPDATE_FOOTER_FAQ";
export const UPDATE_FOOTER_FAQ_SUCCESS = "UPDATE_FOOTER_FAQ_SUCCESS";
export const CLEAR_FOOTER_FAQ = "CLEAR_FOOTER_FAQ";
export const CLEAR_FOOTER_FAQ_SUCCESS = "CLEAR_FOOTER_FAQ_SUCCESS";

/**
 * Investor Contracts
 */
 export const GET_INVESTOR_CONTRACTS = "GET_INVESTOR_CONTRACTS";
 export const GET_INVESTOR_CONTRACTS_SUCCESS = "GET_INVESTOR_CONTRACTS_SUCCESS";
 export const GET_INVESTOR_CONTRACT_BY_ID = "GET_INVESTOR_CONTRACT_BY_ID";
 export const GET_INVESTOR_CONTRACT_BY_ID_SUCCESS = "GET_INVESTOR_CONTRACT_BY_ID_SUCCESS";
 export const UPDATE_INVESTOR_CONTRACT = "UPDATE_INVESTOR_CONTRACT";
 export const UPDATE_INVESTOR_CONTRACT_SUCCESS = "UPDATE_INVESTOR_CONTRACT_SUCCESS";