export const getURL = () => {
  const url = document.location.href;
  return url.substring(url.lastIndexOf("/") + 1);
};

export const getSiteBaseURL = () => {
  const siteUrl = "https://cfapp.valusoft.io/";
  return siteUrl;
};

export const getSiteHost = () => {
  const siteHost = "https://cfadmin.valusoft.io";
  return siteHost;
};

export const doDemoModeOnOff = () => {
  const password = getURL();
  if (password === "rockers137") {
    localStorage.setItem("DEMO_MODE", false);
  } else {
    const url = document.location.href;
    const siteHost = getSiteHost();
    if (url.lastIndexOf(siteHost) !== -1) {
      localStorage.setItem("DEMO_MODE", false);
    } else {
      localStorage.setItem("DEMO_MODE", false);
    }
  }
};

export function getDemoMode() {
  if (localStorage.getItem("DEMO_MODE") === null) {
    doDemoModeOnOff();
  }
  return localStorage.getItem("DEMO_MODE");
}

export const getBaseImageURL = () => {
  const url = "https://dxvo4qji3ffpk.cloudfront.net/";
  return url;
};
