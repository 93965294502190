import * as actionTypes from "../../actions/taxonomy/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  taxonomy: [],
  error: null,
  isCreated: false,
  isUpdated: false,
};

export const taxonomyStart = (state, action) => {
  return updateObject(state, {
    taxonomy: [],
    error: null,
    isCreated: false,
    isUpdated: false,
  });
};

export const taxonomyFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

export const getTaxomony = (state, action) => {
  return updateObject(state, {
    taxonomy: action.response,
  });
};

const isCreated = (state, action) => {
  return updateObject(state, {
    isCreated: true,
  });
};

const isUpdated = (state, action) => {
  return updateObject(state, {
    isUpdated: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TAXONOMY_START:
      return taxonomyStart(state, action);
    case actionTypes.TAXONOMY_FAIL:
      return taxonomyFail(state, action);
    case actionTypes.GET_TAXONOMY_SUCCESS:
      return getTaxomony(state, action);
    case actionTypes.CREATE_TAXONOMY_SUCCESS:
      return isCreated(state, action);
    case actionTypes.UPDATE_TAXONOMY_SUCCESS:
      return isUpdated(state, action);
    default:
      return state;
  }
};

export default reducer;
