import * as actionTypes from "./actionTypes";

/**
 * GET ALL ADMINS
 */
export const getAllAdminsStart = () => {
  return {
    type: actionTypes.GET_ADMINS_START,
  };
};

export const getAllAdminsSuccess = (response) => {
  return {
    type: actionTypes.GET_ADMINS_SUCCESS,
    response: response,
  };
};

export const getAllAdminsFail = (error) => {
  return {
    type: actionTypes.GET_ADMINS_FAIL,
    error,
  };
};
/**
 *
 * @param {Number} limit
 * @param {Number} page
 */
export const getAllAdmins = (search, pageLimit) => {
  return {
    type: actionTypes.GET_ADMINS,
    search,
    pageLimit,
  };
};

/**
 * GET ADMIN ROLES
 */
export const getAllAdminRolesStart = () => {
  return {
    type: actionTypes.GET_ADMINS_ROLES_START,
  };
};

export const getAllAdminRolesSuccess = (response) => {
  return {
    type: actionTypes.GET_ADMINS_ROLES_SUCCESS,
    response: response,
  };
};

export const getAllAdminRolesFail = (error) => {
  return {
    type: actionTypes.GET_ADMINS_ROLES_FAIL,
    error: error,
  };
};

export const getAllAdminRoles = () => {
  return {
    type: actionTypes.GET_ADMINS_ROLES,
  };
};

/**
 * CREATE ADMINS
 */

export const createNewAdminStart = () => {
  return {
    type: actionTypes.CREATE_NEW_ADMIN_START,
  };
};

export const createNewAdminSuccess = (response) => {
  return {
    type: actionTypes.CREATE_NEW_ADMIN_SUCCESS,
    response,
  };
};

export const createNewAdminFail = (error) => {
  return {
    type: actionTypes.CREATE_NEW_ADMIN_FAIL,
    error,
  };
};

export const createNewAdmin = (adminData) => {
  return {
    type: actionTypes.CREATE_NEW_ADMIN,
    adminData,
  };
};

export const getAdminByIdStart = () => {
  return {
    type: actionTypes.GET_ADMIN_BY_ID_START,
  };
};

export const getAdminByIdSuccess = (response) => {
  return {
    type: actionTypes.GET_ADMIN_BY_ID_SUCCESS,
    response,
  };
};

export const getAdminByIdFail = (error) => {
  return {
    type: actionTypes.GET_ADMIN_BY_ID_FAIL,
    error,
  };
};

export const getAdminById = (id) => {
  return {
    type: actionTypes.GET_ADMIN_BY_ID,
    id,
  };
};

export const updateAdminByIdStart = () => {
  return {
    type: actionTypes.UPDATE_ADMIN_BY_ID_START,
  };
};

export const updateAdminByIdSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_ADMIN_BY_ID_SUCCESS,
    response,
  };
};

export const updateAdminByIdFail = (error) => {
  return {
    type: actionTypes.UPDATE_ADMIN_BY_ID_FAIL,
    error,
  };
};

export const updateAdminById = (adminData) => {
  return {
    type: actionTypes.UPDATE_ADMIN_BY_ID,
    adminData,
  };
};

export const deleteAdminByIdStart = () => {
  return {
    type: actionTypes.DELETE_ADMIN_START,
  };
};

export const deleteAdminByIdSuccess = (response) => {
  return {
    type: actionTypes.DELETE_ADMIN_SUCCESS,
    response,
  };
};

export const deleteAdminByIdFail = (error) => {
  return {
    type: actionTypes.DELETE_ADMIN_FAIL,
    error,
  };
};

export const deleteAdminById = (id) => {
  return {
    type: actionTypes.DELETE_ADMIN,
    id,
  };
};

export const getCurrentAdmin = () => {
  return {
    type: actionTypes.GET_CURRENT_ADMIN,
  };
};

export const getCurrentAdminSuccess = (response) => {
  return {
    type: actionTypes.GET_CURRENT_ADMIN_SUCCESS,
    response,
  };
};

// BATCH START

export const batchUpdateAdminStart = () => {
  return {
    type: actionTypes.BATCH_ADMIN_UPDATE_START,
  };
};

export const batchAdminUpdate = (adminData) => {
  return {
    type: actionTypes.BATCH_ADMIN_UPDATE,
    adminData,
  };
};

export const batchAdminUpdateSuccess = (response) => {
  return {
    type: actionTypes.BATCH_ADMIN_UPDATE_SUCCESS,
    response,
  };
};

export const batchAdminUpdateFail = (error) => {
  return {
    type: actionTypes.BATCH_ADMIN_UPDATE_FAIL,
    error,
  };
};

export const batchAdminDelete = (adminData) => {
  return {
    type: actionTypes.BATCH_ADMIN_DELETE,
    adminData,
  };
};

export const changePasswordStart = (clear) => {
  return {
    type: actionTypes.CHANGE_PASSWORD_START,
    clear
  };
};

export const changePassword = (credentials) => {
  return {
    type: actionTypes.CHANGE_PASSWORD,
    credentials
  };
};

export const changePasswordSuccess = (response) => {
  return {
    type: actionTypes.CHANGE_PASSWORD_SUCCESS,
    response
  };
};

export const changePasswordFail = (error) => {
  return {
    type: actionTypes.CHANGE_PASSWORD_FAIL,
    error
  };
};
