import { put, call } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import {
  emailStart,
  emailFail,
  getEmailTemplatesSuccess,
  getEmailTemplateSuccess,
  createEmailTemplateSuccess,
  sendTestEmailSuccess,
  updateEmailTemplateSuccess,
  updateMasterEmailSuccess,
  bulkUpdateEmailSuccess
} from "../../actions/emailSettings/emailSettingsActions";
import {
  EMAIL_SETTINGS_API,
  SEND_TEST_EMAIL_API,
  UPDATE_MASTER_EMAIL_API
} from "../../actions/apiCollections";
export function* getEmailTemplatesSaga() {
  yield put(emailStart());
  try {
    const response = yield axios.get(EMAIL_SETTINGS_API);
    yield put(getEmailTemplatesSuccess(response.data.data.data.docs));
  } catch (error) {
    yield put(emailFail(error));
  }
}

export function* getEmailTemplateSaga(action) {
  yield put(emailStart());
  try {
    const response = yield axios.get(`${EMAIL_SETTINGS_API}${action.id}`);
    yield put(getEmailTemplateSuccess(response.data.data.data));
  } catch (error) {
    yield put(emailFail(error));
  }
}

export function* createEmailTemplateSaga(action) {
  yield put(emailStart());
  try {
    const response = yield axios.post(EMAIL_SETTINGS_API, action.emailTemplate);
    yield put(createEmailTemplateSuccess(response.data.data.data));
  } catch (error) {
    yield put(emailFail(error));
  }
}

export function* deleteEmailTemplateSaga(action) {
  yield put(emailStart());
  try {
    yield axios.delete(EMAIL_SETTINGS_API + action.id);
    yield call(getEmailTemplatesSaga);
  } catch (error) {
    yield put(emailFail(error));
  }
}

export function* sendTestEmailSaga(action) {
  yield put(emailStart());
  try {
    const response = yield axios.post(
      SEND_TEST_EMAIL_API,
      action.emailData,
      { params: { lng: "en" } }
    );
    yield put(sendTestEmailSuccess(response.data.data.data));
  } catch (error) {
    yield put(emailFail(error));
  }
}

export function* updateEmailTemplateSaga(action) {
  yield put(emailStart());
  try {
    const response = yield axios.patch(EMAIL_SETTINGS_API + action.emailTemplate.id, action.emailTemplate);
    yield put(updateEmailTemplateSuccess(response.data.data.data));
  } catch (error) {
    yield put(emailFail(error));
  }
}

export function* updateMasterEmailSaga(action) {
  yield put(emailStart());
  try {
    const response = yield axios.post(
      UPDATE_MASTER_EMAIL_API,
      action.emailData
    );
    yield put(updateMasterEmailSuccess(response.data.data.data));
    yield call(getEmailTemplatesSaga);
  } catch (error) {
    yield put(emailFail(error));
  }
}

export function* bulkUpdateEmailTemplateSaga(action) {
  yield put(emailStart());
  try {
    const response = yield axios.post(EMAIL_SETTINGS_API + "bulk-updates", action.emailData);
    yield put(bulkUpdateEmailSuccess(response.data.data.data));
    yield call(getEmailTemplatesSaga);
  } catch (error) {
    yield put(emailFail(error));
  }
}
