import * as actionTypes from "../../actions/apiManagement/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  error: null,
  social: [],
  isCreated: false,
};

const socialStart = (state, action) => {
  return updateObject(state, {
    error: null,
    isCreated: false,
    social: [],
  });
};

const socialFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

const getSocial = (state, action) => {
  return updateObject(state, {
    social: action.response,
  });
};

const generalSocialSuccess = (state, action) => {
  return updateObject(state, {
    isCreated: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SOCIAL_START:
      return socialStart(state, action);
    case actionTypes.GET_SOCIAL_SUCCESS:
      return getSocial(state, action);
    case actionTypes.CREATE_SOCIAL_SUCCESS:
    case actionTypes.UPDATE_SOCIAL_SUCCESS:
      return generalSocialSuccess(state, action);
    case actionTypes.SOCIAL_FAIL:
      return socialFail(state, action);
    default:
      return state;
  }
};

export default reducer;
