import * as actionTypes from "./actionTypes";

export const settingsStart = () => {
  return {
    type: actionTypes.SETTINGS_START,
  };
};

export const getFrontGeneralSettings = () => {
  return {
    type: actionTypes.GET_FRONT_GENERAL_SETTINGS,
  };
};

export const getGeneralSettings = () => {
  return {
    type: actionTypes.GET_GENERAL_SETTINGS,
  };
};

export const getGeneralSettingsSuccess = (response) => {
  return {
    type: actionTypes.GET_GENERAL_SETTINGS_SUCCESS,
    response,
  };
};

export const createGeneralSettings = (generalSettings) => {
  return {
    type: actionTypes.CREATE_GENERAL_SETTINGS,
    generalSettings,
  };
};

export const createGeneralSettingsSuccess = (response) => {
  return {
    type: actionTypes.CREATE_GENERAL_SETTINGS_SUCCESS,
    response,
  };
};

export const updateGeneralSettings = (generalSettings) => {
  return {
    type: actionTypes.UPDATE_GENERAL_SETTINGS,
    generalSettings,
  };
};

export const updateGeneralSettingsSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_GENERAL_SETTINGS_SUCCESS,
    response,
  };
};

export const settingsFail = (error) => {
  return {
    type: actionTypes.SETTINGS_FAIL,
    error,
  };
};

export const getCampaignSettings = () => {
  return {
    type: actionTypes.GET_CAMPAIGN_SETTINGS,
  };
};

export const getCampaignSettingsSuccess = (response) => {
  return {
    type: actionTypes.GET_CAMPAIGN_SETTINGS_SUCCESS,
    response,
  };
};
/**
 * @description An action that invokes creation of Campaign Settings component. There parameter
 * is `FormData` as there are file uploads in this component
 * @param {FormData} campaign : FormData
 */
export const createCampaignSettings = (campaign) => {
  return {
    type: actionTypes.CREATE_CAMPAIGN_SETTINGS,
    campaign,
  };
};

/**
 * @description An action which gets us the response in `JSON` format which further is stored in store/reducer
 * @param {JSON} response JSON
 */
export const createCampaignSettingsSuccess = (response) => {
  return {
    type: actionTypes.CREATE_CAMPAIGN_SETTINGS_SUCCESS,
    response,
  };
};

/**
 * @returns `Object`
 * @param {FormData} campaign FormData
 */
export const updateCampaignSettings = (campaign) => {
  return {
    type: actionTypes.UPDATE_CAMPAIGN_SETTINGS,
    campaign,
  };
};

/**
 * @returns `Object`
 * @param {JSON} response JSON
 */
export const updateCampaignSettingsSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_CAMPAIGN_SETTINGS_SUCCESS,
    response,
  };
};

export const getAllUsersContactEnquiry = (response) => {
  return {
    type: actionTypes.GET_ALL_USERS_CONTACT_ENQUIRY,
    response,
  };
};

export const getAllUsersContactEnquirySuccess = (response) => {
  return {
    type: actionTypes.GET_ALL_USERS_CONTACT_ENQUIRY_SUCCESS,
    response,
  };
};

export const getNewsletterSettings = () => {
  return {
    type: actionTypes.NEWSLETTER_SETTINGS
  };
};

export const getNewsletterSettingsSuccess = (response) => {
  return {
    type: actionTypes.NEWSLETTER_SETTINGS_SUCCESS,response
  };
};

export const updateNewsletterSettings = (id, newsletter) => {
  return {
    type: actionTypes.UPDATE_NEWSLETTER_SETTINGS,
    id,
    newsletter,
  };
};

export const updateNewsletterSettingsSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_NEWSLETTER_SETTINGS_SUCCESS,
    response,
  };
};
