/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Input } from "../../../../_metronic/_partials/controls";
import * as actions from "../../../../store/actions/index";	
import { compose } from "redux";
import * as Yup from "yup";
import { FormGroup, Alert } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { toastifyConstant } from "../../../../helpers";
// import { loadReCaptcha } from "react-recaptcha-v3";
import {
	loadCaptchaEnginge,
	LoadCanvasTemplateNoReload,
	validateCaptcha
} from "react-simple-captcha";
import { getPublicRecaptcha } from "../../../../store/actions/index";

const initialValues = {
  email: "",
  password: undefined,
};

let validationSchema = {}

function Login(props) {
  
  const adminLoginStatus = props?.recaptcha?.adminLoginStatus;

  useEffect(() => {
		if(adminLoginStatus)
		{
			loadCaptchaEnginge(6, "#dcebff");
		}
	}, [adminLoginStatus]);

  if(adminLoginStatus)
  {
    initialValues.user_captcha_input = "";
    validationSchema = Yup.object().shape({
      email: Yup.string().required("Email is required"),
      password: Yup.string().required("Password is required"),
      user_captcha_input: Yup.string().required("Captcha is required"),
    });
  }
  else
  {
    validationSchema = Yup.object().shape({
      email: Yup.string().required("Email is required"),
      password: Yup.string().required("Password is required"),
    });
  }

  useEffect(() => {
    props.onGetPublicRecaptcha();
  }, []);

  const [showingAlert, setShowingAlert] = useState(false);

  const getInputClasses = (meta, fieldname) => {
    let result = "form-control form-control-solid h-auto py-5 px-6 ";
    if (meta.touched && meta.error) {
      result += " is-invalid";
    }

    if (meta.touched && !meta.error) {
      result += " is-valid";
    }

    return result;
  };
  let errorMessage = "";
  let authRedirect = "";
  if (props.error) {
    errorMessage = (
      <Alert variant="danger" onClose={() => setShowingAlert(false)} dismissible>
        {props.error}
      </Alert>
    );
  }

  return (
    
    <div className="login-form login-signin">
      <ToastContainer />
      {/* begin::Head */}
      {authRedirect}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">Enter your email and password</p>
      </div>
      {errorMessage}

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(fields) => {
          if(adminLoginStatus)
          {
            let user_captcha_value = fields.user_captcha_input;
            if (validateCaptcha(user_captcha_value) === true) {
              props.onAuth(fields.email, fields.password);
              window.scrollTo({ top: 0, behavior: "smooth" });
              loadCaptchaEnginge(6);
            }
            else
            {
              toast.error("Please Enter Valid Captcha", {
                toastifyConstant,
                toastId: "error"
              });
            }
          }
          else
          {
            props.onAuth(fields.email, fields.password);
          }
        }}
      >
        {({ isValid, dirty }) => (
          <Form className="form">
            <FormGroup>
              <Field name="email" component={Input} placeholder="Email" label="Email">
                {({ field, form, meta }) => (
                  <div>
                    <input type="email" {...field} className={`${getInputClasses(meta)}`} id="loginEmail" placeholder="Enter Email" />
                    {meta.touched && meta.error && <div className="error invalid-feedback">{meta.error}</div>}
                  </div>
                )}
              </Field>
            </FormGroup>

            <FormGroup>
              <Field name="password" component={Input} placeholder="Password" label="Password">
                {({ field, form, meta }) => (
                  <div>
                    <input maxLength="20" type="password" {...field} className={`${getInputClasses(meta)}`} id="loginPassword" placeholder="Enter Password" />
                    {meta.touched && meta.error && <div className="error invalid-feedback">{meta.error}</div>}
                  </div>
                )}
              </Field>
            </FormGroup>
            {adminLoginStatus
              ? 
                <FormGroup>
                  <LoadCanvasTemplateNoReload />
                  <Field name="user_captcha_input" id="user_captcha_input" component={Input} placeholder="Enter captcha" label="Captcha">
                  {({ field, form, meta }) => (
                    <div>
                      <input maxLength="20" type="text" {...field} className={`${getInputClasses(meta)}`} placeholder="Enter Captcha" />
                      {meta.touched && meta.error && <div className="error invalid-feedback">{meta.error}</div>}
                    </div>
                  )}
                  </Field>
                </FormGroup>
              : null
            }
            {/* begin::Actions */}
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
              <Link to="/auth/forgot-password" className="text-dark-50 text-hover-primary my-3 mr-2" id="loginForgotPassword">
                <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
              </Link>
              
              <button id="loginSubmit" type="submit" disabled={!isValid} className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}>
                  {"Sign In"}
              </button>
            </div>
            {/* end::Actions */}
          </Form>
        )}
      </Formik>
      {/*end::Form*/}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthorized: state.auth.token,
    recaptcha: state.recaptcha.publicRecaptcha,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password) => dispatch(actions.auth(email, password)),
    onGetPublicRecaptcha: () => dispatch(getPublicRecaptcha()),
  };
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Login);
