import * as actionTypes from "./actionTypes";

export const exportCSVStart = () => {
  return {
    type: actionTypes.EXPORT_CSV_START,
  };
};

export const exportCSVFail = (error) => {
  return {
    type: actionTypes.EXPORT_CSV_COMMON_ERROR,
    error,
  };
};
export const getFrontUserCSVList = () => {
  return {
    type: actionTypes.EXPORT_CSV_FRONT_USERS,
  };
};


export const getFrontUserCSVListSuccess = (response) => {
  return {
    type: actionTypes.EXPORT_CSV_FRONT_USERS_SUCCESS,
    response,
  };
};

export const getRePaymentDistributionCSVList = (id) => {
  return {
    type: actionTypes.GET_REPAYMENT_DISTRIBUTION_CSV_LIST,
    id
  };
};


export const getRePaymentDistributionListSuccess = (response) => {
  return {
    type: actionTypes.GET_REPAYMENT_DISTRIBUTION_CSV_LIST_SUCCESS,
    response,
  };
};

export const getRePaymentReceivedCSVList = (id) => {
  return {
    type: actionTypes.GET_REPAYMENT_RECEIVED_CSV_LIST,
    id
  };
};


export const getRePaymentReceivedCSVListSuccess = (response) => {
  return {
    type: actionTypes.GET_REPAYMENT_RECEIVED_CSV_LIST_SUCCESS,
    response,
  };
};

export const getDistributionViewCSVList = (id) => {
  return {
    type: actionTypes.GET_DISTRIBUTION_VIEW_CSV_LIST,
    id
  };
};


export const getDistributionViewCSVListSuccess = (response) => {
  return {
    type: actionTypes.GET_DISTRIBUTION_VIEW_CSV_LIST_SUCCESS,
    response,
  };
};