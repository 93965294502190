import { put, call } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import { INVESTORS_API } from "../../actions/apiCollections";
import {
  getInvestorsStart,
  getInvestorsFail,
  getInvestorsSuccess,
  getInvestorSuccess,
} from "../../actions/accreditation/accreditationActions";

export function* getAccreditionInvestorsSaga(action) {
  yield put(getInvestorsStart());
  try {
    const response = yield axios.get(INVESTORS_API + "admin", {
      params: {
        ...(action?.search?.search !== "" && {
          search: action?.search?.search
        }),
        ...(action?.search?.accredited !== "" && {
          accredited: action?.search?.accredited
        }),
        limit: 10 * action.pageLimit
      },
    });
    yield put(getInvestorsSuccess(response.data.data.data));
  } catch (error) {
    yield put(getInvestorsFail(error));
  }
}

export function* getAccreditionInvestorSaga(action) {
  try {
    const response = yield axios.get(INVESTORS_API + action.id + "/admin");
    yield put(getInvestorSuccess(response.data.data.data));
  } catch (error) {
    yield put(getInvestorsFail(error));
  }
}

export function* accreditationActionSaga(action) {
  try {
    yield axios.patch(
      INVESTORS_API + action.accData.id + "/admin",
      action.accData
    );
    yield call(getAccreditionInvestorsSaga);
  } catch (error) {
    yield put(getInvestorsFail(error));
  }
}
