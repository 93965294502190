import { TAXONOMY_API } from "../../actions/apiCollections";
import axios from "../../../config/AxiosConfig";
import { put } from "redux-saga/effects";
import { taxonomyStart, taxonomyFail, getTaxomonySuccess, createTaxomonySuccess, updateTaxomonySuccess } from "../../actions/taxonomy/taxonomyActions";

export function* getTaxomony(action) {
  yield put(taxonomyStart());
  try {
    const lang = action.language ? `?language=${action.language}` : "";
    const response = yield axios.get(TAXONOMY_API + lang);
    yield put(getTaxomonySuccess(response.data.data.data));
  } catch (error) {
    yield put(taxonomyFail(error));
  }
}

export function* createTaxomony(action) {
  yield put(taxonomyStart());
  try {
    const response = yield axios.post(TAXONOMY_API, action.taxonomy);
    yield put(createTaxomonySuccess(response.data.data.data));
  } catch (error) {
    yield put(taxonomyFail(error));
  }
}

export function* updateTaxomony(action) {
  yield put(taxonomyStart());
  try {
    const response = yield axios.patch(TAXONOMY_API + action.taxonomy.id, action.taxonomy);
    yield put(updateTaxomonySuccess(response.data.data.data));
  } catch (error) {
    yield put(taxonomyFail(error));
  }
}
