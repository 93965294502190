import { ROLE_API, ROLE_BULK_DELETE, ROLE_BULK_UPDATE, PERMISSIONS_API } from "../../actions/apiCollections";
import axios from "../../../config/AxiosConfig";
import { roleStart, roleFail, getRoleSuccess, getRolesSuccess, createRoleSuccess, updateRoleSuccess, bulkUpdateRoleSuccess, getPermissionSuccess } from "../../actions/roleManagement/roleActions";
import { call, put } from "redux-saga/effects";

export function* getRole(action) {
  yield put(roleStart());
  try {
    const response = yield axios.get(ROLE_API + action.id);
    yield put(getRoleSuccess(response.data.data.data));
  } catch (error) {
    yield put(roleFail(error));
  }
}

export function* getRoles() {
  yield put(roleStart());
  try {
    const response = yield axios.get(ROLE_API);
    yield put(getRolesSuccess(response.data.data.data));
  } catch (error) {
    yield put(roleFail(error));
  }
}

export function* createRole(action) {
  yield put(roleStart());
  try {
    const response = yield axios.post(ROLE_API, action.role);
    yield put(createRoleSuccess(response.data.data.data));
  } catch (error) {
    yield put(roleFail(error));
  }
}

export function* updateRole(action) {
  yield put(roleStart());
  try {
    const response = yield axios.patch(ROLE_API + action.role.id, action.role);
    yield put(updateRoleSuccess(response.data.data.data));
  } catch (error) {
    yield put(roleFail(error));
  }
}

export function* deletedRole(action) {
  yield put(roleStart());
  try {
    yield axios.delete(ROLE_API + action.id);
    yield call(getRoles);
  } catch (error) {
    yield put(roleFail(error));
  }
}

export function* bulkUpdateRoleSaga(action) {
  yield put(roleStart());
  try {
    const response = yield axios.post(ROLE_BULK_UPDATE, action.role);
    yield put(bulkUpdateRoleSuccess(response.data.data.data));
    yield call(getRoles);
  } catch (error) {
    yield put(roleFail(error));
  }
}
export function* bulkDeleteRoleSaga(action) {
  yield put(roleStart());
  try {
    yield axios.post(ROLE_BULK_DELETE, action.role);
    yield call(getRoles);
  } catch (error) {
    yield put(roleFail(error));
  }
}

export function* getPermissionSaga(action) {
  yield put(roleStart());
  try {
    const response = yield axios.get(PERMISSIONS_API + action.id);
    yield put(getPermissionSuccess(response.data.data.data.permissions));
  } catch (error) {
    yield put(roleFail(error));
  }
}
