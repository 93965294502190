import { put } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import { activityFail, activityStart, getActivitiesSuccess } from "../../actions";
import { ACTIVITY_API } from "../../actions/apiCollections";

export function* getActivities(action) {
  yield put(activityStart());
  try {
    const response = yield axios.get(ACTIVITY_API, { params: { limit: action.limit * 10, page: action.page } });
    yield put(getActivitiesSuccess(response.data.data.data));
  } catch (error) {
    yield put(activityFail(error));
  }
}
