import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import WalletTransactions from "./WalletTransactions/WalletTransactions";
import TopupRequests from "./TopupRequests/TopupRequests";
import WithdrawRequests from "./WithdrawRequests/WithdrawRequests";

export default function WalletRouter() {
  return (
    <Switch>
      <Redirect exact={true} from="/wallet" to="/wallet/wallet-transactions" />

      <ContentRoute from="/wallet/wallet-transactions" component={WalletTransactions} />

      <ContentRoute from="/wallet/topup-requests" component={TopupRequests} />

      <ContentRoute from="/wallet/withdraw-requests" component={WithdrawRequests} />

    </Switch>
  );
}
