import * as actionTypes from "../../actions/meta/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  meta: null,
  error: null,
  isCreated: false,
  isUpdated: false,
};

export const metaStart = (state, action) => {
  return updateObject(state, {
    meta: null,
    error: null,
    isCreated: false,
    isUpdated: false,
  });
};

export const metaFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

export const getMeta = (state, action) => {
  return updateObject(state, {
    meta: action.response,
  });
};

export const createMeta = (state, action) => {
  return updateObject(state, {
    isCreated: true,
  });
};

export const updateMeta = (state, action) => {
  return updateObject(state, {
    isUpdated: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.META_START:
      return metaStart(state, action);
    case actionTypes.META_FAIL:
      return metaFail(state, action);
    case actionTypes.GET_META_SUCCESS:
      return getMeta(state, action);
    case actionTypes.CREATE_META_SUCCESS:
      return createMeta(state, action);
    case actionTypes.UPDATE_META_SUCCESS:
      return updateMeta(state, action);
    default:
      return state;
  }
};

export default reducer;
