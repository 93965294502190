import * as actionTypes from "./actionTypes";

export const dashboardStart = () => {
  return {
    type: actionTypes.DASHBOARD_START,
  };
};

export const dashboardFail = (error) => {
  return {
    type: actionTypes.DASHBOARD_FAIL,
    error,
  };
};

export const getDashboardCount = () => {
  return {
    type: actionTypes.GET_DASHBOARD_COUNT,
  };
};

export const getDashboardCountSuccess = (response) => {
  return {
    type: actionTypes.GET_DASHBOARD_COUNT_SUCCESS,
    response,
  };
};

export const getDashboardPendingCampaigns = (id) => {
  return {
    type: actionTypes.GET_DASHBOARD_PENDING_CAMPAIGNS,
    id,
  };
};

export const getDashboardPendingCampaignsSuccess = (response) => {
  return {
    type: actionTypes.GET_DASHBOARD_PENDING_CAMPAIGNS_SUCCESS,
    response,
  };
};

export const getDashboardCampaignsRevenues = () => {
  return {
    type: actionTypes.GET_DASHBOARD_CAMPAIGNS_REVENUES,
  };
};

export const getDashboardCampaignsRevenuesSuccess = (response) => {
  return {
    type: actionTypes.GET_DASHBOARD_CAMPAIGNS_REVENUES_SUCCESS,
    response,
  };
};

export const getDashboardCampaignTotalInvestment = () => {
  return {
    type: actionTypes.GET_DASHBOARD_CAMPAIGN_TOTAL_INVESTMENT,
  };
};

export const getDashboardCampaignTotalInvestmentSuccess = (response) => {
  return {
    type: actionTypes.GET_DASHBOARD_CAMPAIGN_TOTAL_INVESTMENT_SUCCESS,
    response,
  };
};

export const getDashboardCampaignTotalRepayment = () => {
  return {
    type: actionTypes.GET_DASHBOARD_CAMPAIGN_TOTAL_REPAYMENT,
  };
};

export const getDashboardCampaignTotalRepaymentSuccess = (response) => {
  return {
    type: actionTypes.GET_DASHBOARD_CAMPAIGN_TOTAL_REPAYMENT_SUCCESS,
    response,
  };
};
