/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../../layout";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardCount,
  getDashboardCampaignsRevenues,
  getDashboardCampaignTotalInvestment,
  getDashboardCampaignTotalRepayment,
} from "../../../../store/actions/dashboard/dashboardActions";
import { formatCurrency } from "../../../../helpers/numberFormat";

export function MixedWidget1({ className }) {
  const uiService = useHtmlClassService();
  const dispatch = useDispatch();
  const [monthsRevenues, setMonthsRevenues] = useState([]);
  const [monthlyRevenuesTotal, setMonthlyRevenuesTotal] = useState([]);

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseDanger: objectPath.get(
        uiService.config,
        "js.colors.theme.base.danger"
      ),
      colorsThemeBasePrimary: objectPath.get(
        uiService.config,
        "js.colors.theme.base.primary"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    dispatch(getDashboardCount());
    dispatch(getDashboardCampaignsRevenues());
    dispatch(getDashboardCampaignTotalInvestment());
    dispatch(getDashboardCampaignTotalRepayment());
  }, [dispatch]);

  const {
    dashboardCount,
    totalCampaignRevenues,
    totalCampaignInvestments,
    totalCampaignRePayment,
  } = useSelector((state) => state.dashboard);
  const { generalSettings } = useSelector((state) => state.settings);

  useLayoutEffect(() => {
    if (totalCampaignRevenues) {
      const monthsCampaignRevenues = totalCampaignRevenues?.revenueChartData?.map(
        function(item) {
          return item["_id"];
        }
      );
      setMonthsRevenues(monthsCampaignRevenues);

      const campaignRevenuesTotal = totalCampaignRevenues?.revenueChartData?.map(
        function(item) {
          return item["totalEarnedFee"];
        }
      );
      setMonthlyRevenuesTotal(campaignRevenuesTotal);

      if (campaignRevenuesTotal?.length > 0) {
        const element = document.getElementById("kt_mixed_widget_1_chart");
        if (!element) {
          return;
        }

        const options = getChartOptions(
          layoutProps,
          monthsCampaignRevenues,
          campaignRevenuesTotal
        );

        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
          chart.destroy();
        };
      }
    }
  }, [totalCampaignRevenues, layoutProps]);

  return (
    <div className={`card card-custom bg-gray-100 ${className}`}>
      {/* Header */}
      <div className="card-header border-0 bg-primary py-5">
        <h3 className="card-title font-weight-bolder text-white">Revenue</h3>
      </div>
      {/* Body */}
      <div className="card-body p-0 position-relative overflow-hidden">
        {/* Chart */}
        <div
          id="kt_mixed_widget_1_chart"
          className="card-rounded-bottom bg-primary"
          style={{ height: "200px" }}
        ></div>

        {/* Stat */}
        <div className="card-spacer mt-n25">
          <div className="row m-0">
            <div className="col bg-light-warning p-8 rounded-xl mr-7 mb-7">
              <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Layout/Layout-4-blocks.svg"
                  )}
                ></SVG>
              </span>
              <div className="text-warning font-weight-bolder font-size-h2 mt-3">
                {dashboardCount.activeCampaign}
              </div>
              <Link
                to="/campaign/campaigns"
                className="text-warning font-weight-bold font-size-h6"
              >
                Active Campaigns
              </Link>
            </div>
            <div className="col bg-light-primary p-8 rounded-xl mb-7">
              <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Add-user.svg"
                  )}
                ></SVG>
              </span>
              <div className="text-primary font-weight-bolder font-size-h2 mt-3">
                {dashboardCount.newUsers}
              </div>
              <Link
                to="/user-management/front-end-users"
                className="text-primary font-weight-bold font-size-h6 mt-2"
              >
                New Users
              </Link>
            </div>
          </div>
          <div className="row m-0">
            <div className="col bg-light-danger px-6 py-8 rounded-xl mr-7">
              <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}
                ></SVG>
              </span>
              <div className="text-danger font-weight-bolder font-size-h2 mt-3">
                {formatCurrency(
                  totalCampaignInvestments?.totalInvestment ?? 0,
                  generalSettings?.currencySymbolSide,
                  generalSettings?.currencyId?.symbol,
                  generalSettings?.currencyId?.code,
                  generalSettings?.decimalPoints
                )}
              </div>
              <Link
                to={"./transaction"}
                className="text-danger font-weight-bold font-size-h6 mt-2"
              >
                Investment
              </Link>
            </div>
            <div className="col bg-light-success px-6 py-8 rounded-xl">
              <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")}
                ></SVG>
              </span>
              <div className="text-success font-weight-bolder font-size-h2 mt-3">
                {formatCurrency(
                  totalCampaignRePayment?.totalRepayment ?? 0,
                  generalSettings?.currencySymbolSide,
                  generalSettings?.currencyId?.symbol,
                  generalSettings?.currencyId?.code,
                  generalSettings?.decimalPoints
                )}
              </div>
              <p
                className="text-success font-weight-bold font-size-h6 mt-2"
              >
                Re-Payment
              </p>
            </div>
          </div>
        </div>

        {/* Resize */}
        <div className="resize-triggers">
          <div className="expand-trigger">
            <div style={{ width: "411px", height: "461px" }} />
          </div>
          <div className="contract-trigger" />
        </div>
      </div>
    </div>
  );
}

function getChartOptions(layoutProps, monthsRevenues, monthlyRevenuesTotal) {
  const strokeColor = "#4031a2";
  const options = {
    series: [
      {
        name: "Revenue",
        data: monthlyRevenuesTotal,
      },
    ],
    chart: {
      type: "area",
      height: 200,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: 0,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [strokeColor],
    },
    xaxis: {
      categories: monthsRevenues,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: "layoutProps.colorsGrayGray500",
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3,
        },
      },
    },
    yaxis: {
      //min: 0,
      // max: 80,
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
      y: {
        formatter: function(val) {
          return "$" + val.toLocaleString();
        },
      },
      marker: {
        show: false,
      },
    },
    colors: ["transparent"],
    markers: {
      colors: layoutProps.colorsThemeBasePrimary,
      strokeColor: [strokeColor],
      strokeWidth: 3,
    },
  };
  return options;
}
