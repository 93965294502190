/* eslint-disable no-unused-vars */
import { SUBSCRIBERS_API } from "../../actions/apiCollections";
import axios from "../../../config/AxiosConfig";
import { put, call } from "redux-saga/effects";
import * as actions from "../../actions/newsletters/newslettersActions";

export function* getNewsletters(action) {
  yield put(actions.newslettersStart());
  try {
    const response = yield axios.get(SUBSCRIBERS_API, {
      params: { limit: 10 * action.response },
    });
    yield put(actions.getNewslettersSuccess(response.data.data.data));
  } catch (error) {
    yield put(actions.newsletterFail(error));
  }
}

export function* getNewsletter(action) {
  yield put(actions.newslettersStart());
  try {
    const response = yield axios.get(SUBSCRIBERS_API + action.campaignId);
    yield put(actions.getNewsletterSuccess(response.data.data.data));
  } catch (error) {
    yield put(actions.newsletterFail(error));
  }
}


export function* deleteNewsletterSaga(action) {
  yield put(actions.newslettersStart());
  try {
    yield axios.delete(SUBSCRIBERS_API + action.response);
    yield call(getNewsletters,{response:1});
  } catch (error) {
    yield put(actions.newsletterFail(error));
  }
}

export function* getSearchNewslettersSaga(action) {
  yield put(actions.newslettersStart());
  try {
    const response = yield axios.get(SUBSCRIBERS_API, {
      params: {
        ...(action.response.search !== "" && {
          search: action.response.search,
        }),
        ...(action.response.subscriptionType !== "" && {
          subscriptionType: action.response.subscriptionType,
        }),
        ...(action.limitNo !== "" && {
          limit: 10 * action.limitNo,
        }),
      },
    });
    yield put(actions.getNewslettersSuccess(response.data.data.data));
  } catch (error) {
    yield put(actions.newsletterFail(error));
  }
  
}