import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

/**
 * @description An initial Object, to re-use in the upcoming reducers
 */
const initialState = {
  categories: [],
  loading: false,
  error: [],
  message: null,
  categoryId: null,
  languages: [],
  language: "",
  bulk: [],
  isCreated: false
};

/**
 * @description : An initial step which is common and re-useable in other reducers, before reducer to that action. Initially there will be no action as this is a start step.
 * @param {object} state
 * @param {object} action
 * @returns {object} : `Object`
 */
const commonCategoriesStart = (state, action) => {
  return updateObject(state, {
    error: null,
    message: null,
    categoryId: null,
  });
};

/**
 * @description : This reducer stores error(s) which are received from the Saga.
 * @override `error`
 * @param {object} state
 * @param {object} action
 * @returns {object} : `Object`
 */

const commonCategoriesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: true,
  });
};

/**
 * @description : This reducer stores an `Array` of `Object` which have been received from the Saga
 * @param {object} state
 * @param {object} action
 * @returns {object} : `Object`
 */

const getAllCategoriesSuccess = (state, action) => {
  return updateObject(state, {
    categories: action.response,
    loading: false,
    isCreated: false
  });
};

/**
 * @description : In this reducer we store success message received from API Call after creating a single Category
 * @param {object} state
 * @param {object} action
 * @returns {object} `Object`
 */

const createCategorySuccess = (state, action) => {
  return updateObject(state, {
    message: action.response,
    loading: false,
    isCreated: true
  });
};

/**
 * @description : In this reducer we store `Object` received from API Call after Saga API Call
 * @param {object} state
 * @param {object} action
 * @returns {object} `Object`
 */
const getCategorySuccess = (state, action) => {
  return updateObject(state, {
    categoryId: action.response,
    loading: false,
  });
};

const getLanguagesSuccess = (state, action) => {
  return updateObject(state, {
    languages: action.response,
    loading: false,
  });
};

const getCurrentLanguage = (state, action) => {
  return updateObject(state, {
    language: action.response,
  });
};

const getBulkUpdate = (state, action) => {
  return updateObject(state, {
    bulk: action.response,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CATEGORIES_COMMON_START:
      return commonCategoriesStart(state, action);
    case actionTypes.CATEGORIES_COMMON_FAIL:
      return commonCategoriesFail(state, action);
    case actionTypes.GET_CATEGORIES_SUCCESS:
      return getAllCategoriesSuccess(state, action);
    case actionTypes.CREATE_CATEGORY_SUCCESS:
    case actionTypes.UPDATE_CATEGORY_SUCCESS:
      return createCategorySuccess(state, action);
    case actionTypes.GET_CATEGORY_SUCCESS:
      return getCategorySuccess(state, action);
    case actionTypes.GET_LANGUAGES_SUCCESS:
      return getLanguagesSuccess(state, action);
    case actionTypes.BULK_UPDATE_CATEGORIES_SUCCESS:
      return getBulkUpdate(state, action);
    case actionTypes.GET_LANGUAGE:
      return getCurrentLanguage(state, action);
    default:
      return state;
  }
};

export default reducer;
