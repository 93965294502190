export const TRANSACTION_START = "TRANSACTION_START";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TRANSACTION = "GET_TRANSACTION";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const TRANSACTION_FAIL = "TRANSACTION_FAIL";
export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION";
export const UPDATE_TRANSACTION_SUCCESS = "UPDATE_TRANSACTION_SUCCESS";
export const GET_SEARCH_TRANSACTIONS = "GET_SEARCH_TRANSACTIONS";
export const GET_ACTIVE_CAMPAIGN_LIST = "GET_ACTIVE_CAMPAIGN_LIST";
export const GET_ACTIVE_CAMPAIGN_LIST_SUCCESS = "GET_ACTIVE_CAMPAIGN_LIST_SUCCESS";
export const GET_INVESTOR_LIST = "GET_INVESTOR_LIST";
export const GET_INVESTOR_LIST_SUCCESS = "GET_INVESTOR_LIST_SUCCESS";
export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const ADD_TRANSACTION_SUCCESS = "ADD_TRANSACTION_SUCCESS";

