import { takeEvery } from "redux-saga/effects";

import * as actionType from "../actions/actionTypes";
import * as apiAction from "../actions/apiManagement/actionTypes";
import * as languageAction from "../actions/languageSelector/actionTypes";
import * as revenueAction from "../actions/revenue/actionTypes";
import * as roleAction from "../actions/roleManagement/actionTypes";
import * as metaAction from "../actions/meta/actionTypes";
import * as taxonomyAction from "../actions/taxonomy/actionTypes";
import * as spamAction from "../actions/spamSettings/actionTypes";
import * as dashboardAction from "../actions/dashboard/actionTypes";
import * as campaignAction from "../actions/campaigns/actionTypes";
import * as accreditationAction from "../actions/accreditation/actiontTypes";
import * as activityAction from "../actions/activity/actionTypes";
import * as emailAction from "../actions/emailSettings/actionTypes";
import * as cronAction from "../actions/cron/actionTypes";
import * as transactionAction from "../actions/transactions/actionTypes";
import * as newsletterAction from "../actions/newsletters/actionTypes";
import * as exportCSVAction from "../actions/exportCsv/actionTypes";
import * as walletAction from "../actions/wallet/actionTypes";

import {
  logoutSaga,
  checkAuthTimeoutSaga,
  authLoginSaga,
  authCheckStateSaga,
  loginAsUserSaga,
  loginAsAdminUserSaga,
} from "./authSaga";
import {
  getAllAdminsSaga,
  getAllAdminRolesSaga,
  createNewAdminSaga,
  getAdminByIdSaga,
  updateAdminByIdSaga,
  deleteAdminByIdSaga,
  getCurrentAdmin,
  bulkUpdate,
  bulkDelete,
  changePasswordSaga
} from "./adminSaga";
import {
  getAllCategoriesSaga,
  createCategorySaga,
  getCategoryByIdSaga,
  updateCategoryByIdSaga,
  deleteCategoryByIdSaga,
  getLanguagesSuccessSaga,
  categoriesBulkUpdateSaga,
  categoriesBulkDelete,
} from "./categorySaga";
import {
  getAllCompanyIndustrySaga,
  createCompanyIndustrySaga,
  deleteCompanyIndustrySaga,
  getCompanyIndustryByIdSaga,
  updateCompanyIndustrySaga,
  getAllInvestingSourceListSaga,
  deleteInvestingSourceSaga,
  createInvestingSourceSaga,
  getInvestingSourceByIdSaga,
  updateInvestingSourceSaga,
  getAllInvestingTypeSaga,
  deleteInvestingTypeSaga,
  createInvestingTypeSaga,
  getInvestingTypeSaga,
  updateInvestingTypeSaga,
  getDocumentListSaga,
  deleteDocumentSaga,
  createDocumentTypeSaga,
  getDocumentTypeSaga,
  updateDocumentTypeSaga,
  getAllAccountTypeSaga,
  deleteAccountTypeSaga,
  createAccountTypeSaga,
  getManageOptionsLanguagesSaga,
  setManageOptionsCurrentIdSaga,
  updateAccountTypeSaga,
  getAccountTypeByIdSaga,
  getAllTeamMemberTypeSaga,
  createTeamMemberTypeSaga,
  deleteTeamMemberSaga,
  getTeamMemberTypeSaga,
  updateTeamMemberType,
  getAllInvestorTypeSaga,
  createInvestorTypeSaga,
  deleteInvestorTypeSaga,
  getInvestorTypeSaga,
  updateInvestorTypeSaga,
  getAllCampaignStageSaga,
  createCampaignStageSaga,
  deleteCampaignStageSaga,
  getCampaignSaga,
  updateCampaignStageSaga,
  getInvestingRoundListSaga,
  createInvestingRoundSaga,
  deleteInvestingRoundSaga,
  getInvestingRoundSaga,
  updateInvestingRoundSaga,
  getDealTypes,
  createDealType,
  deleteDealType,
  getDealType,
  updateDealType,
  getInvestorTaxList,
  deleteInvestorTax,
  getInvestorTax,
  updateInvestorTaxRelief,
  createInvestorTax,
} from "./manageOptionsSaga";
import {
  getFrontEndUsersSaga,
  deleteFrontEndUserSaga,
  createFrontEndUserSaga,
  getFrontEndUserSaga,
  updateFrontEndUserSaga,
  batchUpdateFrontEndUserSaga,
  batchDeleteFrontEndUserSaga,
  userTypeSaga,
} from "./frontEndUsersSaga";
import {
  getCountriesSaga,
  getLanguageSaga,
  createCountrySaga,
  getCountrySaga,
  updateCountrySaga,
  deleteCountrySaga,
} from "./countriesSaga";
import {
  getSiteSettingsSaga,
  getFrontSiteSettingsSaga,
  createSiteSettingsSaga,
  updateSiteSettingsSaga,
  getCampaignSettings,
  createCampaignSettingsSaga,
  updateCampaignSettingsSaga,
  getAllUsersContactEnquirySaga,
  getNewsletterSettingsSaga,
  updateNewsletterSettingsSaga
} from "./settingsSaga";
import {
  getCurrenciesSaga,
  createCurrencySaga,
  deleteCurrencySaga,
  getCurrencySaga,
  updateCurrencySaga,
  getMiscLanguagesSaga,
  createMiscLanguageSaga,
  deleteLanguageSaga,
  getMiscLanguageSaga,
  updateMiscLanguageSaga,
  getSlidersSaga,
  createSliderSaga,
  deleteSliderSaga,
  getSliderSaga,
  updateSliderSaga,
  getMenuSaga,
  getPageSaga,
  createMenuSaga,
  deleteMenuItemSaga,
  getMenuItem,
  updateMenuItem,
  bulkUpdateCurrency,
  bulkDeleteCurrency,
  bulkSliderDeleteSaga,
  bulkSliderUpdateSaga,
  setDefaultLanguageSaga,
  getsuccessStoriesSaga,
  getSuccessStorySaga,
  createSuccessStoriesSaga,
} from "./miscSaga";
import {
  getFAQs,
  createFAQSaga,
  deleteFAQSaga,
  getFAQSaga,
  updateFAQSaga,
  getFooterFAQs,
  createFooterFAQSaga,
  deleteFooterFAQSaga,
  getFooterFAQSaga,
  updateFooterFAQSaga,
  getInvestorContractsSaga,
  getInvestorContractByIdSaga,
  updateInvestorContractSaga,
  getPages,
  createPageSaga,
  deletePageSaga,
  getPage,
  updatePage,
  getHelpCategoriesSaga,
  getHelpCategory,
  createHelpCategory,
  deleteHelpCategory,
  updateHelpCategory,
  getHelpCenters,
  deleteHelpCenter,
  createHelpCenter,
  getHelpCenter,
  updateHelpCenter,
} from "./contentPagesSaga";
import {
  getPaymentGatewaySaga,
  createPaymentGatewaySaga,
  updatePaymentGatewaySaga,
} from "./apiManagement/paymentGatewaySaga";
import {
  getRecaptchaSaga,
  createRecaptchaSaga,
  updateRecaptchaSaga,
  getPublicRecaptchaSaga,
} from "./apiManagement/recaptchaSaga";
import {
  getSocialSaga,
  createSocialSaga,
  updateSocialSaga,
} from "./apiManagement/socialSignupSaga";
import { getLanguage } from "./languageSelector/languageSelectorSaga";
import {
  getRevenue,
  updateRevenue,
  createRevenue,
} from "./revenue/revenueSaga";
import {
  getRole,
  getRoles,
  createRole,
  updateRole,
  deletedRole,
  bulkDeleteRoleSaga,
  bulkUpdateRoleSaga,
  getPermissionSaga,
} from "./roleManagement/roleManagementSaga";
import { getMeta, createMeta, updateMeta } from "./meta/metaSaga";
import {
  getTaxomony,
  createTaxomony,
  updateTaxomony,
} from "./taxonomy/taxonomySaga";
import {
  getSpamSettingSaga,
  createSpamSettingSaga,
  updateSpamSettingSaga,
  getSpammersSaga,
  createSpammerSaga,
  getSpammerSaga,
} from "./spamSetting/spamSettingSaga";
import {
  getDashboardCountSaga,
  getDashboardPendingCampaignsSaga,
  getDashboardCampaignsRevenuesSaga,
  getDashboardCampaignTotalInvestmentSaga,
  getDashboardCampaignTotalRepaymentSaga,
} from "./dashboard/dashboardSaga";
import * as campaignSaga from "./campaigns/campaignsSaga";
import {
  getAccreditionInvestorsSaga,
  getAccreditionInvestorSaga,
  accreditationActionSaga,
} from "./accredition/accreditionSaga";
import { getActivities } from "./activity/activitySaga";
import {
  getEmailTemplatesSaga,
  getEmailTemplateSaga,
  createEmailTemplateSaga,
  deleteEmailTemplateSaga,
  sendTestEmailSaga,
  updateEmailTemplateSaga,
  updateMasterEmailSaga,
  bulkUpdateEmailTemplateSaga,
} from "./emailSettings/emailSettingsSaga";

import { getCronJobsSaga, createCronJobSaga } from "./cron/cronSaga";

import {
  getTransactions,
  getTransaction,
  updateTransactionSaga,
  getSearchTransactionsSaga,
  getActiveCamapignListSaga,
  getInvestorListSaga,
  addTransactionSaga
} from "./transactions/transactionsSaga";

import {
  getNewsletters,
  getNewsletter,
  deleteNewsletterSaga,
  getSearchNewslettersSaga,
} from "./newsletters/newslettersSaga";

import {
  getFrontUserCSVList,
  getRePaymentDistributionCSVListSaga,
  getDistributionViewCSVListSaga,
  getRePaymentReceivedCSVListSaga
} from "./exportCsv/exportCsvSaga";

import {
  getWalletTransactionsSaga,
  updateWalletTransactionSaga
} from "./wallet/walletSaga";

export function* watchAuth() {
  yield takeEvery(actionType.AUTH_INITIATE_LOGOUT, logoutSaga);
  yield takeEvery(actionType.AUTH_CHECK_TIMEOUT, checkAuthTimeoutSaga);
  yield takeEvery(actionType.AUTH_USER_LOGIN, authLoginSaga);
  yield takeEvery(actionType.AUTH_CHECK_STATE, authCheckStateSaga);
  yield takeEvery(actionType.AUTH_FRONT_END, loginAsUserSaga);
  yield takeEvery(actionType.AUTH_ADMIN_FRONT_END, loginAsAdminUserSaga);
}

export function* watchAdmins() {
  yield takeEvery(actionType.GET_ADMINS, getAllAdminsSaga);
  yield takeEvery(actionType.GET_ADMINS_ROLES, getAllAdminRolesSaga);
  yield takeEvery(actionType.CREATE_NEW_ADMIN, createNewAdminSaga);
  yield takeEvery(actionType.GET_ADMIN_BY_ID, getAdminByIdSaga);
  yield takeEvery(actionType.UPDATE_ADMIN_BY_ID, updateAdminByIdSaga);
  yield takeEvery(actionType.DELETE_ADMIN, deleteAdminByIdSaga);
  yield takeEvery(actionType.GET_CURRENT_ADMIN, getCurrentAdmin);
  yield takeEvery(actionType.BATCH_ADMIN_UPDATE, bulkUpdate);
  yield takeEvery(actionType.BATCH_ADMIN_DELETE, bulkDelete);
  yield takeEvery(actionType.CHANGE_PASSWORD, changePasswordSaga);
}

export function* watchCategory() {
  yield takeEvery(actionType.GET_CATEGORIES, getAllCategoriesSaga);
  yield takeEvery(actionType.CREATE_CATEGORY, createCategorySaga);
  yield takeEvery(actionType.GET_CATEGORY, getCategoryByIdSaga);
  yield takeEvery(actionType.UPDATE_CATEGORY, updateCategoryByIdSaga);
  yield takeEvery(actionType.DELETE_CATEGORY, deleteCategoryByIdSaga);
  yield takeEvery(actionType.GET_LANGUAGES, getLanguagesSuccessSaga);
  yield takeEvery(actionType.BULK_UPDATE_CATEGORIES, categoriesBulkUpdateSaga);
  yield takeEvery(actionType.BULK_DELETE_CATEGORIES, categoriesBulkDelete);
}

export function* watchManageOptions() {
  yield takeEvery(actionType.GET_DEAL_TYPES, getDealTypes);
  yield takeEvery(actionType.GET_COMPANY_INDUSTRY, getAllCompanyIndustrySaga);
  yield takeEvery(
    actionType.CREATE_COMPANY_INDUSTRY,
    createCompanyIndustrySaga
  );
  yield takeEvery(actionType.DELETE_COMPANY, deleteCompanyIndustrySaga);
  yield takeEvery(
    actionType.GET_COMPANY_INDUSTRY_BY_ID,
    getCompanyIndustryByIdSaga
  );
  yield takeEvery(
    actionType.UPDATE_COMPANY_INDUSTRY,
    updateCompanyIndustrySaga
  );
  yield takeEvery(
    actionType.GET_ALL_INVESTING_SOURCE_LIST,
    getAllInvestingSourceListSaga
  );
  yield takeEvery(
    actionType.DELETE_INVESTING_SOURCE,
    deleteInvestingSourceSaga
  );
  yield takeEvery(
    actionType.CREATE_INVESTING_SOURCE,
    createInvestingSourceSaga
  );
  yield takeEvery(actionType.GET_INVESTING_SOURCE, getInvestingSourceByIdSaga);
  yield takeEvery(
    actionType.UPDATE_INVESTING_SOURCE,
    updateInvestingSourceSaga
  );
  yield takeEvery(actionType.GET_ALL_INVESTING_TYPE, getAllInvestingTypeSaga);
  yield takeEvery(actionType.DELETE_INVESTING_TYPE, deleteInvestingTypeSaga);
  yield takeEvery(actionType.CREATE_INVESTING_TYPE, createInvestingTypeSaga);
  yield takeEvery(actionType.GET_INVESTING_TYPE, getInvestingTypeSaga);
  yield takeEvery(actionType.UPDATE_INVESTING_TYPE, updateInvestingTypeSaga);
  yield takeEvery(actionType.GET_ALL_DOCUMENT_TYPE, getDocumentListSaga);
  yield takeEvery(actionType.DELETE_DOCUMENT, deleteDocumentSaga);
  yield takeEvery(actionType.CREATE_DOCUMENT, createDocumentTypeSaga);
  yield takeEvery(actionType.GET_DOCUMENT_TYPE, getDocumentTypeSaga);
  yield takeEvery(actionType.UPDATE_DOCUMENT, updateDocumentTypeSaga);
  yield takeEvery(actionType.GET_ALL_ACCOUNT_TYPE, getAllAccountTypeSaga);
  yield takeEvery(actionType.DELETE_ACCOUNT_TYPE, deleteAccountTypeSaga);
  yield takeEvery(actionType.CREATE_ACCOUNT_TYPE, createAccountTypeSaga);
  yield takeEvery(
    actionType.GET_MANAGE_DROPDOWN_LANGUAGES,
    getManageOptionsLanguagesSaga
  );
  yield takeEvery(
    actionType.SET_MANAGE_DROPDOWN_LANG_ID,
    setManageOptionsCurrentIdSaga
  );
  yield takeEvery(actionType.UPDATE_ACCOUNT_TYPE, updateAccountTypeSaga);
  yield takeEvery(actionType.GET_ACCOUNT_TYPE, getAccountTypeByIdSaga);
  yield takeEvery(
    actionType.GET_ALL_TEAM_MEMBER_TYPE,
    getAllTeamMemberTypeSaga
  );
  yield takeEvery(actionType.CREATE_TEAM_MEMBER_TYPE, createTeamMemberTypeSaga);
  yield takeEvery(actionType.DELETE_TEAM_MEMBER_TYPE, deleteTeamMemberSaga);
  yield takeEvery(actionType.GET_TEAM_MEMBER_TYPE, getTeamMemberTypeSaga);
  yield takeEvery(actionType.UPDATE_TEAM_MEMBER_TYPE, updateTeamMemberType);
  yield takeEvery(actionType.GET_INVESTORT_TYPE_LIST, getAllInvestorTypeSaga);
  yield takeEvery(actionType.CREATE_INVESTOR_TYPE, createInvestorTypeSaga);
  yield takeEvery(actionType.DELETE_INVESTOR_TYPE, deleteInvestorTypeSaga);
  yield takeEvery(actionType.GET_INVESTOR_TYPE, getInvestorTypeSaga);
  yield takeEvery(actionType.UPDATE_INVESTOR_TYPE, updateInvestorTypeSaga);
  yield takeEvery(actionType.GET_CAMPAIGN_STAGE_LIST, getAllCampaignStageSaga);
  yield takeEvery(actionType.CREATE_CAMPAIGN_STAGE, createCampaignStageSaga);
  yield takeEvery(actionType.DELETE_CAMPAIGN_STAGE, deleteCampaignStageSaga);
  yield takeEvery(actionType.GET_CAMPAIGN_STAGE, getCampaignSaga);
  yield takeEvery(actionType.UPDATE_CAMPAIGN_STAGE, updateCampaignStageSaga);
  yield takeEvery(
    actionType.GET_INVESTING_ROUND_LIST,
    getInvestingRoundListSaga
  );
  yield takeEvery(actionType.CREATE_INVESTING_ROUND, createInvestingRoundSaga);
  yield takeEvery(actionType.DELETE_INVESTING_ROUND, deleteInvestingRoundSaga);
  yield takeEvery(actionType.GET_INVESTING_ROUND, getInvestingRoundSaga);
  yield takeEvery(actionType.UPDATE_INVESTING_ROUND, updateInvestingRoundSaga);
  yield takeEvery(actionType.CREATE_DEAL_TYPE, createDealType);
  yield takeEvery(actionType.DELETE_DEAL_TYPE, deleteDealType);
  yield takeEvery(actionType.GET_DEAL_TYPE, getDealType);
  yield takeEvery(actionType.UPDATE_DEAL_TYPE, updateDealType);
  yield takeEvery(actionType.GET_INVESTOR_TAX_LIST, getInvestorTaxList);
  yield takeEvery(actionType.DELETE_INVESTOR_TAX, deleteInvestorTax);
  yield takeEvery(actionType.GET_INVESTOR_TAX, getInvestorTax);
  yield takeEvery(actionType.UPDATE_INVESTOR_TAX, updateInvestorTaxRelief);
  yield takeEvery(actionType.CREATE_INVESTOR_TAX, createInvestorTax);
}

export function* watchFrontEndUsers() {
  yield takeEvery(actionType.GET_ALL_FRONT_END_USERS, getFrontEndUsersSaga);
  yield takeEvery(actionType.DELETE_FRONT_END_USER, deleteFrontEndUserSaga);
  yield takeEvery(actionType.CREATE_FRONT_END_USER, createFrontEndUserSaga);
  yield takeEvery(actionType.GET_FRONT_END_USER, getFrontEndUserSaga);
  yield takeEvery(actionType.UPDATE_FRONT_END_USER, updateFrontEndUserSaga);
  yield takeEvery(
    actionType.BULK_UPDATE_FRONT_END_USER,
    batchUpdateFrontEndUserSaga
  );
  yield takeEvery(
    actionType.BULK_DELETE_FRONT_END_USER,
    batchDeleteFrontEndUserSaga
  );
  yield takeEvery(actionType.USER_TYPE, userTypeSaga);
}

export function* watchCountries() {
  yield takeEvery(actionType.GET_COUNTRIES, getCountriesSaga);
  yield takeEvery(actionType.GET_ENG_LANG, getLanguageSaga);
  yield takeEvery(actionType.CREATE_COUNTRY, createCountrySaga);
  yield takeEvery(actionType.GET_COUNTRY, getCountrySaga);
  yield takeEvery(actionType.UPDATE_COUNTRY, updateCountrySaga);
  yield takeEvery(actionType.DELETE_COUNTRY, deleteCountrySaga);
}

export function* watchSiteSettings() {
  yield takeEvery(actionType.GET_GENERAL_SETTINGS, getSiteSettingsSaga);
  yield takeEvery(actionType.GET_FRONT_GENERAL_SETTINGS, getFrontSiteSettingsSaga);
  yield takeEvery(actionType.CREATE_GENERAL_SETTINGS, createSiteSettingsSaga);
  yield takeEvery(actionType.UPDATE_GENERAL_SETTINGS, updateSiteSettingsSaga);
  yield takeEvery(actionType.GET_CAMPAIGN_SETTINGS, getCampaignSettings);
  yield takeEvery(
    actionType.CREATE_CAMPAIGN_SETTINGS,
    createCampaignSettingsSaga
  );
  yield takeEvery(
    actionType.UPDATE_CAMPAIGN_SETTINGS,
    updateCampaignSettingsSaga
  );
  yield takeEvery(
    actionType.GET_ALL_USERS_CONTACT_ENQUIRY,
    getAllUsersContactEnquirySaga
  );
  yield takeEvery(
    actionType.NEWSLETTER_SETTINGS,
    getNewsletterSettingsSaga
  );
  yield takeEvery(
    actionType.UPDATE_NEWSLETTER_SETTINGS,
    updateNewsletterSettingsSaga
  );
}

export function* watchMisc() {
  yield takeEvery(actionType.GET_CURRENCIES, getCurrenciesSaga);
  yield takeEvery(actionType.CREATE_CURRENCY, createCurrencySaga);
  yield takeEvery(actionType.DELETE_CURRENCY, deleteCurrencySaga);
  yield takeEvery(actionType.GET_CURRENCY, getCurrencySaga);
  yield takeEvery(actionType.UPDATE_CURRENCY, updateCurrencySaga);
  yield takeEvery(actionType.GET_MISC_LANGUAGES, getMiscLanguagesSaga);
  yield takeEvery(actionType.CREATE_MISC_LANGUAGE, createMiscLanguageSaga);
  yield takeEvery(actionType.DELETE_MISC_LANGUAGE, deleteLanguageSaga);
  yield takeEvery(actionType.GET_MISC_LANGUAGE, getMiscLanguageSaga);
  yield takeEvery(actionType.UPDATE_MISC_LANGUAGE, updateMiscLanguageSaga);
  yield takeEvery(actionType.GET_MISC_SLIDERS, getSlidersSaga);
  yield takeEvery(actionType.CREATE_MISC_SLIDER, createSliderSaga);
  yield takeEvery(actionType.DELETE_MISC_SLIDER, deleteSliderSaga);
  yield takeEvery(actionType.GET_MISC_SLIDER, getSliderSaga);
  yield takeEvery(actionType.MISC_SLIDER_UPDATE, updateSliderSaga);
  yield takeEvery(actionType.GET_MENU, getMenuSaga);
  yield takeEvery(actionType.GET_CONTENT_PAGE, getPageSaga);
  yield takeEvery(actionType.CREATE_MENU_ITEM, createMenuSaga);
  yield takeEvery(actionType.DELETE_MENU_ITEM, deleteMenuItemSaga);
  yield takeEvery(actionType.GET_MENU_ITEM, getMenuItem);
  yield takeEvery(actionType.UPDATE_MENU_ITEM, updateMenuItem);
  yield takeEvery(actionType.BULK_UPDATE_CURRENCY, bulkUpdateCurrency);
  yield takeEvery(actionType.BULK_DELETE_CURRENCY, bulkDeleteCurrency);
  yield takeEvery(actionType.BULK_SLIDER_UPDATE, bulkSliderUpdateSaga);
  yield takeEvery(actionType.BULK_SLIDER_DELETE, bulkSliderDeleteSaga);
  yield takeEvery(actionType.SET_DEFAULT_LANGUAGE, setDefaultLanguageSaga);
  yield takeEvery(
    languageAction.LANGUAGE_SELECTOR_CURRENT_LANGUAGE,
    getLanguage
  );
  yield takeEvery(cronAction.GET_CRON_JOBS, getCronJobsSaga);
  yield takeEvery(cronAction.CREATE_CRON_JOB, createCronJobSaga);
  yield takeEvery(actionType.GET_SUCCESS_STORIES, getsuccessStoriesSaga);
  yield takeEvery(actionType.GET_SUCCESS_STORY, getSuccessStorySaga);
  yield takeEvery(actionType.CREATE_SUCCESS_STORIES, createSuccessStoriesSaga);
}

export function* watchContentPage() {
  yield takeEvery(actionType.GET_FAQS, getFAQs);
  yield takeEvery(actionType.CREATE_FAQ, createFAQSaga);
  yield takeEvery(actionType.DELETE_FAQ, deleteFAQSaga);
  yield takeEvery(actionType.GET_FAQ, getFAQSaga);
  yield takeEvery(actionType.UPDATE_FAQ, updateFAQSaga);
  yield takeEvery(actionType.GET_PAGES, getPages);
  yield takeEvery(actionType.CREATE_PAGE, createPageSaga);
  yield takeEvery(actionType.DELETE_PAGE, deletePageSaga);
  yield takeEvery(actionType.GET_PAGE, getPage);
  yield takeEvery(actionType.UPDATE_PAGE, updatePage);
  yield takeEvery(actionType.GET_HELP_CATEGORIES, getHelpCategoriesSaga);
  yield takeEvery(actionType.GET_HELP_CATEGORY, getHelpCategory);
  yield takeEvery(actionType.CREATE_HELP_CATEGORY, createHelpCategory);
  yield takeEvery(actionType.DELETE_HELP_CATEGORY, deleteHelpCategory);
  yield takeEvery(actionType.UPDATE_HELP_CATEGORY, updateHelpCategory);
  yield takeEvery(actionType.GET_HELP_CENTERS, getHelpCenters);
  yield takeEvery(actionType.DELETE_HELP_CENTER, deleteHelpCenter);
  yield takeEvery(actionType.CREATE_HELP_CENTER, createHelpCenter);
  yield takeEvery(actionType.GET_HELP_CENTER, getHelpCenter);
  yield takeEvery(actionType.UPDATE_HELP_CENTER, updateHelpCenter);
  yield takeEvery(actionType.GET_FOOTER_FAQS, getFooterFAQs);
  yield takeEvery(actionType.CREATE_FOOTER_FAQ, createFooterFAQSaga);
  yield takeEvery(actionType.DELETE_FOOTER_FAQ, deleteFooterFAQSaga);
  yield takeEvery(actionType.GET_FOOTER_FAQ, getFooterFAQSaga);
  yield takeEvery(actionType.UPDATE_FOOTER_FAQ, updateFooterFAQSaga);
  yield takeEvery(actionType.GET_INVESTOR_CONTRACTS, getInvestorContractsSaga);
  yield takeEvery(actionType.GET_INVESTOR_CONTRACT_BY_ID, getInvestorContractByIdSaga);
  yield takeEvery(actionType.UPDATE_INVESTOR_CONTRACT, updateInvestorContractSaga);
}

export function* watchAPIManagement() {
  yield takeEvery(apiAction.GET_PAYMENT_GATEWAY, getPaymentGatewaySaga);
  yield takeEvery(apiAction.CREATE_PAYMENT_GATEWAY, createPaymentGatewaySaga);
  yield takeEvery(apiAction.UPDATE_PAYMENT_GATEWAY, updatePaymentGatewaySaga);
  yield takeEvery(apiAction.GET_RECAPTCHA, getRecaptchaSaga);
  yield takeEvery(apiAction.CREATE_RECAPTCHA, createRecaptchaSaga);
  yield takeEvery(apiAction.UPDATE_RECAPTCHA, updateRecaptchaSaga);
  yield takeEvery(apiAction.GET_SOCIAL, getSocialSaga);
  yield takeEvery(apiAction.CREATE_SOCIAL, createSocialSaga);
  yield takeEvery(apiAction.UPDATE_SOCIAL, updateSocialSaga);
  yield takeEvery(apiAction.GET_PUBLIC_RECAPTCHA, getPublicRecaptchaSaga);
}

export function* watchRevenue() {
  yield takeEvery(revenueAction.GET_REVENUE, getRevenue);
  yield takeEvery(revenueAction.UPDATE_REVENUE, updateRevenue);
  yield takeEvery(revenueAction.CREATE_REVENUE, createRevenue);
}

export function* watchRoles() {
  yield takeEvery(roleAction.GET_ROLE, getRole);
  yield takeEvery(roleAction.GET_ROLES, getRoles);
  yield takeEvery(roleAction.CREATE_ROLE, createRole);
  yield takeEvery(roleAction.UPDATE_ROLE, updateRole);
  yield takeEvery(roleAction.DELETE_ROLE, deletedRole);
  yield takeEvery(roleAction.BULK_UPDATE_ROLE, bulkUpdateRoleSaga);
  yield takeEvery(roleAction.BULK_DELETE_ROLE, bulkDeleteRoleSaga);
  yield takeEvery(roleAction.GET_PERMISSIONS, getPermissionSaga);
}

export function* watchMeta() {
  yield takeEvery(metaAction.GET_META, getMeta);
  yield takeEvery(metaAction.CREATE_META, createMeta);
  yield takeEvery(metaAction.UPDATE_META, updateMeta);
}

export function* watchTaxomony() {
  yield takeEvery(taxonomyAction.GET_TAXONOMY, getTaxomony);
  yield takeEvery(taxonomyAction.CREATE_TAXONOMY, createTaxomony);
  yield takeEvery(taxonomyAction.UPDATE_TAXONOMY, updateTaxomony);
}

export function* watchSpamSetting() {
  yield takeEvery(spamAction.GET_SPAM_SETTING, getSpamSettingSaga);
  yield takeEvery(spamAction.CREATE_SPAM_SETTING, createSpamSettingSaga);
  yield takeEvery(spamAction.UPDATE_SPAM_SETTING, updateSpamSettingSaga);
  yield takeEvery(spamAction.GET_SPAMMERS, getSpammersSaga);
  yield takeEvery(spamAction.CREATE_SPAMMER, createSpammerSaga);
  yield takeEvery(spamAction.GET_SPAMMER, getSpammerSaga);
}

export function* watchDashboard() {
  yield takeEvery(dashboardAction.GET_DASHBOARD_COUNT, getDashboardCountSaga);
  yield takeEvery(
    dashboardAction.GET_DASHBOARD_PENDING_CAMPAIGNS,
    getDashboardPendingCampaignsSaga
  );
  yield takeEvery(
    dashboardAction.GET_DASHBOARD_CAMPAIGNS_REVENUES,
    getDashboardCampaignsRevenuesSaga
  );

  yield takeEvery(
    dashboardAction.GET_DASHBOARD_CAMPAIGN_TOTAL_INVESTMENT,
    getDashboardCampaignTotalInvestmentSaga
  );

  yield takeEvery(
    dashboardAction.GET_DASHBOARD_CAMPAIGN_TOTAL_REPAYMENT,
    getDashboardCampaignTotalRepaymentSaga
  );
}

export function* watchCampaigns() {
  yield takeEvery(campaignAction.GET_CAMPAIGNS, campaignSaga.getCampaignsSaga);
  yield takeEvery(
    campaignAction.GET_SEARCH_CAMPAIGNS,
    campaignSaga.getSearchCampaignsSaga
  );
  yield takeEvery(
    campaignAction.GET_TOTAL_RESERVARTIONS,
    campaignSaga.getReservationsSaga
  );
  yield takeEvery(
    campaignAction.GET_CAMPAIGN_DETAILS,
    campaignSaga.getCampaignDetailsSaga
  );
  yield takeEvery(campaignAction.GET_INVESTORS, campaignSaga.getInvestorsSaga);
  yield takeEvery(campaignAction.GET_DOCUMENTS, campaignSaga.getDocumentsSaga);
  yield takeEvery(
    campaignAction.CREATE_CONTRACT_DOCUMENT,
    campaignSaga.uploadDocumentContractSaga
  );
  yield takeEvery(
    campaignAction.APPROVE_CAMPAIGN,
    campaignSaga.approveCampaignSaga
  );
  yield takeEvery(
    campaignAction.DECLINE_CAMPAIGN,
    campaignSaga.declineCampaignSaga
  );
  yield takeEvery(
    campaignAction.PENDING_CAMPAIGN,
    campaignSaga.pendingCampaignSaga
  );
  yield takeEvery(
    campaignAction.FEATURE_CAMPAIGN,
    campaignSaga.featureCampaignSaga
  );
  yield takeEvery(
    campaignAction.UNFEATURE_CAMPAIGN,
    campaignSaga.unfeatureCampaignSaga
  );
  yield takeEvery(
    campaignAction.GET_INVESTOR_DETAILS,
    campaignSaga.getInvestorDetailsSaga
  );
  yield takeEvery(
    campaignAction.GET_CAMPAIGN_ACTIVITIES,
    campaignSaga.getCampaignActivitiesSaga
  );
  yield takeEvery(
    campaignAction.APPROVE_REPAYMENT,
    campaignSaga.approveRepayment
  );
  yield takeEvery(
    campaignAction.GET_REPAYMENT_IN,
    campaignSaga.getRepaymentInSaga
  );
  yield takeEvery(
    campaignAction.CREATE_REPAYMENT_IN,
    campaignSaga.createRepaymentInSaga
  );
  yield takeEvery(
    campaignAction.UPDATE_REPAYMENT,
    campaignSaga.updateRepaymentSaga
  );
  yield takeEvery(
    campaignAction.GET_DISTRIBUTION,
    campaignSaga.getDistributionSaga
  );
  yield takeEvery(
    campaignAction.CREATE_DISTRIBUTION,
    campaignSaga.createDistributionSaga
  );
  yield takeEvery(
    campaignAction.GET_DISTRIBUTION_DETAIL,
    campaignSaga.getDistributionDetailSaga
  );
  yield takeEvery(
    campaignAction.DELETE_DISTRIBUTION,
    campaignSaga.deleteDistributionRecordSaga
  );
  yield takeEvery(
    campaignAction.UPDATE_DISTRIBUTION,
    campaignSaga.updateDistributionSaga
  );
  yield takeEvery(
    campaignAction.UPDATE_DISTRIBUTION_ADJUSTMENT,
    campaignSaga.updateDistributionAdjustmentSaga
  );
  yield takeEvery(
    campaignAction.MARK_DISTRIBUTED,
    campaignSaga.updateDistributionMarkDistributed
  );
  yield takeEvery(
    campaignAction.REMOVE_CAMPAIGN,
    campaignSaga.deleteCampaignSaga
  );
  yield takeEvery(campaignAction.INVEST_ACTION, campaignSaga.investActionSaga);
  yield takeEvery(
    campaignAction.ACK_CONTRACT_ACTION,
    campaignSaga.acknowledgeDocumentSaga
  );
  yield takeEvery(
    campaignAction.UPDATE_CAMPAIGN,
    campaignSaga.updateCampaignSaga
  );
  yield takeEvery(
    campaignAction.GET_STRIPE_ACH_PAYMENT_TOKEN,
    campaignSaga.getStripeACHPaymentTokenSaga
  );
  yield takeEvery(
    campaignAction.POST_STRIPE_ACH_PAYMENT_CONFIMATION,
    campaignSaga.postStripeACHPaymentConfimationSaga
  );
}

export function* watchAccreditation() {
  yield takeEvery(
    accreditationAction.GET_INVESTORS,
    getAccreditionInvestorsSaga
  );
  yield takeEvery(accreditationAction.GET_INVESTOR, getAccreditionInvestorSaga);
  yield takeEvery(
    accreditationAction.ACCREDITATION_ACTION,
    accreditationActionSaga
  );
}

export function* watchActivities() {
  yield takeEvery(activityAction.GET_ACTIVITIES, getActivities);
}

export function* watchEmailSettings() {
  yield takeEvery(emailAction.GET_EMAIL_TEMPLATES, getEmailTemplatesSaga);
  yield takeEvery(emailAction.GET_EMAIL_TEMPLATE, getEmailTemplateSaga);
  yield takeEvery(emailAction.CREATE_EMAIL_TEMPlATE, createEmailTemplateSaga);
  yield takeEvery(emailAction.DELETE_EMAIL_TEMPLATE, deleteEmailTemplateSaga);
  yield takeEvery(emailAction.SEND_TEST_EMAIL, sendTestEmailSaga);
  yield takeEvery(emailAction.UPDATE_EMAIL_TEMPlATE, updateEmailTemplateSaga);
  yield takeEvery(emailAction.UPDATE_MASTER_EMAIL, updateMasterEmailSaga);
  yield takeEvery(
    emailAction.BULK_UPDATE_EMAIL_TEMPlATE,
    bulkUpdateEmailTemplateSaga
  );
  yield takeEvery(transactionAction.GET_TRANSACTIONS, getTransactions);
  yield takeEvery(transactionAction.GET_TRANSACTION, getTransaction);
  yield takeEvery(transactionAction.UPDATE_TRANSACTION, updateTransactionSaga);
  yield takeEvery(transactionAction.GET_ACTIVE_CAMPAIGN_LIST, getActiveCamapignListSaga);
  yield takeEvery(transactionAction.GET_INVESTOR_LIST, getInvestorListSaga);
  yield takeEvery(transactionAction.ADD_TRANSACTION, addTransactionSaga);

  yield takeEvery(
    transactionAction.GET_SEARCH_TRANSACTIONS,
    getSearchTransactionsSaga
  );

  yield takeEvery(newsletterAction.GET_NEWSLETTERS, getNewsletters);
  yield takeEvery(newsletterAction.GET_NEWSLETTER, getNewsletter);
  yield takeEvery(newsletterAction.DELETE_NEWSLETTER, deleteNewsletterSaga);
  yield takeEvery(
    newsletterAction.GET_SEARCH_NEWSLETTERS,
    getSearchNewslettersSaga
  );
}

export function* watchExportCSVList() {
  yield takeEvery(exportCSVAction.EXPORT_CSV_FRONT_USERS, getFrontUserCSVList);
  yield takeEvery(exportCSVAction.GET_REPAYMENT_DISTRIBUTION_CSV_LIST, getRePaymentDistributionCSVListSaga);
  yield takeEvery(exportCSVAction.GET_DISTRIBUTION_VIEW_CSV_LIST, getDistributionViewCSVListSaga);
  yield takeEvery(exportCSVAction.GET_REPAYMENT_RECEIVED_CSV_LIST, getRePaymentReceivedCSVListSaga);
}

export function* watchWallet() {
  yield takeEvery(walletAction.GET_WALLET_TRANSACTIONS, getWalletTransactionsSaga);
  yield takeEvery(walletAction.UPDATE_WALLET_TRANSACTION, updateWalletTransactionSaga);
}
