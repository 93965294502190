import axios from "axios";

// DEV API URL : https://cfapp.valusoft.io/api/v1.1/
// PROD URL : https://cfapp.valusoft.io/api/v1.1/

const instance = axios.create({
  baseURL: "https://cfapp.valusoft.io/api/v1.1/",
});

instance.interceptors.request.use(function(config) {
  const token = localStorage.getItem("adminToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export default instance;
